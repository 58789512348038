import * as PIXI from 'pixi.js';

export default class Label extends PIXI.Text {
  constructor(text, style = {}) {
    const fontSize = style.fontSize || 24;
    const textStyle = {
      fontFamily: 'Early GameBoy',
      fill: 0xeae7bc,
      align: 'center',
      ...style,
      fontSize,
      lineHeight: fontSize * 0.5,
    };
    super(text, textStyle);
  }
}
