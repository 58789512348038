import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import Importer from '../utils/importer';
import TweenGeneric from '../tweening/tween_generic';
import Star from '../gameObjects/Star';
import { Util } from '../util';
import { Vector } from '../utils/helpers';
import Tree from '../gameObjects/Tree';
import BigStar from '../gameObjects/BigStar';
import SkyLine from '../gameObjects/SkyLine';
import TweenBlink from '../tweening/tween_blink';
import TextLink from '../gameObjects/TextLink';
import TweenPop from '../tweening/tween_pop';
import DashedMenu from '../gameObjects/DashedMenu';
import Label from '../ui/label';

let STAR_SPAWN_TIME = 100; // to be calculated
const START_LIFE_SPAN = 7000; // 5 sec

export default class LandingScene extends PIXI.Container {
  constructor(delegate) {
    super();

    this.delegate = delegate;
    this.screenSize = delegate.screenSize;

    this.focalObjects = [];

    this.stars = []; // to be able to stop the eye cutscene
    this.isStarsAnimating = true;
    this.timerSpawnStar = 0;
    this.cloudsDirection = 1;

    this.targetPoint = new Vector(
      this.screenSize.width / 2,
      this.screenSize.height / 2
    );

    this.fl = 200; // fokal length
    this.dY = 600;
    this.diff = new Vector(0, this.dY);
    this.vanishingPoint = new Vector(
      this.screenSize.width / 2,
      this.screenSize.height / 2 + 110
    );

    const background = PIXI.Sprite.from(PIXI.Texture.WHITE);
    background.width = this.screenSize.width;
    background.height = this.screenSize.height;
    background.tint = 0xebe4ae;
    this.addChild(background);

    this.background = background;

    // Import objects
    const objects = PIXI.Assets.get('landing-page_screen').objects;

    this.importer = new Importer();
    this.importer.importObjects(objects, this);

    // set all children to be visible
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      child.visible = true;
    }

    this.layers = [];
    this.uiContainer = this.findById('ui-container');

    this.layer2Container = this.findById('layer2-container');

    this.backgroundContainer = this.findById('background-container');
    this.skyLineContainer = this.findById('sky-lines');
    this.cloudsContainer = this.findById('clouds-container');

    this.backgroundLayer = this.findByName('background');
    this.cloudLayer = this.findByName('clouds');
    this.layer1 = this.findByName('layer1');
    this.layer2 = this.findByName('layer2');
    this.layer3 = this.findByName('layer3');

    this.layers.push(this.backgroundLayer);
    this.layers.push(this.cloudLayer);

    this.layer2.sortableChildren = true;

    const yOffset = -50;
    this.layer2.y += yOffset;
    this.cloudLayer.y += yOffset;
    this.backgroundLayer.y += yOffset + 20;
    this.uiContainer.y += yOffset;
    this.cloudsContainer.y = -20;

    this.backgroundContainer.x = this.screenSize.width / 2;
    this.skyLineContainer.x = this.screenSize.width / 2;
    this.cloudsContainer.x = this.screenSize.width / 2;
    this.uiContainer.x = this.screenSize.width / 2;
    this.layer2Container.x = 0;
    this.layer2Container.sortableChildren = true;
    window.layer2Container = this.layer2Container;

    this.buildMenu();

    // all containers muset be moved to the middle of the screen

    this.cloud1 = this.findById('cloud1');
    this.areg = this.findById('areg');
    this.feather = this.findById('feather');
    this.featherShadow = this.findById('feather-shadow');

    // replace cloud with a tiling sprite with its texture
    const cloudWidth = this.screenSize.width + 200;
    const cloudTexture = this.cloud1.texture;
    const cloud = new PIXI.TilingSprite(
      cloudTexture,
      cloudWidth,
      this.cloud1.height
    );
    cloud.x = -cloudWidth / 2;
    cloud.y = 540;
    cloud.scale.y = 0.9;
    this.cloud1.parent.addChild(cloud);
    this.cloud1.removeFromParent();
    this.cloud1 = cloud;

    // find cloud layer

    // Areg Animation
    const ac = new PIXI.Container();
    const animation = new Spine(PIXI.Assets.get('Areg_landing').spineData);

    animation.autoUpdate = true;
    animation.state.setAnimation(0, 'animation', true);
    animation.scale.set(0.08);
    ac.addChild(animation);

    this.aregAnimation = animation;

    ac.position = this.areg.position;
    this.layer2Container.addChild(ac);
    this.areg.removeFromParent();
    animation.y -= animation.height;
    animation.x -= animation.width / 2;

    // add tenticle to the scene from the texture sample_tenticle

    this.eventMode = 'static';
    this.addEventListener('pointermove', this.onMouseMove.bind(this));

    // pre spawn 50 stars
    for (let i = 0; i < 50; i++) {
      this.spawnStar(500 + i * 100 + Util.Math.randomInt(0, 1000));
    }

    this.drawLandLines();

    this.prepareFocalObjects(this.layer2Container.children);
    this.calculatePerspective();

    // this.grid = new PerspectiveGrid();
    // this.grid.setPerspective(this.fl, this.diff, this.vanishingPoint);
    // this.addChild(this.grid);

    this.trees = this.findByType(Tree);
    this.bigStars = this.findByType(BigStar);
    this.skyLines = this.findByType(SkyLine);

    this.footerBanner = this.findById('footer-banner');
    this.resizeFooter();
  }

  buildMenu() {
    const container = this.uiContainer;

    const style = {
      fontFamily: 'Pixels',
      fontSize: 50,
      fill: 0xeae7bc,
      align: 'center',
    };

    const links = [
      {
        id: 'the-artwork',
        text: 'About The Project',
      },
      {
        id: 'the-music',
        text: 'The Music',
      },
      {
        id: 'the-story',
        text: 'The Story',
      },
      {
        id: 'live-show',
        text: 'Live Staged Show',
      },
      {
        id: 'films',
        text: 'Films',
      },
      {
        id: 'merchandise',
        text: 'Merchandise',
      },
      {
        id: 'tigrans-website',
        text: `Tigran's Website`,
      },
    ];

    const width = 290;
    const height = 320;

    const dashedMenu = new DashedMenu({
      width,
      height,
      links,
      style,
      delegate: this,
    });
    dashedMenu.position.set(0, 100);
    this.uiContainer.addChild(dashedMenu);

    // PLAY GAME TextLink
    style.fontSize = 70;
    const playGame = new TextLink('Play Game', this, style);
    playGame.underline.visible = true;
    playGame.canHover = false;
    playGame.position.set(0, 480);
    playGame.id = 'play-game';
    container.addChild(playGame);

    const delay = 2000;
    new TweenPop(playGame, 1.2, null, 900).delay(delay).run();

    // add a footer at the bottom of the page
    // with two links separated by a line
    // the left link will display "truth.io"
    // and the right link will display "credits"

    const footerContainer = new PIXI.Container();
    footerContainer.y = this.screenSize.height + 30;
    container.addChild(footerContainer);

    const footerColor = 0x333333;

    const footerStyle = {
      fontFamily: 'Pixels',
      fontSize: 30,
      fill: footerColor,
      align: 'center',
    };

    const truthLink = this.createLink('truth-io', 'truth.io', footerStyle, 0);
    truthLink.setColor(footerColor);
    footerContainer.addChild(truthLink);

    const line = new PIXI.Graphics();
    line.beginFill(footerColor, 1);
    line.drawRect(0, 0, 2, 20);
    line.endFill();
    line.x = 0;
    line.y = -15;
    footerContainer.addChild(line);

    const creditsLink = this.createLink('credits', 'credits', footerStyle, 0);
    creditsLink.setColor(footerColor);
    footerContainer.addChild(creditsLink);

    truthLink.x -= truthLink.width / 2 + 10;
    creditsLink.x += creditsLink.width / 2 + 10;

    // add a text that says "C" left from the truth.io link
    const cText = new Label('(c)', footerStyle);
    cText.anchor.set(0.5);
    cText.x = truthLink.x - truthLink.width / 2 - 15;
    cText.y = -8;
    footerContainer.addChild(cText);
  }

  createLink(id, text, style, y) {
    const link = new TextLink(text, this, style);
    link.position.set(0, y);
    link.id = id;
    return link;
  }

  createLandLine(x, y) {
    const extra = 400;
    const width = this.screenSize.width + extra;
    // create a black line the width of the screen
    const line = new PIXI.Graphics();
    line.beginFill(0x000000, 1);
    line.drawRect(-width / 2, 0, width, 2);
    line.endFill();
    line.x = x;
    line.y = y;
    return line;
  }

  drawLandLines() {
    if (this.lines) {
      for (let i = 0; i < this.lines.length; i++) {
        const line = this.lines[i];
        line.removeFromParent();
      }
      this.lines = [];
    }

    const line1 = this.createLandLine(this.screenSize.width / 2, 690);
    const line2 = this.createLandLine(0, 900);
    const line3 = this.createLandLine(0, 1100);
    const line4 = this.createLandLine(0, 1200);

    this.cloudLayer.addChild(line1);
    this.layer2Container.addChild(line2);
    this.layer2Container.addChild(line3);
    this.layer2Container.addChild(line4);

    this.lines = [line1, line2, line3, line4];

    // remove the objects from focalObjects
    this.removeObject(line2, this.focalObjects);
    this.removeObject(line3, this.focalObjects);
    this.removeObject(line4, this.focalObjects);

    line2.pushToBack();
    line3.pushToBack();
    line4.pushToBack();
  }

  removeObject(o, array) {
    const index = array.indexOf(o);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  onStartClicked(target) {
    target.eventMode = 'none';

    if (!this.delegate.isTreesLoaded) {
      const label = new Label('Loading...', {
        fontFamily: 'Early GameBoy',
        fontSize: 20,
        fill: 0xebe4ae,
      });
      label.anchor.set(0.5);
      label.x = target.x + 20;
      label.y = target.y;
      target.parent.addChild(label);
      target.visible = false;

      this.loaderTween = new TweenBlink(label, 0, null, 2000).run();

      window.app.game.addWaitingAssetsEvent(
        'trees',
        this.executeHomeBtnClicked.bind(this)
      );
      return;
    } else {
      this.executeHomeBtnClicked();
    }
  }

  executeHomeBtnClicked() {
    if (this.loaderTween) {
      this.loaderTween.stop();
    }

    const filter = new PIXI.AlphaFilter(1);
    this.filters = [filter];

    new TweenGeneric(
      filter,
      { alpha: 0 },
      null,
      400,
      this.onHomeTransitionComplete.bind(this)
    ).run();
  }

  onHomeTransitionComplete() {
    this.delegate.onHomeScreen();
  }

  update(delta, ticker) {
    this.cloud1.tilePosition.x += 0.01 * delta;

    const rotationAltitude = 0.05;

    // rotate the feather from left to right , by using sine function
    this.feather.rotation = Math.sin(ticker.lastTime / 1000) * rotationAltitude;
    this.featherShadow.scale.x =
      Math.sin(ticker.lastTime / 1000) * rotationAltitude + 1;

    if (this.tenticle) {
      // use sin wave to animate its skew
      this.tenticle.skew.x = Math.sin(ticker.lastTime / 1000) * 0.1;

      // use sin wave to animate its scale
      this.tenticle.scale.x = Math.sin(10 + ticker.lastTime / 1000) * 0.05 + 1;

      this.tenticle.onUpdate(delta, ticker);
    }

    this.timerSpawnStar -= ticker.deltaMS;
    if (this.timerSpawnStar <= 0) {
      this.timerSpawnStar += STAR_SPAWN_TIME;
      this.spawnStar();
    }

    if (this.stars) {
      for (let i = 0; i < this.stars.length; i++) {
        const star = this.stars[i];
        star.onUpdate(delta, ticker);
      }
    }

    this.calculatePerspective();

    // this.grid.postUpdate();

    // update all trees
    for (let i = 0; i < this.trees.length; i++) {
      const tree = this.trees[i];
      tree.onUpdate(delta, ticker);
    }

    // update all big stars
    for (let i = 0; i < this.bigStars.length; i++) {
      const bigStar = this.bigStars[i];
      bigStar.onUpdate(delta, ticker);
    }

    // update all sky lines
    for (let i = 0; i < this.skyLines.length; i++) {
      const skyLine = this.skyLines[i];
      skyLine.onUpdate(delta, ticker);
    }

    // this.grid.setPerspective(this.fl, this.diff, this.vanishingPoint);
  }

  spawnStar(lifeSpan) {
    const start = new Star(lifeSpan || START_LIFE_SPAN, this, this.stars);
    // set random position
    const x = Util.Math.randomInt(0, this.screenSize.width + 300); // running the side requires start to be there
    const y = Util.Math.randomInt(0, this.screenSize.height - 300);

    const pos = this.backgroundLayer.toLocal(new PIXI.Point(x, y));
    start.position = pos;

    this.backgroundLayer.addChild(start);
  }

  removeStars() {
    if (this.stars) {
      for (let i = 0; i < this.stars.length; i++) {
        const star = this.stars[i];
        star.removeFromParent();
      }

      this.stars = null;
    }
  }

  onMouseMove(e) {
    const point = e.data.global;
    if (this.tenticle) {
      this.tenticle.followPoint(point);
    }

    this.mousePoint = point;

    this.targetPoint.x = point.x;
    this.targetPoint.y = point.y;

    this.diff.x = (point.x - this.screenSize.width / 2) * 0.15;
    this.diff.y = this.dY + (point.y - this.screenSize.height / 2) * 0.15;

    this.moveLayers(point.x, point.y);
  }

  moveLayers(x, y) {
    x -= this.screenSize.width / 2;
    y -= this.screenSize.height / 2;

    x = x * 0.1;
    y = y * 0.1;

    for (let i = 0; i < this.layers.length; i++) {
      const layer = this.layers[i];
      if (x !== null) {
        layer.x = x * layer.factor;
      }

      if (y !== null) {
        // layer.y = y * layer.factor;
      }
    }
  }

  prepareFocalObjects(children) {
    for (let i = 0; i < children.length; i++) {
      const object = children[i];
      const z = Util.Math.map(object.y, 0, 1300, 3000, 0);

      object.zIndex = Math.round(z);

      object.pos3D = {
        x: object.x * 7,
        y: 0,
        z: z,
      };
      this.focalObjects.push(object);
    }

    this.focalObjects = this.focalObjects.sort((a, b) => {
      return b.zIndex - a.zIndex;
    });

    for (let i = 0; i < this.focalObjects.length; i++) {
      const object = this.focalObjects[i];
      object.zIndex = i;
    }
  }

  calculatePerspective(x, y) {
    const objects = this.focalObjects;

    for (var i = objects.length - 1; i >= 0; i--) {
      var object = objects[i];
      // object.zIndex = i;

      var pos3D = object.pos3D;

      var perspective = this.fl / (this.fl + pos3D.z);
      var fLengthX = this.fl;
      var perspectiveX = fLengthX / (fLengthX + pos3D.z);
      //  var factor = 0.4; // to be checked

      var x = (pos3D.x + this.diff.x) * perspectiveX + this.vanishingPoint.x; // (pos3D.x + dx * factor) * perspective + vx;
      var y = (pos3D.y + this.diff.y) * perspective + this.vanishingPoint.y;

      object.x = x;
      object.y = y;
    }
  }

  onShow() {}

  onHide() {}

  onResize(size) {
    this.background.width = size.width;
    this.background.height = size.height;

    this.uiContainer.x = this.screenSize.width / 2;
    this.vanishingPoint.x = this.screenSize.width / 2;

    this.backgroundContainer.x = this.screenSize.width / 2;
    this.skyLineContainer.x = this.screenSize.width / 2;
    this.cloudsContainer.x = this.screenSize.width / 2;
    this.cloud1.width = this.screenSize.width + 200;
    this.cloud1.x = -this.cloud1.width / 2;

    this.drawLandLines();

    // clone array
    const preps = this.lines.slice(0);
    preps.shift();

    this.prepareFocalObjects(preps);

    this.resizeFooter();
  }

  resizeFooter() {
    let scale = Util.Math.map(this.screenSize.width, 0, 1920, 0.4, 1);
    scale = Util.Math.clamp(scale, 0.4, 0.9);

    if (this.footerBanner) {
      this.footerBanner.scale.set(scale);
    }
  }

  onLinkClick(link) {
    if (link.id === 'play-game') {
      this.onStartClicked(link);
    } else if (link.id === 'truth-io') {
      window.open('https://truth.io', '_blank');
    } else if (link.id === 'credits') {
      window.open('https://bird1000.com/credits', '_blank');
    }
  }
}
