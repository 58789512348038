import * as PIXI from 'pixi.js';

PIXI.DisplayObject.prototype.bringToFront = function () {
    var parent = this.parent;
    parent.setChildIndex(this, parent.children.length - 1);
};

PIXI.DisplayObject.prototype.pushToBack = function () {
    var parent = this.parent;
    parent.setChildIndex(this, 0);
};

PIXI.DisplayObject.prototype.removeFromParent = function () {
    if (this.parent) {
        this.parent.removeChild(this);
    }
};


PIXI.DisplayObject.prototype.stretch = function (width, height) {
    //TODO , do this using the scale
    this.width = width;
    this.height = height;
};

PIXI.DisplayObject.prototype.centered = function () {
    this.anchor.set(0.5, 0.5);
};

/**
 * Finds the element recusivly for a given ID
 * @param String id 
 * @param Object parent the layer in which to serach 
 */
PIXI.DisplayObject.prototype.findById = function (id, parent) {
    parent = parent || this;
    for (var i = 0; i < parent.children.length; i++) {
        var c = parent.children[i];
        if (c.id === id) {
            return c;
        }
        var b = this._findById(id, c.children);
        if (b) {
            return b;
        }
    }

    return null;
};

PIXI.DisplayObject.prototype._findById = function (id, children) {
    for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (c.id === id) {
            return c;
        }
        var b = this._findById(id, c.children);
        if (b) {
            return b;
        }
    }
    return null;
};

PIXI.DisplayObject.prototype.findByName = function (name, children) {
    children = children || this.children;
    for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (c.name === name) {
            return c;
        }
        var b = this.findByName(name, c.children);
        if (b) {
            return b;
        }
    }

    return null;
};

PIXI.DisplayObject.prototype.findByTag = function (tag, result, children) {
    children = children || this.children;
    result = result || [];
    for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (c.tag == tag) {
            result.push(c);
        }
        this.findByTag(tag, result, c.children);
    }

    return result.length ? result : null;
};

PIXI.DisplayObject.prototype.findByType = function (type, result, children) {
    children = children || this.children;
    result = result || [];
    for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (c instanceof type) {
            result.push(c);
        }
        this.findByType(type, result, c.children);
    }

    return result.length ? result : null;
};

PIXI.DisplayObject.prototype.findByCustom = function (method, result, children) {
    children = children || this.children;
    result = result || [];
    for (var i = 0; i < children.length; i++) {
        var c = children[i];
        if (method(c)) {
            result.push(c);
        }
        this.findByCustom(method, result, c.children);
    }

    return result.length ? result : null;
};

PIXI.DisplayObject.prototype.fitTo = function (fitWidth, fitHeight) {

    this.calculateBounds();
    var b = this.getLocalBounds();

    var myWidth = b.width;
    var myHeight = b.height;

    this.scale.set(Math.min(fitWidth / myWidth, fitHeight / myHeight));

};

PIXI.DisplayObject.prototype.fillOut = function (fillWidth, fillHeight) {

    this.calculateBounds();
    var b = this.getLocalBounds();

    var myWidth = b.width;
    var myHeight = b.height;

    var scale = Math.max(fillWidth / myWidth, fillHeight / myHeight);
    this.scale.set(scale, scale);

};

PIXI.Sprite.prototype.setTexture = function (textureName) {
    this.texture = PIXI.utils.TextureCache[textureName];
}

// #### EVENTS

PIXI.DisplayObject.prototype.onUpdate = function (dt) {

};


PIXI.DisplayObject.prototype.postUpdate = function (dt) {

};
