import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Util } from '../util';

export default class FireBucket extends PIXI.Container {
  constructor() {
    super();

    const data = PIXI.Assets.get('Fire Bucket');

    this.animation = new Spine(data.spineData);
    this.animation.autoUpdate = true;
    this.animation.scale.set(0.5);
    this.animation.y = 50;
    this.addChild(this.animation);
    const tScale = Util.Math.randomFloat(0.8, 1.3);
    this.animation.state.setAnimation(0, 'animation', true);
    this.animation.state.timeScale = tScale;
  }
}
