import { Util } from '../util';
import { Vector } from '../utils/helpers';
import Tween from './tween';

export default class TweenShake extends Tween {
  constructor(object, magnitude, frequency, bezier, duration, callback) {
    super(object, null, bezier, duration, callback);

    this.magnitude = magnitude;
    this.frequency = frequency ? frequency : 25 / 1000;

    this.shakeAccumulation = new Vector(0, 0);

    this.applyValues();
  }

  applyValues() {
    this.startPoint = new Vector(0, 0).copy(this.object.position);
    this.shakes = 0;
  }

  step(dt) {
    this.timePassed += dt;
    this.ticks++;

    let s = this.timePassed / this.duration;

    s = s >= 1 ? 1.0 : s;

    const f = Math.round(this.frequency * this.timePassed) - this.shakes;

    if (f > 0) {
      this.shakes += f;

      const bstep = this.bezier ? this.bezier.get(s) : 1;

      var angle = Util.Math.randomInt(0, 360);
      const move = new Vector();
      move.setLength(
        Util.Math.randomInt(this.magnitude / 3, this.magnitude * bstep)
      );
      move.setAngle(Util.Math.degreesToRadians(angle));

      while (
        Vector.addition(move, this.shakeAccumulation).getLength() >
        this.magnitude * bstep
      ) {
        var angle = Util.Math.randomInt(0, 360);
        move.setLength(
          Util.Math.randomInt(this.magnitude / 3, this.magnitude * bstep)
        );
        move.setAngle(Util.Math.degreesToRadians(angle));
      }

      this.shakeAccumulation.add(move);

      this.object.x += move.x;
      this.object.y += move.y;
    }

    if (s === 1.0 && this.ticks > 1) {
      this.object.x -= this.shakeAccumulation.x;
      this.object.y -= this.shakeAccumulation.y;
      this.invokeCallback();
      this.stop();
    }
  }
}
