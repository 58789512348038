export default class Actions {
  constructor() {
    throw new Error("Can't initialize Actions");
  }

  static actions = [];
  static isPaused = false;

  static add(action) {
    const index = Actions.actions.indexOf(action);
    if (index === -1) {
      Actions.actions.push(action);
      if (!action.duration) {
        action.step(0); // it will execute immediately if duration is 0
      }
    }
  }

  static remove(action) {
    // Actions.actions.splice(Actions.actions.indexOf(action), 1);
    const index = Actions.actions.indexOf(action);
    if (index !== -1) {
      Actions.actions.splice(index, 1);
    }
  }

  static pause() {
    Actions.isPaused = true;
  }

  static resume() {
    Actions.isPaused = false;
  }

  static stopAll() {
    Actions.actions.splice(0, Actions.actions.length);
  }

  static stopByTag(tag, stopWithCallback) {
    for (let i = Actions.actions.length - 1; i >= 0; i--) {
      const action = Actions.actions[i];
      if (action && tag === action.tag) {
        action.stop(stopWithCallback); // this will remove the action from the array
      }
    }
  }

  static isRunning(tag) {
    for (let i = Actions.actions.length - 1; i >= 0; i--) {
      if (Actions.actions[i] && tag === Actions.actions[i].tag) {
        return true;
      }
    }
    return false;
  }

  static update(dt) {
    if (Actions.isPaused) {
      return;
    }

    const count = Actions.actions.length;

    for (let i = count - 1; i >= 0; i--) {
      if (Actions.actions[i] && !Actions.actions[i].isPaused) {
        Actions.actions[i].step(dt);
      }
    }
  }
}
