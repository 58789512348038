import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { SAT } from '../utils/sat';
import { Vector } from '../utils/helpers';

export default class MagicTree extends PIXI.Container {
  constructor() {
    super();

    this.isTriggered = false;

    const data = PIXI.Assets.get('tree-magic');

    this.animation = new Spine(data.spineData);
    this.animation.autoUpdate = true;
    this.animation.scale.set(1);
    this.animation.y = 150;
    this.addChild(this.animation);

    this.animation.state.setAnimation(0, 'tree-Idle', true);

    const width = 100;
    const height = 100;

    const points = [
      new Vector(-width / 2, -height / 2),
      new Vector(width / 2, -height / 2),
      new Vector(width / 2, height / 2),
      new Vector(-width / 2, height / 2),
    ];

    this.collisonRectangle = new SAT.Polygon(new SAT.Vector(0, 0), points);
  }

  getCollisonRect() {
    const globalPosition = this.getGlobalPosition();
    this.collisonRectangle.pos.x = globalPosition.x;
    this.collisonRectangle.pos.y = globalPosition.y;

    return this.collisonRectangle;
  }

  onTrigger() {
    if (this.isTriggered) {
      return;
    }

    this.isTriggered = true;

    console.log('Magic Tree triggered!');

    this.animation.state.setAnimation(0, 'tree-activation', false);
  }
}
