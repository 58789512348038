import * as PIXI from 'pixi.js';
import BlinkingEye from './BlinkingEye';
import { Vector } from '../utils/helpers';
import { Util } from '../util';
import { SAT } from '../utils/sat';
import Obstacle from './Obstacle';

const T1 = 900;
const T2 = 600;
const T3 = 400;

export default class DemonTenticle extends PIXI.Sprite {
  constructor() {
    super();
    this.eyes = [];
    this.isAttacking = false;
    this.speedFactor = { x: 1 };
    this.screenSize = null;
    this.angle = -0.3;
    this.attackDirection = new Vector(0, 1);
    this.attackDirection.setAngle(this.angle);

    this.t1 = T1;
    this.t2 = T2;
    this.t3 = T3;

    this.isShort = false;
  }

  setData(data, extractMethod) {
    // its a container
  }

  onImport() {
    // get the eyes

    const scaleFactor = 2.5;
    this.scale.set(scaleFactor);

    this.eyes = this.findByType(BlinkingEye);

    this.initializeCollisionRect();
  }

  initializeCollisionRect() {
    const scaleFactor = this.scale.x;

    const obs = this.findByType(Obstacle)[0];
    this.obstacle = obs;

    const data = obs.data;

    // Polygons must be scaled , rotated and translated to match the sprite
    const points = [];

    for (let i = 0; i < data.points.length; i++) {
      points.push(
        new SAT.Vector(
          data.points[i].x * scaleFactor,
          data.points[i].y * scaleFactor
        )
      );
    }

    this.collisonRectangle = new SAT.Polygon(
      new SAT.Vector(
        data.position.x * scaleFactor,
        data.position.y * scaleFactor
      ),
      points
    );

    const pos = this.collisonRectangle.pos;
    this.collisonRectangle.translate(pos.x, pos.y);

    obs.removeFromParent();
  }

  getCollisonRect() {
    const globalPosition = this.getGlobalPosition();
    this.collisonRectangle.pos.x = globalPosition.x;
    this.collisonRectangle.pos.y = globalPosition.y;

    return this.collisonRectangle;
  }

  setUpTenticle() {
    this.rotation = this.angle;

    const b = this.getBounds();

    this.x = this.screenSize.width / 2 + b.width / 2;
    this.y = -b.height / 2 - 300;

    this.collisonRectangle.rotate(this.rotation);

    this.attack();
  }

  setDownTenticle() {
    this.rotation = this.angle + Math.PI;

    const b = this.getBounds();

    this.x = this.screenSize.width / 2 + b.width / 2 + 200;
    this.y = -b.height / 2 + 1000;
    this.collisonRectangle.rotate(this.rotation);

    this.attack();
  }

  attack() {
    this.startX = Util.Math.map(this.screenSize.width, 600, 2000, 0.5, 1);
    this.isAttacking = true;
  }

  followPoint(point) {
    for (let i = 0; i < this.eyes.length; i++) {
      const child = this.eyes[i];
      if (child.followPoint) {
        child.followPoint(point);
      }
    }
  }

  onUpdate(delta, ticker) {
    for (let i = 0; i < this.eyes.length; i++) {
      const child = this.eyes[i];
      if (child.onUpdate) {
        child.onUpdate(delta, ticker);
      }
    }

    if (this.isAttacking) {
      if (this.t1 > 0) {
        this.t1 -= delta;
        this.speedFactor.x = 0.8;
      } else if (this.t2 > 0) {
        this.t2 -= delta;
        const x = Util.Math.map(this.t2, T2, 0, 0.2, 0);
        this.speedFactor.x = x;
      } else if (this.t3 > 0) {
        this.t3 -= delta;
        const x = Util.Math.map(this.t3, T3, 0, 0, 2.5);
        this.speedFactor.x = x;
      } else {
        this.speedFactor.x = 2.5;
      }

      this.x -= this.attackDirection.x * delta * this.speedFactor.x;
      this.y -= this.attackDirection.y * delta * this.speedFactor.x;
    }
  }
}
