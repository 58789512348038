import * as PIXI from 'pixi.js';

export default class LakeFog extends PIXI.Container {
  constructor() {
    super();

    this.randomPhaseX = Math.random() * 1000;
  }

  setData(data, extractMethod) {
    this.sprite = new PIXI.Sprite(PIXI.Assets.get(data.imageName));
    if (data.anchor) {
      this.sprite.anchor.set(data.anchor.x, data.anchor.y);
    } else {
      this.sprite.anchor.set(0.5);
    }
    this.addChild(this.sprite);
  }

  onUpdate(delta, ticker) {
    // animate x positon with sine wave
    const amplitude = 40;
    const frequency = 0.0005;
    this.sprite.x =
      Math.sin(this.randomPhaseX + ticker.lastTime * frequency) * amplitude;
  }
}
