import * as PIXI from 'pixi.js';
import { Vector } from '../utils/helpers';
import { SAT } from '../utils/sat';
import { Spine } from 'pixi-spine';
import TweenGeneric from '../tweening/tween_generic';

export default class LakeBoat extends PIXI.Container {
  constructor() {
    super();
    this.startY = 0;
    this.player = null;

    this.startX = 0;
    this.endX = 0;
  }

  setData(data, extractMethod) {
    const bData = PIXI.Assets.get('under-water');
    // return;
    const testAnim = new Spine(bData.spineData);
    testAnim.autoUpdate = true;
    // animation.x = -this.screenSize.width;
    // animation.y = -this.screenSize.height;

    // repeat it
    testAnim.scale.set(0.5);

    // // 1_START_LOOP 2_HIT 3_END_LOOP 4_ALL ANIMATION
    testAnim.state.setAnimation(0, '1_START_LOOP', true);
    // testAnim.x = this.screenSize.width / 2;
    testAnim.y = 120;

    this.addChild(testAnim);

    this.animation = testAnim;

    setTimeout(() => {
      this.animation.state.addListener({
        complete: this.onAnimationCompleted.bind(this),
      });
    }, 100);

    const width = 355;
    const height = 160;

    const points = [
      new Vector(-width / 2, -height / 2),
      new Vector(width / 2, -height / 2),
      new Vector(width / 2, height / 2),
      new Vector(-width / 2, height / 2),
    ];

    this.collisonRectangle = new SAT.Polygon(
      new SAT.Vector(data.position.x, data.position.y),
      points
    );
  }

  startAnimation() {
    this.animation.state.setAnimation(0, '2_HIT', false);
  }

  onAnimationCompleted(event) {
    if (event.animation.name === '2_HIT') {
      this.animation.state.setAnimation(0, '3_END_LOOP', true);
      this.player.animation.state.setAnimation(0, '13_Drown_Loop', true);
    }
  }

  getCollisonRect() {
    const globalPosition = this.getGlobalPosition();
    this.collisonRectangle.pos.x = globalPosition.x;
    this.collisonRectangle.pos.y = globalPosition.y;

    return this.collisonRectangle;
  }

  onUpdate(delta, ticker) {
    // sine wave
    const amplitude = 10;
    const frequency = 0.001;
    this.y = this.startY + Math.sin(ticker.lastTime * frequency) * amplitude;

    // rotate with sine , to rock the boat from left to right
    const rotationAmplitude = 0.05;
    this.rotation = Math.sin(ticker.lastTime / 1000) * rotationAmplitude;

    if (
      this.player &&
      this.player.x >= this.startX &&
      this.player.x <= this.endX
    ) {
      this.x = this.player.x;
    }
  }

  removeBoat() {
    this.player = null;
    this.removeFromParent();
    this.destroy();
  }
}
