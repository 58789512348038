import * as PIXI from 'pixi.js';
import { Vector } from '../utils/helpers';
import LineHelper from '../utils/LineHelper';
import TextLink from './TextLink';
import BigStar from './BigStar';
import DashedBox from './DashedBox';

export default class DashedMenu extends PIXI.Container {
  constructor({
    width,
    height,
    links,
    style,
    delegate,
    padding = 17,
    startY = 40,
    fontSize = 50,
  }) {
    super();

    this.startY = startY;
    this.padding = padding;
    this.menuWidth = width;
    this.hmenuHeight = height;
    this.links = links;
    this.style = style || {
      fontFamily: 'Pixels',
      fontSize: fontSize,
      fill: 0xeae7bc,
      align: 'center',
    };
    this.delegate = delegate;
    this.stars = [];

    this.buildMenu();
  }

  buildMenu() {
    // draw a dashed container for the menu

    const graphics = new PIXI.Graphics();
    // set yellow color
    graphics.lineStyle(2, 0xeae7bc, 1);

    // determine all the points for the dashed rectangle to be drawn
    const width = this.menuWidth;
    const height = this.hmenuHeight;

    const dashedBox = new DashedBox(width, height);
    this.addChild(dashedBox);
    this.stars = dashedBox.stars.concat(dashedBox.stars);

    const startY = this.startY;
    const padding = this.padding;
    let y = startY;

    const style = this.style;

    for (let i = 0; i < this.links.length; i++) {
      const link = this.links[i];
      const linkObj = this.createLink(link.id, link.text, style, y);
      this.addChild(linkObj);
      y += linkObj.height + padding;
    }
  }

  createLink(id, text, style, y) {
    const link = new TextLink(text, this, style);
    link.position.set(0, y);
    link.id = id;
    return link;
  }

  onLinkClick(link) {
    if (link.id === 'the-artwork') {
      window.open('https://bird1000.com/about', '_blank');
    } else if (link.id === 'the-music') {
      window.open('https://bird1000.com/music', '_blank');
    } else if (link.id === 'the-story') {
      window.open('https://bird1000.com/story', '_blank');
    } else if (link.id === 'live-show') {
      window.open('https://bird1000.com/staged', '_blank');
    } else if (link.id === 'films') {
      window.open('https://bird1000.com/films', '_blank');
    } else if (link.id === 'merchandise') {
      window.open('https://bird1000.com/merch', '_blank');
    } else if (link.id === 'tigrans-website') {
      window.open('https://tigranhamasyan.com/home', '_blank');
    } else if (link.id === 'truth-io') {
      window.open('https://www.truth.io/', '_blank');
    } else if (link.id === 'credits') {
      window.open('https://bird1000.com/credits', '_blank');
    } else if (this.delegate && this.delegate.onLinkClick) {
      this.delegate.onLinkClick(link);
    }
  }
}
