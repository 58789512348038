import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import Importer from '../utils/importer';
import TweenGeneric from '../tweening/tween_generic';
import TweenIdle from '../tweening/tween_idle';
import { sound } from '@pixi/sound';
import Bezier from '../tweening/bezier';
import { Vector } from '../utils/helpers';
import { Util } from '../util';
import Player from '../gameObjects/player';
import CollisionBox from '../gameObjects/collisionBox';
import TweenShake from '../tweening/tween_shake';
import Collectable from '../gameObjects/Collectable';
import Actions from '../tweening/actions';
import Star from '../gameObjects/Star';
import SkyLine from '../gameObjects/SkyLine';
import TweenTick from '../tweening/tween_tick';
import WaterWaves from '../gameObjects/WaterWaves';
import WaterFog from '../gameObjects/LakeFog';
import TextLink from '../gameObjects/TextLink';
import TakeFX from '../gameObjects/TakeFX';
import MagicTree from '../gameObjects/MagicTree';
import API from '../utils/api';
import BackgroundEye from '../gameObjects/BackgroundEye';
import Tenticle from '../gameObjects/Tenticle';
import PauseMenu from '../gameObjects/PauseMenu';
import EndScreenMenu from '../gameObjects/EndScreenMenu';
import Label from '../ui/label';
import LeaderboardMenu from '../gameObjects/LeaderboardMenu';
import BlinkingEye from '../gameObjects/BlinkingEye';
import { ReflectionFilter } from 'pixi-filters';

const SUBTITLE_TRANSITION_TIME = 600;
const AREG_FLOOR_Y = 980;
const FOREST_ACTIVATION_POINT = 4000;
const RUINS_ACTIVATION_POINT = 19000;
const LAKE_ACTIVATION_POINT = 34500;
const CUTSCENE_EYE_START_POINT = 51374;
const CUTSCENE_HORSE_START_POINT = -28783;
const FINAL_SCENE_ACTIVATION_POINT = 41663;
const UP_PLAY_WIDTH = 400; // how much the player can move up left to right
let STAR_SPAWN_TIME = 100; // to be calculated
const START_LIFE_SPAN = 7000; // 5 sec
const STARS_SPACING = 40000; // the higher the number , less stars will be spawned
const BACKROUND_COLOR = 0x333333;
const CAMERA_PORTRAIT_ADJUSTMENT = -20;
const CAMERA_LANDSCAPE_ADJUSTMENT = -180;
const MUSIC_SYNC_POINT = 0.549; // where the eye animation starts
const PLAYER_DEMON_Y = -29790;
const PLAYER_DOWN_Y = -29700;
const PLAYER_UP_Y = -30300;

const BUILD_FOREST_X = 1400;
const BUILD_RUINS_X = 15000;
const BUILD_LAKE_X = 30000;

export const VOLUME_FX = 0.1;
export const VOLUME_AMBIENT = 0.1;
export const VOLUME_MUSIC = 0.2;

sound.disableAutoPause = true;

export default class GameScene extends PIXI.Container {
  constructor(delegate) {
    super();

    this.delegate = delegate;
    this.screenSize = delegate.screenSize;
    this.api = new API();
    this.score = 0;
    this.isKeyCollected = false;
    this.importer = new Importer();
    this.importer.isLazyImport = true;

    this.cameraPosition = new Vector(0, 0);
    this.trackingCenter = new Vector(this.screenSize.width / 2, 900);
    this.isCameraTracking = true;
    this.isPortrait = this.screenSize.width < this.screenSize.height;
    this.cameraLeftX = this.isPortrait
      ? CAMERA_PORTRAIT_ADJUSTMENT
      : this.screenSize.width / 3 + CAMERA_LANDSCAPE_ADJUSTMENT;

    this.isForestActivated = false;
    this.isRuninsCameraActive = false;
    this.isRuninsActivated = false;
    this.isCutsceneEyeActivated = false;
    this.isCutsceneEyePreActivated = false;
    this.isUpLevelCameraActive = false;
    this.isStarsAnimating = true;
    this.isCutsceneHorseActivated = false;
    this.isFinalSceneActivated = false;
    this.isLakeActivated = false;
    this.isHorseLevelActive = false;
    this.horseContainerVector = new Vector(0.5, 0);
    this.horseContainerVector.setAngle(Util.Math.degreesToRadians(135));
    this.shouldSyncWithMusic = false;
    this.isGameStarted = false;
    this.isIntroPlaying = true;

    this.isSwampBuilt = false;
    this.isForestBuilt = false;
    this.isRuinsBuilt = false;
    this.isLakeBuilt = false;

    this.backgroundMusic = null;
    this.ambientSound = null;
    this.boatAmbientSound = null;

    this.canMoveScreen = false;
    this.isFirstTrack = true; // used to fix a glitch in the tracking script
    this.collectables = [];

    this.upLevelCameraX = 0; // used to fix the camera left to right when player is flying in upLevel
    this.stars = []; // to be able to stop the eye cutscene
    this.timerSpawnStar = STAR_SPAWN_TIME;
    this.skyLines = [];
    this.backgroundLakeEyes = [];
    this.updateables = [];
    this.subTitles = []; // reference for the sub titles
    this.tenticleLevelTimers = [];

    this.clickCount = 0;

    const background = PIXI.Sprite.from(PIXI.Texture.WHITE);
    background.width = this.screenSize.width;
    background.height = this.screenSize.height;
    background.tint = 0xebe4ae;
    this.addChild(background);

    this.layers = [];
    this.triggers = [];

    this.background = background;

    this.gameContainer = new PIXI.Container();
    this.gameContainer.sortableChildren = true;
    this.addChild(this.gameContainer);

    this.introContainer = new PIXI.Container();
    this.introContainer.y -= 50;
    this.addChild(this.introContainer);

    const alpha = new PIXI.AlphaFilter(0);
    this.introContainer.filters = [alpha];
    new TweenGeneric(alpha, { alpha: 1 }, null, 400, () => {}).run();

    this.horseContainer = new PIXI.Container();
    this.gameContainer.addChild(this.horseContainer);

    /////////

    this.createUI();

    this.eventMode = 'static';
    this.addEventListener('pointerdown', this.onPointerDown.bind(this));
    this.addEventListener('pointerup', this.onPointerUp.bind(this));
    this.addEventListener('pointerout', this.onPointerOut.bind(this));

    document.body.onkeydown = (e) => {
      if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32) {
        this.onSpaceDown();
      }
    };

    document.body.onkeyup = (e) => {
      if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32) {
        this.onSpaceUp();
      }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////// MOVE TO RUNNING SCENE ////////////////////////////////////////////////////

    // this.importer.isLazyImport = false;

    // this.isGameStarted = true;
    // this.isIntroPlaying = false;
    // this.buildPlayer();
    // this.buildSwamp();
    // this.player.animation.scale.set(0.2);
    // this.player.y = 0;
    // this.player.animation.state.setAnimation(0, '5_Run', true);
    // this.playerLayer.addChild(this.player);
    // this.background.tint = BACKROUND_COLOR;

    // this.moveCameraToLeft();

    // /////////////// not real values , this is just before the real values are set
    // // 974   - swamp start
    // // 17000 - ruins start
    // // 33000 - boat start
    // // 50000 - eye cutscene
    // // 51379 - player location after cur scene
    // //

    // // stop all tweens

    // this.player.x = 50000;
    // this.track(this.player, true, 0);

    // new TweenIdle(100, () => {
    //   this.canMoveScreen = true;
    // }).run();

    // this.isDebug = true;

    // // -51075 mobile position of camera
    // // -50507 camera on desktop

    // // // ACTIVATE UP LEVEL fake it so it does not trigger it
    // // // HORSE ACTIVATES AT
    // // // -27083;
    // this.jumpToUpLevel(-27083);

    // this.isFinalSceneActivated = true;

    // this.jumpToDemonLevel();

    // setTimeout(() => {
    //   Actions.stopAll();
    //   this.jumpToFinalScene();
    // }, 200);

    // // this.onGameEnd();

    // return;

    // /////////////////////////////////////////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////////////////////////////////////////

    // // add gate darkness

    this.startMusicAt(0, () => {
      this.startIntroAnimation();
    });
  }

  startIntroAnimation() {
    this.gate = PIXI.Sprite.from(PIXI.Assets.get('intro_gate_empty'));
    this.gate.x = this.screenSize.width / 2;
    this.gate.y = this.screenSize.height / 2 + this.gate.height / 2;
    this.gate.anchor.set(0.5, 1);
    this.introContainer.addChild(this.gate);

    this.gateDark = PIXI.Sprite.from(PIXI.Assets.get('intro_gate_mask'));
    this.gateDark.position.set(this.gate.x, this.gate.y);
    this.gateDark.anchor.set(0.5, 1);
    this.introContainer.addChild(this.gateDark);
    this.gateDark.pushToBack();
    this.gate.bringToFront();

    // add gate dark part

    this.startAnimation();

    const kingData2 = PIXI.Assets.get('portrait-merged');
    const animation = new Spine(kingData2.spineData);
    animation.scale.set(0.1);
    animation.state.setAnimation(0, 'animation', false);
    animation.x = this.screenSize.width / 2;
    animation.y = 1000;
    this.introContainer.addChild(animation);
    this.introHeadAnimation = animation;

    this.splitHeadAnimation.state.timeScale = 0.9;
    this.introHeadAnimation.state.timeScale = 0.9;

    new TweenIdle(3000, () => {
      // play sound
      sound.play('SFX_Head-Apart', { volume: VOLUME_FX * 0.5 });
    }).run();

    //  L_half, R_half.
    // find bone by name
    const leftHalf = this.splitHeadAnimation.skeleton.findBone('L_half');
    const rightHalf = this.splitHeadAnimation.skeleton.findBone('R_half');

    const splitheadDelay = 7000;
    const splitingTime = 4000;
    const slipttingDistance = 3000;
    new TweenIdle(splitheadDelay, () => {
      leftHalf.active = false;
      rightHalf.active = false;

      new TweenGeneric(
        leftHalf,
        { x: leftHalf.x - slipttingDistance },
        null,
        splitingTime,
        () => {}
      ).run('intro-animation');
      new TweenGeneric(
        rightHalf,
        { x: rightHalf.x + slipttingDistance },
        null,
        splitingTime,
        () => {}
      ).run('intro-animation');
    }).run('intro-animation');

    const fadeoutKingHeadTime = 9500;

    new TweenGeneric(this.splitHeadAnimation, { alpha: 0 }, null, 1200)
      .delay(fadeoutKingHeadTime)
      .run('intro-animation');

    new TweenIdle(fadeoutKingHeadTime - 500, () => {
      this.startGrandMotherAnimation();
    }).run('intro-animation');

    this.addIntroSubtitles();

    // ADD SKIP BUTTON

    // START FALLING AREG ANIMATION AND PLAYER INITIALIZATION

    new TweenIdle(22000, () => {
      // set background color

      this.startFallingAregAnimation();
      this.buildSwamp();
      this.moveGateOut();

      // test blur
    }).run('intro-animation');

    /// DEBUG TIMELINE TIMERS
    // this.debugTimeline();
  }

  onIntroDoubleClicked() {
    // read from local storage if the intro was played
    const isIntroPlayed = localStorage.getItem('isIntroPlayed');

    if (isIntroPlayed) {
      this.showIntroBtn();
    }
  }

  showIntroBtn() {
    this.skipButton = new TextLink('SKIP INTRO', this, { fontSize: 18 });
    this.skipButton.setColor(0x333333);
    this.skipButton.id = 'skip-intro';
    this.skipButton.canHover = false;
    this.skipButton.underline.visible = true;
    this.skipButton.x = this.screenSize.width / 2;
    this.skipButton.y = 160;
    this.introContainer.addChild(this.skipButton);
  }

  setGameLogo(delay = 2000) {
    this.gameLogo = new PIXI.Sprite(PIXI.Assets.get('ui-game-logo'));
    this.gameLogo.anchor.set(0.5, 0.5);
    this.gameLogo.x = this.screenSize.width / 2;
    this.gameLogo.y = 400;
    this.gameLogo.alpha = 0;
    this.delegate.stage.addChild(this.gameLogo);

    new TweenGeneric(this.gameLogo, { alpha: 1 }, null, 2000)
      .delay(delay)
      .run('intro-animation');
  }

  moveGateOut() {
    const back = new PIXI.Graphics();
    back.beginFill(BACKROUND_COLOR);
    back.drawRect(0, 0, this.screenSize.width, this.screenSize.height);
    back.endFill();
    this.gameContainer.addChild(back);
    back.pushToBack();
    this.darkBackground = back;
    this.gameContainer.pushToBack();

    // GAME LOGO
    this.setGameLogo();

    const gateDelay = 1000;
    const aregPortraitShorningTime = 500;

    // HIDE AREG PORTRAIT
    new TweenGeneric(
      this.introPortraitAregAnimation.filters[0],
      { alpha: 0 },
      null,
      900
    )
      .delay(gateDelay - aregPortraitShorningTime)
      .run('intro-animation');

    const blurFilter = new PIXI.BlurFilter();
    blurFilter.blur = 0;
    blurFilter.quality = 2;
    this.gate.filters = [blurFilter];
    this.gateDark.filters = [blurFilter];
    new TweenGeneric(blurFilter, { blur: 15 }, null, 4000)
      .delay(gateDelay)
      .run('intro-animation');

    const scaleGate = 10;
    const gateMoveY = 900;
    const gateScaleDuration = 4000;

    const gateBezier = new Bezier(0.37, 0.06, 0.85, 0.68);

    // MOVE GATE
    new TweenGeneric(
      this.gate.scale,
      { x: scaleGate, y: scaleGate },
      gateBezier,
      gateScaleDuration
    )
      .delay(gateDelay)
      .run('intro-animation');

    new TweenGeneric(
      this.gate,
      { y: this.gate.y + gateMoveY },
      gateBezier,
      gateScaleDuration
    )
      .delay(gateDelay)
      .run('intro-animation');

    new TweenGeneric(
      this.gateDark.scale,
      { x: scaleGate, y: scaleGate },
      gateBezier,
      gateScaleDuration
    )
      .delay(gateDelay)
      .run('intro-animation');

    new TweenGeneric(
      this.gateDark,
      { y: this.gateDark.y + gateMoveY },
      gateBezier,
      gateScaleDuration
    )
      .delay(gateDelay)
      .run('intro-animation');

    const fadeOutDelay = 3300;
    const fadeOutDuration = 800;

    //fade out gate
    new TweenGeneric(this.gate, { alpha: 0 }, null, 300)
      .delay(fadeOutDelay)
      .run('intro-animation');

    new TweenGeneric(this.gateDark, { alpha: 0 }, null, fadeOutDuration)
      .delay(fadeOutDelay)
      .run('intro-animation');

    // Start hiding the background to fade in the swamp
    new TweenGeneric(this.background, { alpha: 0 }, null, 400)
      .delay(fadeOutDelay)
      .run('intro-animation');
  }

  addIntroSubtitles() {
    new TweenIdle(1000, () => {
      const st = this.addSubTitle(
        "A benevolent king shared his riches\nto ease his people's burdens.",
        4500
      );
      this.introContainer.addChild(st);
      this.subTitles.push(st);
    }).run('intro-animation');

    new TweenIdle(6000, () => {
      const st = this.addSubTitle(
        'During this celebration, a child\ntragically died under the feet of many.',
        6000
      );
      this.introContainer.addChild(st);
      this.subTitles.push(st);
    }).run('intro-animation');

    new TweenIdle(12000, () => {
      const st = this.addSubTitle(
        'The grandmother, seeking solace,\ntold the king',
        3000
      );
      this.introContainer.addChild(st);
      this.subTitles.push(st);
    }).run('intro-animation');

    new TweenIdle(15000, () => {
      const st = this.addSubTitle(
        'Only the song of a mystical bird\ncould bring redemption.',
        4000
      );
      this.introContainer.addChild(st);
      this.subTitles.push(st);
    }).run('intro-animation');

    new TweenIdle(19000, () => {
      const st = this.addSubTitle(
        'Son of the king, prince Areg,\nsets off to find this...',
        4000
      );
      this.introContainer.addChild(st);
      this.subTitles.push(st);
    }).run('intro-animation');
  }

  onSkipIntroClicked(link) {
    link.eventMode = 'none';
    if (!this.delegate.isSwampLoaded) {
      window.app.game.addWaitingAssetsEvent('swamp', this.skipIntro.bind(this));
      return;
    } else {
      this.skipIntro();
    }
  }

  skipIntro() {
    this.isIntroPlaying = false;
    if (this.gate) {
      this.gate.removeFromParent();
    }

    if (this.gateDark) {
      this.gateDark.removeFromParent();
    }
    // add gate dark part

    this.menuBtn.texture = PIXI.Assets.get('menu-light');

    // remove all spine animations
    if (this.introHeadAnimation) {
      this.introHeadAnimation.removeFromParent();
      this.introHeadAnimation.state.timeScale = 0;
      this.introHeadAnimation.destroy();
      this.introHeadAnimation = null;
    }

    if (this.splitHeadAnimation) {
      this.splitHeadAnimation.removeFromParent();
      this.splitHeadAnimation.state.timeScale = 0;
      this.splitHeadAnimation.destroy();
      this.splitHeadAnimation = null;
    }

    if (this.introPortraitAregAnimation) {
      this.introPortraitAregAnimation.removeFromParent();
      this.introPortraitAregAnimation.state.timeScale = 0;
      this.introPortraitAregAnimation.destroy();
      this.introPortraitAregAnimation = null;
    }

    // remove grandmother
    if (this.grandmotherAnimation) {
      this.grandmotherAnimation.removeFromParent();
      this.grandmotherAnimation.state.timeScale = 0;
      this.grandmotherAnimation.destroy();
      this.grandmotherAnimation = null;
    }

    if (this.gameLogo) {
      this.gameLogo.removeFromParent();
      this.gameLogo = null;
    }

    if (this.darkBackground) {
      this.darkBackground.removeFromParent();
      this.darkBackground = null;
    }

    // stop all tweens
    Actions.stopByTag('intro-animation');

    // remove subtitles from the screen
    this.removeAllSubTitles();

    // remove skip button
    this.skipButton.removeFromParent();

    // sync with music

    // create swamp and player and start running
    if (!this.isSwampBuilt) {
      this.buildPlayer();
      this.importer.isLazyImport = false;
      this.buildSwamp();
      this.importer.isLazyImport = true; // resume the lazy loading
    }

    this.player.animation.scale.set(0.2);
    this.player.y = 0;
    this.player.x = 974;
    this.track(this.player, true, 0);
    this.player.animation.state.setAnimation(0, '3_Idle', true);
    this.playerLayer.addChild(this.player);
    this.background.tint = BACKROUND_COLOR;

    // sync music here
    this.backgroundMusic.stop();
    this.startMusicAt(26.1);

    // re introduce the game logo
    this.setGameLogo(100);

    new TweenIdle(1500, () => {
      this.player.animation.state.setAnimation(0, '5_Run', true);
      this.canMoveScreen = true;
      this.moveCameraToLeft();

      new TweenGeneric(this.gameLogo, { alpha: 0 }, null, 1500, () => {
        this.gameLogo.removeFromParent();
      })
        .delay(1000)
        .run();
    }).run();
  }

  createUI() {
    this.uiLayer = new PIXI.Container();
    this.addChild(this.uiLayer);

    //create menu btn
    this.menuBtn = new PIXI.Sprite(PIXI.Assets.get('menu-gray'));
    this.menuBtn.x = 20;
    this.menuBtn.y = 20;
    this.menuBtn.eventMode = 'static';
    this.menuBtn.cursor = 'pointer';
    this.menuBtn.addEventListener('pointerdown', (e) => {
      e.preventDefault();
      e.stopPropagation();
      // this.menuBtn.texture = PIXI.Assets.get('menu-light');
    });
    this.menuBtn.addEventListener('pointerup', (e) => {
      // this.menuBtn.texture = PIXI.Assets.get('menu-gray');
      this.onMenuBtnClick(e);
      e.preventDefault();
      e.stopPropagation();
    });
    // pointerupoutside
    // this.menuBtn.addEventListener('pointerupoutside', () => {
    //   this.menuBtn.texture = PIXI.Assets.get('menu-gray');
    // });
    this.uiLayer.addChild(this.menuBtn);

    // position score counter to the right of the screen
    // ui-egg-points
    const scoreOffset = 70;
    this.scoreCounter = new PIXI.Sprite(PIXI.Assets.get('ui-egg-points'));
    this.scoreCounter.x = this.screenSize.width - scoreOffset;
    this.scoreCounter.y = scoreOffset;
    this.scoreCounter.anchor.set(0.5, 0.5);
    this.scoreCounter.scale.set(0.5);
    this.uiLayer.addChild(this.scoreCounter);

    window.scoreCounter = this.scoreCounter;

    // create score text
    const style = new Label({
      fontFamily: 'Pixels',
      fontSize: 120,
      fill: 0xebe4ae,
      align: 'center',
    });

    this.scoreText = new Label('0', style);
    this.scoreText.anchor.set(0.5, 0.5);
    this.scoreText.y = 40;
    this.scoreCounter.addChild(this.scoreText);
    window.scoreText = this.scoreText;
  }

  onMenuBtnClick() {
    // onPause
    this.delegate.pause();

    if (!this.pauseMenu) {
      this.pauseMenu = new PauseMenu(this, this.screenSize);
    }

    this.addChild(this.pauseMenu);
  }

  onResume() {
    this.pauseMenu.removeFromParent();
    this.delegate.resume();
  }

  jumpToFinalScene() {
    this.player.isLocked = true;
    for (let i = 0; i < this.tenticleLevelTimers.length; i++) {
      const timer = this.tenticleLevelTimers[i];
      clearTimeout(timer);
    }
    if (this.isDebug) {
      // this.startMusicAt(202.5);
    }
    this.onFinalScene();
  }

  jumpToUpLevel(y = 0) {
    this.buildCosmos();

    this.player.x = 51379;
    this.player.y = y;
    this.track(this.player, true, 0);

    this.afterCosmosBuild();

    this.isCutsceneEyeActivated = true;

    const frontAnimation = this.createFrontAnimation();
    this.activateUpLevel(frontAnimation);

    if (this.boat) {
      this.boat.removeBoat();
      this.boat = null;
    }

    new TweenIdle(1, () => {
      Actions.stopAll();

      this.player.velocity.x = 0;
      // this.canMoveScreen = true;
      this.isRuninsCameraActive = false;
      this.isEyeCameraActive = false;
      this.isUpLevelCameraActive = true;

      this.track(this.player, true, 0);

      new TweenGeneric(
        this.player,
        { y: this.player.y - 500 },
        null,
        300,
        () => {
          this.isCameraTracking = true;
          // up level start
          this.player.velocity.y = -1;
          this.player.isLocked = false;
          this.canMoveScreen = true;

          this.moveCameraToBottom();
        }
      )
        .delay(0)
        .run();
    }).run();
  }

  debugTimeline() {
    return;

    this.addDebugLabelVertical('Run', 28); // 28
    this.addDebugLabelVertical('RUINS', 57);
    this.addDebugLabelVertical('BOAT', 60 + 24);
    this.addDebugLabelVertical('Eye Cutscene', 60 + 52);

    this.addDebugLabelHorizontal('Start Vertical Play', 120 + 13);
    this.addDebugLabelHorizontal('Horse cutscene', 120 + 41);

    this.addDebugLabelVertical('Start Demon Level', 120 + 55);
    this.addDebugLabelHorizontal('Burst Egg', 180 + 23);
  }

  addDebugLabelVertical(text, delay, duration = 3000) {
    new TweenIdle(delay * 1000, () => {
      console.log(`${text}`, this.player.position);
      // add white big text to the middle of the screen
      const container = new PIXI.Container();
      container.x = this.screenSize.width / 2;
      this.addChild(container);

      const style = new Label({
        fontFamily: 'Arial',
        fontSize: 50,
        fill: 'white',
        align: 'center',
      });

      const message = new Label(text, style);
      message.anchor.set(0.5, 0.5);
      message.y = this.screenSize.height / 2;
      container.addChild(message);

      // draw a white rectangle in the middle of the text
      const gap = 100;
      const h = this.screenSize.height / 2 - gap / 2;

      const graphics = new PIXI.Graphics();
      graphics.lineStyle(2, 0xffffff, 0);
      graphics.beginFill(0xffffff, 0.8);
      graphics.drawRect(-4, 0, 8, h);
      graphics.drawRect(-4, this.screenSize.height / 2 + gap / 2, 8, h);

      graphics.endFill();
      container.addChild(graphics);

      new TweenIdle(duration, () => {
        container.removeFromParent();
      }).run();
    }).run();
  }

  addDebugLabelHorizontal(text, delay, duration = 3000) {
    new TweenIdle(delay * 1000, () => {
      console.log(`${text}`, this.player.position);
      // add white big text to the middle of the screen
      const container = new PIXI.Container();
      container.y = this.screenSize.height / 2;
      this.addChild(container);

      const style = new Label({
        fontFamily: 'Arial',
        fontSize: 50,
        fill: 'white',
        align: 'center',
      });

      const message = new Label(text, style);
      message.anchor.set(0.5, 0.5);
      message.x = this.screenSize.width / 2;
      container.addChild(message);

      // draw a white rectangle in the middle of the text
      const gap = message.width + 20;
      const h = this.screenSize.width / 2 - gap / 2;

      const graphics = new PIXI.Graphics();
      graphics.lineStyle(2, 0xffffff, 0);
      graphics.beginFill(0xffffff, 0.8);
      graphics.drawRect(-4, 0, h, 8);
      graphics.drawRect(this.screenSize.width / 2 + gap / 2, 0, h, 8);

      graphics.endFill();
      container.addChild(graphics);

      new TweenIdle(duration, () => {
        container.removeFromParent();
      }).run();
    }).run();
  }

  buildSwamp() {
    // where name starts with 'swamp-'

    console.log('start build swamp');
    if (this.isSwampBuilt) return;

    this.isSwampBuilt = true;

    this.delegate.app.renderer.background.color = BACKROUND_COLOR;

    const swampObjects = this.findLayersByNameGroup('swamp-');
    const playerLayers = this.findLayersByNameGroup('player-');

    this.importer.importObjects(
      swampObjects.concat(playerLayers),
      this.gameContainer,
      () => {
        this.playerLayer = this.gameContainer.findByName('player-layer-10');
        this.playerBackground = this.gameContainer.findByName(
          'player-background-1'
        );
        this.underwaterLights =
          this.gameContainer.findById('underwater-lights');
        this.underwaterLights.removeFromParent();

        this.starsLayer = new PIXI.Container();
        this.playerBackground.addChild(this.starsLayer);

        this.transparentLakeFadeout = null;

        this.layers = this.gameContainer.children;
        this.moveLayers(0, this.screenSize.height - 100);

        this.platforms = this.gameContainer.findByType(CollisionBox) || [];
        this.player.platforms = this.platforms;

        this.collectables = this.gameContainer.findByType(Collectable) || [];
        this.player.collectables = this.collectables;

        const reflections = this.gameContainer.findByTag('straw_reflection');
        for (let i = 0; i < reflections.length; i++) {
          const reflection = reflections[i];
          reflection.alpha = 0.5;
          // reflection.blendMode = PIXI.BLEND_MODES.ADD;
        }

        // create reflection for the player

        this.playerLayer.filters = [
          new ReflectionFilter({
            boundary: 0.915,
            waveLength: [30, 200],
            amplitude: [0, 5],
            alpha: [1, 0.9],
          }),
        ];

        // swamp-reflection

        this.playerLayer.filterArea = new PIXI.Rectangle(
          0,
          100,
          this.screenSize.width,
          this.screenSize.height + 520
        );

        this.reflectionFilter = this.playerLayer.filters[0];

        this.buildSky();

        console.log('done building swamp');
      }
    );
  }

  buildSky() {
    const objects = this.playerBackground.children;
    // add bland mode to all children
    for (let i = 0; i < objects.length; i++) {
      const container = objects[i];
      container.visible = true;

      for (let j = 0; j < container.children.length; j++) {
        const child = container.children[j];

        // if instance of SkyLine
        if (child instanceof SkyLine) {
          child.blendMode = PIXI.BLEND_MODES.ADD;
          this.skyLines.push(child);
        }
      }
    }

    // a single start on every 100x100 pixels space
    const numberOfStartToSpawn =
      (this.screenSize.width * this.screenSize.height) / 10000;
    STAR_SPAWN_TIME = STARS_SPACING / numberOfStartToSpawn;
    // build sky
  }

  buildForest() {
    // where name starts with 'swamp-'
    const levelObjects = this.findLayersByNameGroup('forest-');
    const container = this.gameContainer;

    this.forceLazyLoadingToFinish();

    console.log('START BUILDING FOREST');
    this.importer.importObjects(levelObjects, container, () => {
      console.log('END BUILDING FOREST');
      this.layers = this.gameContainer.children;

      this.platforms = this.gameContainer.findByType(CollisionBox) || [];
      this.player.platforms = this.platforms;

      this.collectables = this.gameContainer.findByType(Collectable) || [];
      this.player.collectables = this.collectables;

      const fog = this.gameContainer.findByType(WaterFog) || [];
      this.updateables = this.updateables.concat(fog);
    });

    // forest build
  }

  async buildRuins() {
    // where name starts with 'swamp-'
    const levelObjects = this.findLayersByNameGroup('ruins-');
    const container = this.gameContainer;

    this.importer.importObjects(levelObjects, container, () => {
      this.layers = this.gameContainer.children;

      this.platforms = this.gameContainer.findByType(CollisionBox) || [];
      this.player.platforms = this.platforms;

      this.collectables = this.gameContainer.findByType(Collectable) || [];
      this.player.collectables = this.collectables;
    });

    // ruins build
  }

  buildLake() {
    // where name starts with 'swamp-'
    const levelObjects = this.findLayersByNameGroup('lake-');
    const container = this.gameContainer;

    this.importer.importObjects(levelObjects, container, () => {
      this.layers = this.gameContainer.children;

      this.platforms = this.gameContainer.findByType(CollisionBox) || [];
      this.player.platforms = this.platforms;

      this.collectables = this.gameContainer.findByType(Collectable) || [];
      this.player.collectables = this.collectables;

      this.fogSprites = this.gameContainer.findByType(WaterFog) || [];

      const trees = this.gameContainer.findByType(MagicTree) || [];
      this.player.triggers = trees;

      this.backgroundLakeEyes =
        this.gameContainer.findByType(BackgroundEye) || [];
      for (let i = 0; i < this.backgroundLakeEyes.length; i++) {
        const eye = this.backgroundLakeEyes[i];
        eye.player = this.player;
        eye.screenSize = this.screenSize;
      }

      this.tenticles = this.gameContainer.findByType(Tenticle) || [];

      this.buildWaves();
    });

    if (!this.isDebug) {
      this.destroyForest();
    }
  }

  buildWaves() {
    const waterGroundLayer = this.gameContainer.findByName('lake-ground-9');
    const waterStart = waterGroundLayer.findById('lake-start');
    const waterEnd = waterGroundLayer.findById('lake-end');

    const width = waterEnd.x - waterStart.x;

    const texture = PIXI.utils.TextureCache['lake-waves-tile'];

    this.boat = this.gameContainer.findById('lake-boat');
    this.boat.player = this.player;
    this.boat.startX = waterStart.x;
    this.boat.endX = waterEnd.x;
    this.boat.x = waterStart.x;

    this.player.boat = this.boat;

    waterStart.y -= 30;

    // stretch a tile sprite
    const wavesBackground = new PIXI.Sprite(PIXI.Texture.WHITE);
    wavesBackground.width = width;
    wavesBackground.height = 150;
    wavesBackground.tint = BACKROUND_COLOR;
    wavesBackground.position.set(waterStart.x, waterStart.y + 40);
    this.playerLayer.addChild(wavesBackground);
    wavesBackground.pushToBack();

    this.wavesBackground = wavesBackground;

    const w1 = new WaterWaves(texture, width, 90);
    this.playerLayer.addChild(w1);
    w1.position.set(waterStart.x, waterStart.y);

    // add the boat between
    this.playerLayer.addChild(this.boat);

    const w2 = new WaterWaves(texture, width, 90);
    this.playerLayer.addChild(w2);
    w2.position.set(waterStart.x, waterStart.y + 30);

    const w3 = new WaterWaves(texture, width, 90);
    this.playerLayer.addChild(w3);
    w3.position.set(waterStart.x, waterStart.y + 60);

    w1.startX = 0;
    w2.startX = 60;
    w3.startX = 160;

    w1.phaseShift = 0;
    w2.phaseShift = 250;
    w3.phaseShift = 150;

    this.waterTiles = [w1, w2, w3];
  }

  buildCosmos() {
    // build cosmos
    // where name starts with 'swamp-'
    const levelObjects = this.findLayersByNameGroup('cosmos-');
    const container = this.gameContainer;

    this.importer.importObjects(levelObjects, container, () => {
      this.layers = this.gameContainer.children;

      this.platforms = this.gameContainer.findByType(CollisionBox) || [];
      this.player.platforms = this.platforms;

      this.collectables = this.gameContainer.findByType(Collectable) || [];
      this.player.collectables = this.collectables;

      // BlinkingEye

      // stretch the backgorund.
      this.cosmosClouds = this.gameContainer.findByName(
        'cosmos-background-6'
      ).children;

      this.cosmosTenticleObstacleLayer =
        this.gameContainer.findByName('cosmos-obsticles-9');

      this.cosmosBgTenticleLayer =
        this.gameContainer.findByName('cosmos-main-9');

      this.blinkingEyes =
        this.cosmosBgTenticleLayer.findByType(BlinkingEye) || [];

      // cosmos-front-9
      this.cosmosFrontLayer = this.gameContainer.findByName('cosmos-front-9');
      const demonFrontTenticles =
        this.cosmosFrontLayer.findByType(Tenticle) || [];
      this.updateables = this.updateables.concat(demonFrontTenticles);

      this.removeStars();
      // remove the clouds also
      this.playerBackground.removeChildren();

      //mid-tenticles
      const midTenticles = this.gameContainer.findById('mid-tenticles');
      midTenticles.player = this.player;
      this.updateables.push(midTenticles);

      const tenticles =
        this.cosmosTenticleObstacleLayer.findByType(Tenticle) || [];
      this.updateables = this.updateables.concat(tenticles);

      this.player.obstacles = [].concat(tenticles);

      const transitionTile = this.gameContainer.findById(
        'cosmos-transition-tile'
      );
      transitionTile.height = this.screenSize.width + 100;

      const transitionTile2 = this.gameContainer.findById(
        'cosmos-transition-tile-2'
      );
      transitionTile2.height = this.screenSize.width + 100;

      this.afterCosmosBuild();
    });

    if (!this.isDebug) {
      this.destroyRuins();
    }
  }

  afterCosmosBuild() {
    if (this.screenSize.width > 1200) {
      // get glocal cetner point
      const cp = new PIXI.Point(
        this.screenSize.width / 2,
        this.screenSize.height / 2
      );
      for (let i = 0; i < this.cosmosClouds.length; i++) {
        const cloud = this.cosmosClouds[i];
        // because they are positioned rotated by 90 degrees
        cloud.height = this.screenSize.width;
        const lp = cloud.parent.toLocal(cp);
        cloud.x = lp.x;
      }
    }
  }

  findLayersByNameGroup(name) {
    const objects = PIXI.Assets.get('swamp-level').objects;
    const targetObjects = [];
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (object.name.startsWith(name)) {
        object.visible = true;
        targetObjects.push(object);
      }
    }

    return targetObjects;
  }

  moveLayers(x, y) {
    for (let i = 0; i < this.layers.length; i++) {
      const layer = this.layers[i];
      if (x !== null) {
        layer.x = x * layer.factor;
      }

      if (y !== null) {
        layer.y = y * layer.factor;
      }
    }
  }

  startAregAnimation() {
    const data = PIXI.Assets.get('01_areg_portrait');
    const animation = new Spine(data.spineData);
    animation.autoUpdate = true;
    animation.scale.set(0.1);
    animation.state.setAnimation(0, 'animation', false);
    animation.x = this.screenSize.width / 2;
    animation.y = 924;
    animation.scale.set(0.09);
    this.introContainer.addChild(animation);

    this.introPortraitAregAnimation = animation;
    const alphaFilter = new PIXI.AlphaFilter(0);
    this.introPortraitAregAnimation.filters = [alphaFilter];

    new TweenGeneric(alphaFilter, { alpha: 1 }, null, 900).run(
      'intro-animation'
    );
  }

  buildPlayer() {
    this.player = new Player(this);
    this.player.x = this.screenSize.width / 2;

    return this.player;
  }

  startFallingAregAnimation(fallDuration = 4000) {
    this.buildPlayer();

    if (this.skipButton) {
      this.skipButton.removeFromParent();
    }

    this.isIntroPlaying = false;
    // the intro is considered to be over

    this.introContainer.addChild(this.player);
    this.player.y = 300;

    // add hiding asset
    const hiddingAsset = PIXI.Sprite.from(PIXI.Assets.get('gate-hidding-top'));
    hiddingAsset.anchor.set(0.5, 1);
    hiddingAsset.x = this.gate.x;
    hiddingAsset.y = this.gate.y;
    this.introContainer.addChild(hiddingAsset);

    // remove the hiding asset after 1 sec
    new TweenIdle(1000, () => {
      hiddingAsset.removeFromParent();
      PIXI.Assets.unload('gate-hidding-top');

      this.menuBtn.texture = PIXI.Assets.get('menu-light');
    }).run('intro-animation');

    this.gate.bringToFront();
    this.introPortraitAregAnimation.bringToFront();

    this.player.animation.state.setAnimation(0, '1_Intro_Loop', true);

    // increase the scale of the player as he is falling
    new TweenGeneric(
      this.player.animation.scale,
      { x: 0.2, y: 0.2 },
      null,
      fallDuration
    ).run('intro-animation');

    // move the player to the floor
    new TweenGeneric(
      this.player,
      { y: AREG_FLOOR_Y - this.introContainer.y },
      null,
      fallDuration
    ).run('intro-animation');

    new TweenIdle(fallDuration - 3000, () => {
      // transition from fall to idle
      this.player.animation.state.setAnimation(0, '2_Intro', false);
      new TweenIdle(3000, () => {
        this.player.animation.state.setAnimation(0, '3_Idle', true);
      }).run('intro-animation');
      new TweenIdle(3000 + 1500, () => {
        this.onStartRunLevel();
      }).run('intro-animation');
    }).run('intro-animation');
  }

  removeAllSubTitles() {
    for (let i = 0; i < this.subTitles.length; i++) {
      const st = this.subTitles[i];
      st.removeFromParent();
    }
    this.subTitles = [];
  }

  forceLazyLoadingToFinish() {
    // check with the importer
    console.log(
      `forceLazyLoadingToFinish: isLazyImport  ${this.importer.isLazyImport} , isImportingFinishded:${this.importer.isImportingFinishded}`
    );
    if (this.importer.isLazyImport && !this.importer.isImportingFinishded) {
      this.importer.isMustImport = true;
      this.importer.onUpdate(); // first call to finish the update
      this.importer.onUpdate(); // second call to trigger the callback
      console.log('FINISH FORECED LAZY LOADING');
    }
  }

  onStartRunLevel() {
    if (this.background) {
      this.background.removeFromParent();
    }

    if (this.darkBackground) {
      this.darkBackground.removeFromParent();
    }

    this.forceLazyLoadingToFinish();

    // remove all subTitles
    this.removeAllSubTitles();

    this.player.animation.state.setAnimation(0, '5_Run', true);
    // add player layer
    this.playerLayer.addChild(this.player);
    this.player.y = 0;
    this.canMoveScreen = true;

    this.moveCameraToLeft();

    this.clearIntroAnimations();

    new TweenGeneric(this.gameLogo, { alpha: 0 }, null, 1500, () => {
      this.gameLogo.removeFromParent();
    })
      .delay(1000)
      .run();

    localStorage.setItem('isIntroPlayed', true);
  }

  startGrandMotherAnimation() {
    const data = PIXI.Assets.get('01_grandmother-b');
    const animation = new Spine(data.spineData);
    animation.autoUpdate = true;
    animation.scale.set(0.1);
    animation.state.setAnimation(0, 'animation', false);
    animation.x = this.screenSize.width / 2;
    animation.y = 865;
    animation.scale.set(0.09);

    this.introContainer.addChild(animation);

    // end grandmother after 5 sec
    new TweenIdle(10000, () => {
      this.onGrandmaEnd();
    }).run('intro-animation');

    this.grandmotherAnimation = animation;
    this.grandmotherAnimation.alpha = 0;

    new TweenGeneric(this.grandmotherAnimation, { alpha: 1 }, null, 1200).run(
      'intro-animation'
    );
  }

  onGrandmaEnd() {
    new TweenGeneric(this.grandmotherAnimation, { alpha: 0 }, null, 600).run(
      'intro-animation'
    );
    this.startAregAnimation();
  }

  startAnimation() {
    const kingData = PIXI.Assets.get('01_king');
    const animation = new Spine(kingData.spineData);
    animation.autoUpdate = true;
    animation.scale.set(0.1);

    animation.state.setAnimation(0, 'animation', false);
    animation.x = this.screenSize.width / 2;
    animation.y = 1000;

    this.introContainer.addChild(animation);
    this.splitHeadAnimation = animation;
  }

  addSubTitle(
    text,
    duration,
    y = this.screenSize.height / 2 + 350,
    color = 0x333333
  ) {
    const style = {
      fontFamily: 'Pixels',
      fontSize: 50,
      fill: color,
      align: 'center',
    };

    const message = new Label(text, style);
    message.anchor.set(0.5, 0);
    message.x = this.screenSize.width / 2;
    message.y = y;
    message.roundPixels = true;
    message.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;

    message.alpha = 0;
    new TweenGeneric(
      message,
      { alpha: 1 },
      null,
      SUBTITLE_TRANSITION_TIME,
      () => {}
    ).run();
    new TweenIdle(duration, () => {
      new TweenGeneric(
        message,
        { alpha: 0 },
        null,
        SUBTITLE_TRANSITION_TIME,
        () => {
          message.removeFromParent();
        }
      ).run();
    }).run();

    return message;
  }

  addScore(score) {
    this.score += score;

    this.scoreText.text = this.score;
    if (this.score < 10) {
      this.scoreText.fontSize = 120;
    } else if (this.score < 1000) {
      this.scoreText.fontSize = 100;
    } else if (this.score < 10000) {
      this.scoreText.fontSize = 86;
    } else {
      this.scoreText.fontSize = 78;
    }
  }

  addScoreFromCollectable(collectable) {
    if (collectable.points) {
      this.addScore(collectable.points);
    }

    // add effect
    const effect = new TakeFX();
    effect.x = collectable.x;
    effect.y = collectable.y;
    collectable.parent.addChild(effect);

    // play sound

    const sounds = collectable.sounds;

    // iterate and play sounds
    for (let i = 0; i < sounds.length; i++) {
      const collectableSoundName = sounds[i];
      sound.play(collectableSoundName, {
        volume: 0.5 * VOLUME_FX,
      });
    }

    // add a label to the screen
    const x = collectable.position.x;
    const y = collectable.position.y - 100;
    this.addScoreLabel(collectable.points || '0', x, y, collectable.parent);

    if (collectable.isKey) {
      this.isKeyCollected = true;
    }
  }

  addScoreLabel(text, x, y, layer) {
    const style = new Label({
      fontFamily: 'Pixels',
      fontSize: 70,
      fill: 0xebe4ae,
      align: 'center',
    });
    const message = new Label(`+${text}`, style);
    message.anchor.set(0.5, 0);
    message.x = x;
    message.y = y;

    layer.addChild(message);

    const bezier = new Bezier(0.21, 0.88, 0.77, 0.98);
    new TweenGeneric(message, { y: message.y - 100 }, bezier, 800).run();
    new TweenGeneric(message, { alpha: 0 }, bezier, 800, () => {
      message.removeFromParent();
    })
      .delay(400)
      .run();
  }

  moveCameraToLeft() {
    // console.log('MOVE CAMERA TO LEFT');
    new TweenGeneric(
      this.trackingCenter,
      { x: this.cameraLeftX, y: 900 },
      null,
      2000
    ).run();
  }

  moveCameraToRuins() {
    // console.log('MOVE CAMERA TO RUINS');
    new TweenGeneric(
      this.trackingCenter,
      { x: this.cameraLeftX, y: 700 },
      null,
      1000
    ).run();
  }

  moveCameraCenter() {
    // console.log('MOVE CAMERA TO CENTER');
    new TweenGeneric(
      this.trackingCenter,
      { x: this.screenSize.width / 2, y: 700 },
      new Bezier(0.11, 0.65, 0.57, 0.98),
      3000
    ).run();
  }

  moveCameraTo(x, y) {
    // console.log('MOVE CAMERA TO ');
    new TweenGeneric(
      this.trackingCenter,
      { x, y },
      new Bezier(0.11, 0.65, 0.57, 0.98),
      3000
    ).run();
  }

  moveCameraToBottom() {
    // move camera target to bottom
    new TweenGeneric(
      this.trackingCenter,
      { x: this.screenSize.width / 2, y: 1050 },
      new Bezier(0.11, 0.65, 0.57, 0.98),
      2000
    ).run();
  }

  track(object, immidiate, delta) {
    if (!object || !this.isCameraTracking) {
      return;
    }
    const b = object.getGlobalPosition();
    var targetPosition = new Vector(b.x, b.y);
    targetPosition.x *= this.scale.x;
    targetPosition.y *= this.scale.y;

    var angle = Util.Math.getAngle(this.trackingCenter, targetPosition);
    var distance = Util.Math.getDistance(this.trackingCenter, targetPosition);

    distance = distance > 1 && !immidiate ? distance * 0.005 * delta : distance;
    var v = new Vector(0, 0);
    v.setLength(distance);
    v.setAngle(angle);
    this.cameraPosition.sub(v);

    if (this.isRuninsCameraActive) {
      this.ruinsCamera();
    } else if (this.isEyeCameraActive) {
      this.eyeCutSceneCamera();
    } else if (this.isUpLevelCameraActive) {
      this.upLevelCamera();
    } else {
      // forest camera
      this.forestCamera();
    }

    this.updateCameraLayers();
  }

  updateCameraLayers() {
    // move layers
    for (var i = 0; i < this.layers.length; i++) {
      var l = this.layers[i];
      var k = l.factor;
      l.position.set(this.cameraPosition.x * k, this.cameraPosition.y * k);
    }
  }

  forestCamera() {
    // it will be just a free follow

    if (this.cameraPosition.y < 980) {
      this.cameraPosition.y = 980;
    }

    // also limit the top

    if (this.cameraPosition.y > 1200) {
      this.cameraPosition.y = 1200;
    }
  }

  ruinsCamera() {
    // it will be just a free follow

    if (this.cameraPosition.y < 980) {
      this.cameraPosition.y = 980;
    }

    // also limit the top

    // if (this.cameraPosition.y > 1500) {
    //   this.cameraPosition.y = 1500;
    // }
  }

  eyeCutSceneCamera() {
    // just nothing
  }

  upLevelCamera() {
    // limit the camera from left to right
    this.cameraPosition.x = this.upLevelCameraX;
  }

  update(delta, ticker) {
    if (this.canMoveScreen) {
      if (this.player) {
        this.player.onUpdate(delta, ticker);
      }

      this.track(this.player, false, delta);
    }

    if (this.reflectionFilter) {
      //1573 3500
      // const x = Util.Math.clamp(this.player.x, 1573, 3500);
      // const alpha = Util.Math.map(x, 1573, 3500, 1, 0.5);

      const start = 1800;
      const end = 3500;
      const padding = 200;

      // const dy = this.cameraPosition.y - AREG_FLOOR_Y;
      // this.playerLayer.filterArea.y = dy + 280;

      if (this.player.x < start) {
        this.reflectionFilter.alpha = [0, 0];
      } else if (this.player.x > end) {
        this.playerLayer.filters = [];
        this.playerLayer.filterArea = null;
        this.reflectionFilter = null;
      } else if (this.player.x > end - padding) {
        const x = Util.Math.clamp(this.player.x, end - padding, end);
        const alpha = Util.Math.map(x, end - padding, end, 1, 0);
        this.reflectionFilter.alpha = [1 * alpha, 0.9 * alpha];
      } else if (this.player.x > start) {
        const x = Util.Math.clamp(this.player.x, start, start + padding);
        const alpha = Util.Math.map(x, start, start + padding, 0, 1);
        this.reflectionFilter.alpha = [1 * alpha, 0.9 * alpha];
      }
    }

    // special case
    if (this.player && this.player.isHorseMode) {
      this.player.onUpdate(delta, ticker);
    }

    for (let i = 0; i < this.collectables.length; i++) {
      const collectable = this.collectables[i];
      collectable.onUpdate(delta, ticker);
    }

    // trigger levels build

    if (this.player) {
      if (!this.isForestBuilt && this.player.position.x >= BUILD_FOREST_X) {
        this.isForestBuilt = true;
        this.buildForest();
      }

      if (!this.isRuinsBuilt && this.player.position.x >= BUILD_RUINS_X) {
        this.isRuinsBuilt = true;
        this.buildRuins();
        this.destroySwamp(15 * 1000);
      }

      if (!this.isLakeBuilt && this.player.position.x >= BUILD_LAKE_X) {
        this.isLakeBuilt = true;
        this.buildLake();
      }
    }

    /////////// ACTIVATE SCENES
    this.sceneActivator();

    if (this.waterTiles) {
      for (let i = 0; i < this.waterTiles.length; i++) {
        const waterTile = this.waterTiles[i];
        // animate with sine wave
        waterTile.tilePosition.x =
          waterTile.startX +
          Math.sin(waterTile.phaseShift + ticker.lastTime / 800) * 100;
        waterTile.onUpdate(delta, ticker);
      }
    }

    if (this.boat) {
      this.boat.onUpdate(delta, ticker);
    }

    if (this.stars) {
      for (let i = 0; i < this.stars.length; i++) {
        const star = this.stars[i];
        star.onUpdate(delta, ticker);
      }
    }

    this.timerSpawnStar -= ticker.deltaMS;
    if (this.timerSpawnStar <= 0) {
      this.timerSpawnStar += STAR_SPAWN_TIME;
      this.spawnStar();
    }

    // update skylines
    for (let i = 0; i < this.skyLines.length; i++) {
      const skyline = this.skyLines[i];
      skyline.onUpdate(delta, ticker);
    }

    // update fogSprites
    if (this.fogSprites) {
      for (let i = 0; i < this.fogSprites.length; i++) {
        const fog = this.fogSprites[i];
        fog.onUpdate(delta, ticker);
      }
    }

    if (this.backgroundLakeEyes) {
      for (let i = 0; i < this.backgroundLakeEyes.length; i++) {
        const eye = this.backgroundLakeEyes[i];
        eye.onUpdate(delta, ticker);
      }
    }

    if (this.tenticles) {
      for (let i = this.tenticles.length - 1; i >= 0; i--) {
        const tenticle = this.tenticles[i];
        tenticle.onUpdate(delta, ticker);
      }
    }

    if (this.updateables) {
      const gp = this.player ? this.player.getGlobalPosition() : null;
      for (let i = this.updateables.length - 1; i >= 0; i--) {
        const upldateable = this.updateables[i];
        upldateable.onUpdate(delta, ticker);

        if (upldateable.followPoint && gp) {
          upldateable.followPoint(gp);
        }
      }
    }

    if (this.blinkingEyes) {
      const gp = this.player ? this.player.getGlobalPosition() : null;
      for (let i = this.blinkingEyes.length - 1; i >= 0; i--) {
        const upldateable = this.blinkingEyes[i];
        upldateable.onUpdate(delta, ticker);

        if (upldateable.followPoint && gp) {
          upldateable.followPoint(gp);
        }

        const ugp = upldateable.getGlobalPosition();
        if (ugp.y > this.screenSize.height + 300) {
          this.blinkingEyes.splice(i, 1);

          upldateable.removeFromParent();
          upldateable.animation.destroy();
          upldateable.destroy();
        }
      }
    }

    if (this.isHorseLevelActive && !this.isFinalSceneActivated) {
      this.horseContainer.x += this.horseContainerVector.x * delta;
      this.horseContainer.y += this.horseContainerVector.y * delta;
    }

    if (this.importer && this.importer.isLazyImport) {
      this.importer.onUpdate();
    }
  }

  updatePaused(delta, ticker) {
    if (this.pauseMenu) {
      this.pauseMenu.update(delta, ticker);
    }
  }

  sceneActivator() {
    // activate forest
    if (
      this.player &&
      !this.isForestActivated &&
      this.player.position.x >= FOREST_ACTIVATION_POINT
    ) {
      this.isForestActivated = true;
      this.activateForest();
    }

    if (
      this.player &&
      !this.isRuninsActivated &&
      this.player.position.x >= RUINS_ACTIVATION_POINT
    ) {
      this.isRuninsActivated = true;
      this.activateRuins();
    }

    if (
      this.player &&
      !this.isLakeActivated &&
      this.player.x > LAKE_ACTIVATION_POINT
    ) {
      this.activateLake();
    }

    if (
      this.player &&
      !this.isCutsceneEyePreActivated &&
      this.player.position.x >= CUTSCENE_EYE_START_POINT - 1800
    ) {
      this.isCutsceneEyePreActivated = true;
      this.preActivateEyeCutscene();
    }

    if (
      this.player &&
      !this.isCutsceneEyeActivated &&
      this.player.position.x >= CUTSCENE_EYE_START_POINT
    ) {
      this.isCutsceneEyeActivated = true;
      this.activateEyeCutscene();
    }

    if (
      this.player &&
      !this.isCutsceneHorseActivated &&
      this.player.position.y <= CUTSCENE_HORSE_START_POINT
    ) {
      this.isCutsceneHorseActivated = true;
      this.activateHorseCutScene();
    }

    if (
      this.isHorseLevelActive &&
      this.horseContainer.x < FINAL_SCENE_ACTIVATION_POINT &&
      !this.isFinalSceneActivated
    ) {
      this.isFinalSceneActivated = true;
      this.onFinalScene();
    }
  }

  spawnStar() {
    if (!this.playerBackground || !this.stars) return;

    const start = new Star(START_LIFE_SPAN, this, this.stars);
    // set random position
    const x = Util.Math.randomInt(0, this.screenSize.width + 300); // running the side requires start to be there
    const y = Util.Math.randomInt(0, this.screenSize.height - 300);

    const pos = this.playerBackground.toLocal(new PIXI.Point(x, y));
    start.position = pos;

    this.starsLayer.addChild(start);
  }

  onResize(size) {
    this.screenSize = size;
    this.background.width = size.width;
    this.background.height = size.height;

    this.isPortrait = this.screenSize.width < this.screenSize.height;

    this.cameraLeftX = this.isPortrait
      ? CAMERA_PORTRAIT_ADJUSTMENT
      : this.screenSize.width / 3 + CAMERA_LANDSCAPE_ADJUSTMENT;

    if (this.scoreCounter) {
      this.scoreCounter.x = this.screenSize.width - 70;
    }
  }

  onShow() {}

  onHide() {}

  onPointerDown(event) {
    if (this.delegate.isPaused) return;

    if (this.player) {
      if (this.player.isLocked) return;
      this.player.onPointerDown();
    }
  }

  onPointerUp() {
    this.clickCount++;

    if (this.isIntroPlaying && this.clickCount === 2) {
      this.onIntroDoubleClicked();
    }

    if (this.delegate.isPaused) return;
    if (this.player) {
      this.player.onPointerRelesed();
    }
  }

  onPointerOut() {
    if (this.delegate.isPaused) return;
    if (this.player) {
      this.player.onPointerRelesed();
    }
  }

  onSpaceDown() {
    if (this.delegate.isPaused) return;
    if (this.player) {
      if (this.player.isLocked) return;
      this.player.onPointerDown();
    }
  }

  onSpaceUp() {
    if (this.delegate.isPaused) return;
    if (this.player) {
      this.player.onPointerRelesed();
    }
  }

  onPlayerGround(player) {
    // new TweenShake(this, 5, 0.2, null, 200).run();
  }

  onPlayerJump(player) {
    // console.log('jump');
    if (this.boatAmbientSound) {
      new TweenGeneric(this.boatAmbientSound, { volume: 0 }, null, 600).run();
    }
  }

  onPlayerFall(player) {
    // console.log('fall');
  }

  onPlayerPlatform(player) {
    // new TweenShake(this, 5, 0.2, null, 200).run();
  }

  onPlayerBoat() {
    // play boat sound
    if (!this.boatAmbientSound) {
      this.boatAmbientSound = sound.play('04_SFX_Boat-Floating', {
        loop: true,
        volume: 1 * VOLUME_FX,
      });
    } else {
      new TweenGeneric(
        this.boatAmbientSound,
        { volume: 1 * VOLUME_FX },
        null,
        600
      ).run();
    }
  }

  onPlayerCollectable(collectable) {
    this.addScoreFromCollectable(collectable);

    collectable.removeFromParent();
  }

  onPlayerTrigger(trigger) {
    sound.play('SFX_Glowing-Tree', { volume: 1 * VOLUME_FX });

    // add a takefx
    const effect = new TakeFX();
    effect.x = trigger.x;
    effect.y = trigger.y;

    const score = 1000;

    this.addScoreLabel(score, trigger.x, trigger.y - 100, trigger.parent);
    this.addScore(score);

    trigger.parent.addChild(effect);
    trigger.bringToFront();
  }

  onPlayerObstacle(obstacle) {
    this.player.takeDamage();
  }

  playAmbientSound(name, volume = 1) {
    if (this.ambientSound) {
      this.ambientTween.stop();
      const oldAmbient = this.ambientSound;
      new TweenGeneric(oldAmbient, { volume: 0 }, null, 2000, () => {
        oldAmbient.stop();
      }).run();
    }

    this.ambientSound = this.ambientSound = sound.play(name, {
      volume: 0,
      loop: true,
    });
    this.ambientTween = new TweenGeneric(
      this.ambientSound,
      { volume: volume * VOLUME_AMBIENT },
      null,
      2000
    ).run();
  }

  activateForest() {
    this.isForestActivated = true;
    this.playAmbientSound('02_AMB_Forest');
  }

  activateRuins() {
    this.isRuninsCameraActive = true;
    this.forceLazyLoadingToFinish();
    this.moveCameraToRuins();

    this.playAmbientSound('03_AMB_Ruins');
  }

  activateLake() {
    this.forceLazyLoadingToFinish();
    this.isLakeActivated = true;
    // add background
    this.playAmbientSound('04_AMB_River', 0.7);

    let w = 6000;
    const fadeOut = new PIXI.Container();

    const texture = PIXI.Assets.get('transparent-fadeout');
    while (w > 0) {
      const sprite = new PIXI.Sprite(texture);
      sprite.x = w;
      sprite.y = -200;
      sprite.height = 1200;
      fadeOut.addChild(sprite);
      w -= sprite.width;
    }

    // fadeOut.anchor.set(0, 1);
    // fadeOut.height = 1200;
    fadeOut.x = 3300;
    // fadeOut.y = -this.screenSize.height;

    this.playerBackground.addChild(fadeOut);
    this.transparentLakeFadeout = fadeOut;

    fadeOut.alpha = 0;
    new TweenGeneric(
      fadeOut,
      { alpha: 1 },
      new Bezier(0.32, 0.8, 0.67, 0.98),
      3000
    ).run();

    const ruinsMainLayer = this.gameContainer.findByName('ruins-main-11');

    // iterate ruinsMainLayer children
    ruinsMainLayer.removeFromParent(); // this layer is not needed anymore
    for (let i = 0; i < ruinsMainLayer.children.length; i++) {
      const child = ruinsMainLayer.children[i];
      // if child is instance of FireBucket remove it
      if (child instanceof FireBucket) {
        child.animation.destroy();
      }
      child.destroy();
    }
  }

  preActivateEyeCutscene() {
    this.eyeAnimation = this.buildEyeAnimation();
    this.eyeAnimation.state.setAnimation(0, 'lurking', true);

    this.eyeLaserAnimation = this.buildLaserEyeAnimation();

    // to take care of the camera

    new TweenIdle(2000, () => {
      this.isCameraTracking = false;

      new TweenGeneric(
        this.cameraPosition,
        { x: -CUTSCENE_EYE_START_POINT + this.screenSize.width / 2, y: 980 },
        new Bezier(0.48, 0.27, 0.55, 0.93),
        2000
      ).run();

      new TweenTick(2000, () => {
        this.updateCameraLayers();
      }).run();
    }).run();
  }

  activateEyeCutscene() {
    if (this.boatAmbientSound) {
      new TweenGeneric(this.boatAmbientSound, { volume: 0 }, null, 1000).run();
    }

    if (this.ambientSound) {
      const oldAmbient = this.ambientSound;
      new TweenGeneric(oldAmbient, { volume: 0 }, null, 2000, () => {
        oldAmbient.stop();
      }).run();
    }

    if (this.isDebug) {
      this.startMusicAt(60 + 52);
    }

    this.player.isRunnerMode = false;
    this.player.velocity.x = 0; // no speed
    this.player.isLocked = true;
    // play idle animation
    this.player.animation.state.setAnimation(0, '11_Swim', true);
    new TweenGeneric(this.player, { y: 0 }, null, 300).run(); // make sure he is on the ground

    /// START ANIMATIONS HERE

    this.eyeAnimation.state.setAnimation(0, 'lurking', true);
    // const alphaFilter = new PIXI.AlphaFilter(0);
    // this.eyeAnimation.filters = [alphaFilter];
    // new TweenGeneric(alphaFilter, { alpha: 1 }, null, 1000).delay().run();
    //

    // Ajdust this time to match the eye animation
    // It will sync with the music if the music is playing

    // adjust eye position slowly

    // const p = new PIXI.Point(CUTSCENE_EYE_START_POINT, -600);

    if (!sound.isPlaying()) {
      console.log('NO MUSIC SYNC , just start the eye animation');
      const WAIT_FOR_EYE_DURATION = 2000;
      new TweenIdle(WAIT_FOR_EYE_DURATION, () => {
        this.startEyeAnimation();
      }).run();
    } else {
      console.log('MUSIC SYNC , please wait !');
      /// 60 + 52 + 4 = 116
      // 3:31 = 60*3+31 = 211
      // sync with the music
      this.shouldSyncWithMusic = true;
    }
  }

  moveEyeToPosition() {
    const gp = this.eyeAnimation.getGlobalPosition();
    const dx = gp.x - this.screenSize.width / 2;
    const mx = this.eyeAnimation.x - dx * 0.8;
    const duration = 3000;

    new TweenGeneric(
      this.eyeAnimation,
      { x: mx },
      new Bezier(0.42, 0.31, 0.45, 0.97),
      duration
    ).run();

    new TweenGeneric(
      this.eyeLaserAnimation,
      { x: mx },
      new Bezier(0.42, 0.31, 0.45, 0.97),
      duration
    ).run();
  }

  startEyeAnimation() {
    // build underwater texture below the waves

    this.moveEyeToPosition();

    const underwaterBackground = new PIXI.Graphics();
    this.underwaterBackground = underwaterBackground;
    // draw a rect
    underwaterBackground.beginFill(BACKROUND_COLOR);
    underwaterBackground.drawRect(
      0,
      0,
      this.screenSize.width,
      this.screenSize.height + 400
    );
    underwaterBackground.endFill();
    underwaterBackground.pivot.set(this.screenSize.width / 2, 0);

    const layer = this.playerLayer;
    layer.addChild(underwaterBackground);
    underwaterBackground.x = this.player.x;
    underwaterBackground.y = 100;
    underwaterBackground.pushToBack();

    this.wavesBackground.pushToBack(); // again

    // Ray_2 , Ray_4 , Ray_6
    new TweenIdle(1500, () => {
      this.eyeAnimation.state.setAnimation(0, 'Anim', false);
      this.eyeLaserAnimation.state.setAnimation(0, 'Anim', false);

      new TweenIdle(100, () => {
        sound.play('05_SFX_Lightray', { volume: 1 * VOLUME_FX });
      }).run();

      new TweenIdle(2000, () => {
        sound.play('05_SFX_Boat-Hit', { volume: 1 * VOLUME_FX });
      }).run();
    }).run();

    new TweenIdle(4500, () => {
      this.eyeAnimation.state.setAnimation(0, 'Anim_Loop', true);
      this.eyeLaserAnimation.state.setAnimation(0, 'Anim_Loop', true);
      const fireSound = sound.play('05_SFX_Boat-on-Fire', {
        volume: 1 * VOLUME_FX,
      });

      new TweenGeneric(fireSound, { volume: 0 }, null, 3000, () => {
        fireSound.stop();
      })
        .delay(3000)
        .run();
      new TweenIdle(4000, () => {
        new TweenGeneric(this.eyeAnimation, { alpha: 0 }, null, 1000, () => {
          // Hide the eye
          this.eyeAnimation.visible = false;
          this.eyeAnimation.autoUpdate = false;

          this.eyeLaserAnimation.visible = false;
          this.eyeLaserAnimation.autoUpdate = false;
        }).run();
      }).run();
    }).run();

    new TweenIdle(3000, () => {
      this.boat.startAnimation();

      const underWaterAmbient = sound.play('05_AMB_Underwater', { volume: 0 });
      new TweenGeneric(
        underWaterAmbient,
        { volume: VOLUME_AMBIENT },
        null,
        8000
      )
        .delay(2000)
        .run();

      // START TO SINK TO THE BOTTOM
      this.canMoveScreen = true;
      this.isRuninsCameraActive = false;
      this.isEyeCameraActive = true;

      this.player.animation.state.setAnimation(0, '12_Swim-Hit', false);

      /// add glow
      const glow = PIXI.Sprite.from(PIXI.Assets.get('underwater_glow'));
      glow.x = this.screenSize.width / 2;
      glow.anchor.set(0.5, 0.5);
      glow.blendMode = PIXI.BLEND_MODES.ADD;
      glow.y = 200;
      glow.scale.set(1.2);
      underwaterBackground.addChild(glow);
      glow.alpha = 0;
      new TweenGeneric(glow, { alpha: 1 }, null, 200).delay(1500).run();

      // add cloud to act as a line

      this.underwaterLights.x = glow.x;
      this.underwaterLights.y = 200;
      this.underwaterLights.visible = true;

      // go throguh the children of underwaterLights , and set blend mode
      for (let i = 0; i < this.underwaterLights.children.length; i++) {
        const child = this.underwaterLights.children[i];
        child.alpha = 0.2;
        child.blendMode = PIXI.BLEND_MODES.ADD;
      }

      underwaterBackground.addChild(this.underwaterLights);

      const SINKING_DURATION = 9300;
      // KEEP DROWNING FOR SOME TIME

      // CAMERA start camera tracking again
      this.isCameraTracking = true;
      const CAMERA_Y = this.screenSize.height / 2 + 200;
      this.upLevelCameraX =
        -CUTSCENE_EYE_START_POINT + this.screenSize.width / 2;

      this.cameraPosition.x = this.upLevelCameraX;
      this.trackingCenter.x = this.screenSize.width / 2;

      new TweenGeneric(
        this.trackingCenter,
        { y: CAMERA_Y },
        new Bezier(0.11, 0.65, 0.57, 0.98),
        2000
      ).run();

      const frontAnimation = this.createFrontAnimation();

      new TweenIdle(SINKING_DURATION, () => {
        this.player.animation.state.setAnimation(0, '14_Drown', false);

        // BUST OUT OF THE WATER
        this.buildCosmos(); // aslo build the next level here

        new TweenIdle(3000, () => {
          // GRAB SWORD
          sound.play('05_SFX_Areg-Sword-Grab', { volume: 1 * VOLUME_FX });
          // hide the eye
        }).run();

        this.player.animation.state.addListener({
          complete: (event) => {
            if (event.animation.name === '14_Drown') {
              underWaterAmbient.stop();
              this.activateUpLevel(frontAnimation);
            }
          },
        });

        // destroy the lake
        this.destroyLake();
      }).run();

      new TweenGeneric(
        this.player,
        { y: this.player.y + 1000 },
        new Bezier(0.33, 0.75, 0.58, 0.97),
        SINKING_DURATION + 2000,
        () => {}
      )
        .delay(1500)
        .run();
    }).run();
  }

  activateUpLevel(frontAnimation) {
    this.swapPlayerAnimation(frontAnimation);
    sound.play('05_SFX_Areg-Catapults', { volume: 1 * VOLUME_FX });

    this.forceLazyLoadingToFinish();

    // Up Left Right
    this.player.animation.state.setAnimation(0, 'Up', true);
    this.player.activateUpMechanics();

    const x = this.player.x - UP_PLAY_WIDTH / 2;
    const y = this.player.x + UP_PLAY_WIDTH / 2;
    this.player.leftLocationX = x;
    this.player.rightLocationX = y;

    // this will not allow the camera to move from left to right
    this.upLevelCameraX = this.cameraPosition.x;

    // activate camera
    this.isUpLevelCameraActive = true;
    this.isEyeCameraActive = false;
    this.isRuninsCameraActive = false;

    this.moveCameraToBottom();

    // move the player to the left side
    new TweenIdle(1000, () => {
      this.player.moveLeft();
    }).run();
  }

  buildEyeAnimation() {
    const bData = PIXI.Assets.get('B_Eye');
    const eyeAnimation = new Spine(bData.spineData);
    eyeAnimation.autoUpdate = true;
    eyeAnimation.scale.set(0.4);

    const evilEyeObject = this.gameContainer.findById('evil-eye');
    eyeAnimation.x = evilEyeObject.x;
    eyeAnimation.y = evilEyeObject.y;
    evilEyeObject.parent.addChild(eyeAnimation);
    eyeAnimation.pushToBack();

    // aslo adjust the waves
    // this.waterTiles[1].bringToFront();
    // this.waterTiles[2].bringToFront();

    return eyeAnimation;
  }

  buildLaserEyeAnimation() {
    const bData = PIXI.Assets.get('Laser_Eye');
    const eyeAnimation = new Spine(bData.spineData);
    eyeAnimation.autoUpdate = true;
    eyeAnimation.scale.set(0.4);

    const evilEyeObject = this.gameContainer.findById('evil-eye');
    eyeAnimation.x = evilEyeObject.x;
    eyeAnimation.y = evilEyeObject.y;
    evilEyeObject.parent.addChild(eyeAnimation);

    // aslo adjust the waves
    // this.waterTiles[1].bringToFront();
    // this.waterTiles[2].bringToFront();

    return eyeAnimation;
  }

  createFrontAnimation() {
    const bData = PIXI.Assets.get('Human_Front');

    const aregFrontAnimation = new Spine(bData.spineData);
    aregFrontAnimation.autoUpdate = true;
    aregFrontAnimation.scale.set(0.2);
    aregFrontAnimation.state.timeScale = 1.5;

    return aregFrontAnimation;
  }

  swapPlayerAnimation(aregFrontAnimation) {
    const oldAnimation = this.player.animation;
    oldAnimation.autoUpdate = false;
    oldAnimation.visible = false;
    new TweenIdle(100, () => {
      oldAnimation.removeFromParent();
    }).run();
    // add new animation
    this.player.animation = aregFrontAnimation;
    this.player.addChild(aregFrontAnimation);
  }

  clearIntroAnimations(delay = 1000) {
    if (!this.splitHeadAnimation) {
      return;
    }
    // clear intro animations
    new TweenIdle(delay, () => {
      this.splitHeadAnimation.removeFromParent();
      this.grandmotherAnimation.removeFromParent();
      this.introPortraitAregAnimation.removeFromParent();
      this.introHeadAnimation.removeFromParent();

      // call destory on the animations
      this.splitHeadAnimation.destroy();
      this.grandmotherAnimation.destroy();
      this.introPortraitAregAnimation.destroy();
      this.introHeadAnimation.destroy();

      this.splitHeadAnimation = null;
      this.grandmotherAnimation = null;
      this.introPortraitAregAnimation = null;
      this.introHeadAnimation = null;

      this.removeIntroAssets();
    }).run();
  }

  removeIntroAssets(delay = 1000) {
    new TweenIdle(delay, () => {
      PIXI.Assets.unload('01_king');
      PIXI.Assets.unload('01_grandmother-b');
      PIXI.Assets.unload('portrait-merged');
      PIXI.Assets.unload('01_areg_portrait');

      this.gate.removeFromParent();
      this.gate.destroy();
      this.gate = null;

      PIXI.Assets.unload('intro_gate');

      // Destroy the intro assets
    }).run();
  }

  destroySwamp(delay = 1000) {
    // find all children with name starting with swamp

    new TweenIdle(delay, () => {
      const layers = this.gameContainer.children;
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        if (layer.name && layer.name.startsWith('swamp-')) {
          layer.removeFromParent();
        }
      }

      PIXI.Assets.unload('foreground');
      PIXI.Assets.unload('reflection');
      PIXI.Assets.unload('rocks-stand1');
      PIXI.Assets.unload('rocks-stand2');
      PIXI.Assets.unload('rocks1');
      PIXI.Assets.unload('rocks2');
      PIXI.Assets.unload('straws-main');
      PIXI.Assets.unload('straws');

      // remove the swamp assets
    }).run();
  }

  destroyForest(delay = 1000) {
    new TweenIdle(delay, () => {
      const layers = this.gameContainer.children;
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        if (layer.name && layer.name.startsWith('forest-')) {
          layer.removeFromParent();
        }
      }

      PIXI.Assets.unload('trees_atlas0');
      PIXI.Assets.unload('trees_atlas1');

      // destroy the forest assets
    }).run();
  }

  destroyRuins(delay = 5000) {
    new TweenIdle(delay, () => {
      // destroy the ruins assets
      const layers = this.gameContainer.children;
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        if (layer.name && layer.name.startsWith('ruins-')) {
          layer.removeFromParent();
        }
      }
    }).run();
  }

  destroyLake(delay = 5000) {
    new TweenIdle(delay, () => {
      // destroy the lake assets
      if (this.boatAmbientSound) {
        this.boatAmbientSound.stop();
      }

      const layers = this.gameContainer.children;
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        if (layer.name && layer.name.startsWith('lake-')) {
          layer.removeFromParent();
        }
      }

      for (let i = 0; i < this.waterTiles.length; i++) {
        const waterTile = this.waterTiles[i];
        waterTile.removeFromParent();
      }

      this.waterTiles = null;

      this.boat.removeFromParent();
      this.player.boat = null;
      this.boat = null;

      if (this.underwaterBackground) {
        this.underwaterBackground.removeFromParent();
        this.underwaterBackground = null;
      }

      if (this.transparentLakeFadeout) {
        this.transparentLakeFadeout.removeFromParent();
        this.transparentLakeFadeout = null;
      }

      if (this.eyeAnimation) {
        this.eyeAnimation.removeFromParent();
        this.eyeAnimation = null;

        this.eyeLaserAnimation.removeFromParent();
        this.eyeLaserAnimation = null;
      }

      // unload lake assets

      PIXI.Assets.unload('lake-tree-magic');
      PIXI.Assets.unload('underwater_glow');
      PIXI.Assets.unload('lake-atlas');

      PIXI.Assets.unload('B_Eye');
      PIXI.Assets.unload('Laser_Eye');
    }).run();
  }

  removeStars() {
    if (this.stars) {
      for (let i = 0; i < this.stars.length; i++) {
        const star = this.stars[i];
        star.removeFromParent();
      }

      this.stars = null;
    }
  }

  startMusicAt(time = 0) {
    if (time) {
      this.backgroundMusic = sound.play('bgMusic', {
        start: time,
        volume: VOLUME_MUSIC,
      });
    } else {
      this.backgroundMusic = sound.play('bgMusic', {
        loaded: () => {
          console.log('music loaded');
        },
        volume: VOLUME_MUSIC,
      });
    }

    this.backgroundMusic.on(
      'progress',
      this.onBackgroundMusicProgress.bind(this)
    );

    this.backgroundMusic.on('end', this.onMusicend.bind(this));
  }

  onBackgroundMusicProgress(progress) {
    if (!this.isGameStarted) {
      this.isGameStarted = true;
      this.startIntroAnimation();
    }

    if (this.shouldSyncWithMusic) {
      // find the percentage
      if (progress > MUSIC_SYNC_POINT) {
        this.shouldSyncWithMusic = false;
        this.startEyeAnimation();
      }
    }
  }

  onMusicend() {
    console.log('on music END');
  }

  showSubTitles2() {
    const y = 300;
    this.subTitles = [];
    const color = 0xebe4ae;

    new TweenIdle(300, () => {
      const st = this.addSubTitle(
        'In a realm shadowed by chaos\nwith the mystical bird as his beacon',
        5000,
        y,
        color
      );
      this.addChild(st);
      this.subTitles.push(st);
    }).run('sub-titles-2');

    // new TweenIdle(2900, () => {
    //   const st = this.addSubTitle(
    //     'With the mystical bird as his beacon',
    //     2000,
    //     y,
    //     color
    //   );
    //   this.addChild(st);
    //   this.subTitles.push(st);
    // }).run('sub-titles-2');

    new TweenIdle(5500, () => {
      const st = this.addSubTitle(
        'Areg pierces through\nthe eyes of darkness',
        2800,
        y,
        color
      );
      this.addChild(st);
      this.subTitles.push(st);
    }).run('sub-titles-2');

    new TweenIdle(8800, () => {
      const st = this.addSubTitle(
        'This brave act echoes an awakening\nthat spans beyond time itself',
        4500,
        y,
        color
      );
      this.addChild(st);
      this.subTitles.push(st);
    }).run('sub-titles-2');
  }

  showSubTitles3() {
    let y;
    this.subTitles = [];
    const color = 0xebe4ae;

    new TweenIdle(300, () => {
      const st = this.addSubTitle(
        'Questing for the eternal\nhymn of the bird',
        2400,
        y,
        color
      );
      this.addChild(st);
      this.subTitles.push(st);
    }).run('sub-titles-3');

    new TweenIdle(3500, () => {
      const st = this.addSubTitle(
        "Areg's trials composed\na symphony within",
        3500,
        y,
        color
      );
      this.addChild(st);
      this.subTitles.push(st);
    }).run('sub-titles-3');
  }

  activateHorseCutScene() {
    // activate horse cutscene
    if (this.isDebug) {
      // this.startMusicAt(120 + 41); // 120 + 41
    }

    this.showSubTitles2();

    const AREG_FLAYING_TIME = 7600;
    const HORSE_FLAYING_TIME = 4500;
    // demon-background

    // lock the player
    this.player.isLocked = true;

    // move the player to the center of the gameplay area
    new TweenGeneric(
      this.player,
      { x: CUTSCENE_EYE_START_POINT },
      null,
      AREG_FLAYING_TIME,
      () => {
        // add horse animation to the screen
        const data = PIXI.Assets.get('Horse');
        const animation = new Spine(data.spineData);
        animation.autoUpdate = true;
        animation.scale.set(0.2);
        animation.x = this.player.x;
        animation.y = this.player.y;
        this.playerLayer.addChild(animation);
        animation.state.setAnimation(0, 'Right', true);
        this.horseAnimation = animation;
        this.horseAnimation.pushToBack();

        // Animate horse to move

        this.horseAnimation.x -= 300;
        this.horseAnimation.y += this.screenSize.height / 2 + 200;

        new TweenIdle(2000, () => {
          // SFX_Horse-Flaming || 07_SFX_Horse-Appears
          sound.play('SFX_Horse-Flaming', { volume: 1 * VOLUME_FX });
        }).run();

        new TweenGeneric(
          this.horseAnimation,
          { x: this.player.x, y: this.player.y + 110 },
          new Bezier(0.33, 0.75, 0.59, 0.95),
          HORSE_FLAYING_TIME,
          () => {
            this.jumpOnHorse();
          }
        ).run();
      }
    ).run();

    // slow down the player
    new TweenGeneric(this.player.velocity, { y: 0 }, null, 2000, () => {
      console.log('TODO make sure the player is at the right position here');
      this.player.y = PLAYER_DEMON_Y;
    }).run();
    // move camera to the center
    this.moveCameraTo(
      this.screenSize.width / 2,
      this.screenSize.height / 2 + 300
    );

    //////////// CREATE SHADOW SELF

    // add shadow self animation , Shadow-self
    const data = PIXI.Assets.get('Shadow-self');
    const animation = new Spine(data.spineData);
    animation.autoUpdate = true;
    animation.scale.set(0.6);
    const shadowSelfContainer = this.gameContainer.findById('shadow-self');
    shadowSelfContainer.addChild(animation);
    animation.x -= 820;
    animation.y -= 830;

    // 0.8 -1000
    // 0.6

    // set animation at first frame

    // add event

    // add mix
    animation.stateData.setMix('LOOP_sleep', 'Twitching', 0.2);
    animation.stateData.setMix('Twitching', 'LOOP_sleep', 0.2);
    animation.stateData.setMix('LOOP_sleep', 'START', 0.2);
    animation.stateData.setMix('START', 'LOOP', 0.2);

    animation.alpha = 0;
    animation.state.setAnimation(0, 'LOOP_sleep', false);
    new TweenGeneric(animation, { alpha: 1 }, null, 300, () => {}).run();

    this.shadowSelf = animation;
    // push shadow self behind the player
    shadowSelfContainer.pushToBack();
    // START , LOOP , LOOP_sleep, Twitching

    for (let i = 0; i < 5; i++) {
      new TweenIdle(10000 + i * 1000 * Util.Math.randomInt(4, 9), () => {
        // don't twith if the player is locked
        if (this.player.isLocked) return;
        this.shadowSelf.state.setAnimation(0, 'Twitching', false);
      }).run();
    }

    setTimeout(() => {
      animation.state.addListener({
        complete: (event) => {
          if (event.animation.name === 'START') {
            animation.state.setAnimation(0, 'LOOP', true);
          } else if (event.animation.name === 'Twitching') {
            animation.state.setAnimation(0, 'LOOP_sleep', true);
          }
        },
      });
    }, 200);

    // remove all eyes from the cosmos
    if (!this.isDebug) {
      this.blinkingEyes.forEach((eye) => {
        eye.removeFromParent();
        eye.destroy();
      });
      this.blinkingEyes = [];
      console.log('remove blinking eyes');
    }
  }

  buildDemonLevel() {
    this.horseContainer.x = this.player.x;
    this.horseContainer.y = this.player.y;

    // move the player back a litle bit
    if (this.isPortrait) {
      new TweenGeneric(
        this.player,
        { x: this.player.x - 100 },
        null,
        3000
      ).run();
    }

    const tenticleContainer = this.gameContainer.findById(
      'demon-tenticle-container'
    );

    // move tenticle container to the center of the screen

    const c = new PIXI.Point(
      this.screenSize.width / 2,
      this.screenSize.height / 2
    );
    const lp = tenticleContainer.parent.toLocal(c);
    tenticleContainer.x = lp.x;
    tenticleContainer.y = lp.y;

    this.tenticleContainer = tenticleContainer;

    const gameObjects = PIXI.Assets.get('gameObjects');
    this.demonTenticleData = this.importer.findDataById(
      'demon-tenticle',
      gameObjects.objects
    );

    this.demonShortTenticleData = this.importer.findDataById(
      'demon-short-tenticle',
      gameObjects.objects
    );

    // build demon level

    const t1 = setTimeout(() => {
      let demonTenticle = this.createShortDemonTenticle();
      demonTenticle.setUpTenticle();
      this.player.obstacles.push(demonTenticle);
      window.demonTenticle = demonTenticle;
    }, 100);
    this.tenticleLevelTimers.push(t1);

    const t2 = setTimeout(() => {
      let demonTenticle = this.createShortDemonTenticle();
      demonTenticle.setDownTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 2000);
    this.tenticleLevelTimers.push(t2);

    const t3 = setTimeout(() => {
      let demonTenticle = this.createDemonTenticle();
      demonTenticle.setUpTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 3800);
    this.tenticleLevelTimers.push(t3);

    const t4 = setTimeout(() => {
      let demonTenticle = this.createDemonTenticle();
      demonTenticle.setDownTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 8 * 1000);
    this.tenticleLevelTimers.push(t4);

    const t5 = setTimeout(() => {
      let demonTenticle = this.createShortDemonTenticle();
      demonTenticle.setUpTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 12000);
    this.tenticleLevelTimers.push(t5);

    const t6 = setTimeout(() => {
      let demonTenticle = this.createDemonTenticle();
      demonTenticle.setDownTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 13600);
    this.tenticleLevelTimers.push(t6);

    const t7 = setTimeout(() => {
      let demonTenticle = this.createShortDemonTenticle();
      demonTenticle.setUpTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 17500);
    this.tenticleLevelTimers.push(t7);

    const t8 = setTimeout(() => {
      let demonTenticle = this.createShortDemonTenticle();
      demonTenticle.setDownTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 19500);
    this.tenticleLevelTimers.push(t8);

    const t9 = setTimeout(() => {
      let demonTenticle = this.createDemonTenticle();
      demonTenticle.setUpTenticle();
      this.player.obstacles.push(demonTenticle);
    }, 21500);
    this.tenticleLevelTimers.push(t9);
  }

  createDemonTenticle() {
    const tenticle = this.importer.dataToObject(this.demonTenticleData);
    tenticle.screenSize = this.screenSize;
    tenticle.onImport();
    this.tenticleContainer.addChild(tenticle);

    this.updateables.push(tenticle);
    tenticle.updateables = this.updateables;

    return tenticle;
  }

  createShortDemonTenticle() {
    const tenticle = this.importer.dataToObject(this.demonShortTenticleData);
    tenticle.screenSize = this.screenSize;
    tenticle.isShort = true;
    tenticle.onImport();
    this.tenticleContainer.addChild(tenticle);

    this.updateables.push(tenticle);
    tenticle.updateables = this.updateables;

    return tenticle;
  }

  jumpToDemonLevel() {
    const y = PLAYER_DEMON_Y;
    this.buildCosmos();

    this.player.x = 51379;
    this.player.y = y;
    this.track(this.player, true, 0);

    this.afterCosmosBuild();

    this.isCutsceneEyeActivated = true;

    const frontAnimation = this.createFrontAnimation();
    this.activateUpLevel(frontAnimation);

    if (this.boat) {
      this.boat.removeBoat();
      this.boat = null;
    }

    new TweenIdle(1, () => {
      Actions.stopAll();
      // stop all animations

      setTimeout(() => {
        this.player.velocity.x = 0;
        // this.canMoveScreen = true;
        this.isRuninsCameraActive = false;
        this.isEyeCameraActive = false;
        this.isUpLevelCameraActive = true;
        this.player.isLocked = false;

        this.track(this.player, true, 0);

        this.jumpOnHorse();
        this.shadowSelf.alpha = 1;
      }, 100);
    }).run();
  }

  jumpOnHorse() {
    if (this.horseAnimation) {
      this.horseAnimation.removeFromParent();
      this.horseAnimation.destroy();
      this.horseAnimation = null;
    }

    this.player.activateHorseMode();
    this.player.downLocationY = PLAYER_DOWN_Y;
    this.player.upLocationY = PLAYER_UP_Y;

    // move player to down position
    new TweenGeneric(this.player, { y: PLAYER_DOWN_Y }, null, 900).run();

    this.isCameraTracking = false; // no more camera tracking

    if (!this.isFinalSceneActivated) {
      this.buildDemonLevel();
    }
  }

  onTakeDamage() {
    new TweenShake(this, 8, 0.2, null, 300).run();

    this.addScore(-100);
    // remove some score
  }

  onHorseMounted() {
    /// START GAMEPLAY
    // horse mounted
    this.isHorseLevelActive = true;

    this.playAmbientSound('08_AMB-DemonTentacles');
  }

  onFinalScene() {
    console.log('ON FINAL SCENE');
    const GAME_END_DEALY = 18000;
    const MOVE_TO_FINAL_POSITION_TIME = 3000;

    this.player.isLocked = true;
    this.player.velocity.x = 0;

    // stop the ambient sound here
    if (this.ambientSound) {
      new TweenGeneric(this.ambientSound, { volume: 0 }, null, 3000, () => {
        this.ambientSound.stop();
        this.ambientSound = null;
        console.log('STOP');
      }).run();
    }

    // remove menu btn
    new TweenGeneric(this.menuBtn, { alpha: 0 }, null, 1000, () => {
      this.menuBtn.removeFromParent();
    }).run();

    // remove score text
    new TweenGeneric(this.scoreCounter, { alpha: 0 }, null, 1000, () => {
      this.scoreCounter.removeFromParent();
    }).run();

    // remove score egg

    const toX = this.player.x + 30 + (this.isPortrait ? 100 : 0);
    new TweenGeneric(
      this.player,
      { y: PLAYER_UP_Y + 180, x: toX },
      new Bezier(0.23, 0.61, 0.63, 0.98),
      MOVE_TO_FINAL_POSITION_TIME,
      () => {
        // move player to scene container
        const gp = this.player.getGlobalPosition();
        this.addChild(this.player);
        this.player.x = gp.x;
        this.player.y = gp.y;

        new TweenIdle(1000, () => {
          if (this.shadowSelf) {
            this.shadowSelf.state.setAnimation(0, 'START', false);
          }
        }).run();
      }
    ).run();

    new TweenIdle(MOVE_TO_FINAL_POSITION_TIME, () => {
      sound.play('09_SFX_Magic-Egg-Hatch', { volume: 1 * VOLUME_FX });
      this.player.animation.state.setAnimation(0, '6_End', false);

      new TweenIdle(5000, () => {
        sound.play('10_WHOOSH-MAGIC-LIGHT', { volume: 0.5 * VOLUME_FX });
        this.tunder();

        // remove cosmos
        // show clouds here
      }).run();

      // show bird portrait
    }).run();

    new TweenIdle(GAME_END_DEALY, () => {
      this.onGameEnd();
    }).run();

    new TweenIdle(2000, () => {
      this.showSubTitles3();
    }).run();
  }

  showBird() {
    const data1 = PIXI.Assets.get('Final frame');
    const birdAnimation = new Spine(data1.spineData);
    birdAnimation.autoUpdate = true;
    birdAnimation.state.setAnimation(0, 'Final frame', true);
    birdAnimation.scale.set(0.5);
    birdAnimation.x = this.screenSize.width / 2 - birdAnimation.width / 2 - 190;
    birdAnimation.y =
      this.screenSize.height / 2 - birdAnimation.height / 2 - 30;
    this.addChild(birdAnimation);

    this.birdAnimation = birdAnimation;
    // slow down the bird animation
    birdAnimation.state.timeScale = 0.6;
  }

  tunder() {
    const tunder = new PIXI.Sprite(PIXI.Texture.WHITE);
    tunder.width = this.screenSize.width;
    tunder.height = this.screenSize.height;
    tunder.tint = 0xebe4ae;
    tunder.anchor.set(0.5, 0.5);
    tunder.x = this.screenSize.width / 2;
    tunder.y = this.screenSize.height / 2;
    tunder.alpha = 0;
    window.tunder = tunder;
    this.addChild(tunder);

    new TweenGeneric(tunder, { alpha: 1 }, null, 100, () => {
      this.removeCosmos();
      this.showEndingClouds();
      this.showBird();
      tunder.bringToFront();
      this.player.removeFromParent();
      this.player.isLocked = true;

      new TweenGeneric(tunder, { alpha: 0 }, null, 3000, () => {
        tunder.removeFromParent();

        this.playAmbientSound('AMB-end-song');
      }).run();
    }).run();
  }

  removeCosmos() {
    // remove existing background here

    // iterate throguh this.gameContainer.children ,
    // find where name starts with cosmos
    // remove them

    const layers = this.gameContainer.children;
    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      if (layer.name && layer.name.startsWith('cosmos-')) {
        // layer.removeFromParent();
        layer.alpha = 0;
      }
    }
  }

  showEndingClouds() {
    this.playerBackground.addChild(this.starsLayer);
    this.stars = [];

    const cloudObjects = this.findLayersByNameGroup('ending-sky-2');

    this.importer.isLazyImport = false;
    this.importer.importObjects(cloudObjects, this.gameContainer, () => {
      // find layer
      const layer = this.gameContainer.findByName('ending-sky-2');
      layer.scale.set(0.5);
      const clouds = layer.children;
      for (let i = 0; i < clouds.length; i++) {
        const cloud = clouds[i];
        cloud.blendMode = PIXI.BLEND_MODES.ADD;
      }
    });
  }

  // onEnd
  onGameEnd() {
    // show leaderboard first
    this.showLeaderboard('continue');

    const alphaFilter = new PIXI.AlphaFilter(0);
    this.leaderboard.filters = [alphaFilter];
    new TweenGeneric(alphaFilter, { alpha: 1 }, null, 4000).run();
  }

  onContinueClicked() {
    this.showSubmitForm();
  }

  showLeaderboard(mode) {
    if (this.leaderboard) {
      // remove from updateables
      const index = this.updateables.indexOf(this.leaderboard);
      if (index > -1) {
        this.updateables.splice(index, 1);
      }
      this.leaderboard.removeFromParent();
      this.leaderboard.destroy();
    }

    this.leaderboard = new LeaderboardMenu(
      this,
      this.screenSize,
      this.score,
      mode
    );
    this.updateables.push(this.leaderboard);
    this.addChild(this.leaderboard);
  }

  showEndMenu() {
    if (!this.endScreenMenu) {
      const endScreenMenu = new EndScreenMenu(
        this,
        this.screenSize,
        this.scoreText.text
      );
      this.addChild(endScreenMenu);

      this.updateables.push(endScreenMenu.bigStar);
      this.updateables.push(...endScreenMenu.dashedMenu.stars);

      this.endScreenMenu = endScreenMenu;
    }

    this.endScreenMenu.visible = true;

    // const alphaFilter = new PIXI.AlphaFilter(0);
    // endScreenMenu.filters = [alphaFilter];
    // new TweenGeneric(alphaFilter, { alpha: 1 }, null, 4000).run();
  }

  onFormClose() {
    this.showEndMenu();
  }

  onFormSubmit(userData) {
    this.sendScore(userData, (response) => {
      console.log('data submited', response);
      this.showLeaderboard('menu');
    });
  }

  sendScore(userData, callback) {
    this.api.sendScore(
      this.score,
      userData.name,
      userData.email,
      userData.newsletter,
      this.isKeyCollected,
      callback
    );
  }

  showSubmitForm() {
    const form = document.getElementById('score-form');
    const scoreLabel = document.getElementById('form-score');
    const nameInput = document.getElementById('form-name');
    const submitBtn = document.getElementById('form-submit');
    const formBackground = document.getElementById('form-background-wrapper');
    const skipBtn = document.getElementById('skipBtn');

    const userData = this.getUserData();
    if (userData) {
      nameInput.value = userData.name;
    }

    //  no-key
    if (!this.isKeyCollected) {
      form.classList.add('no-key');
    }

    if (this.isPortrait) {
      formBackground.style.backgroundImage =
        'url(assets/ui/ui_mobile_frame.png)';
    } else {
      formBackground.style.backgroundImage =
        'url(assets/ui/ui_desktop_frame.png)';
    }

    skipBtn.onclick = () => {
      form.style.display = 'none';
      this.onFormClose();
    };

    form.style.display = 'flex';

    // show the score
    scoreLabel.innerHTML = this.score;

    nameInput.onkeydown = () => {
      nameInput.setCustomValidity('');
      nameInput.reportValidity();
    };

    const badWords = PIXI.Assets.get('bad_words');

    const hasBadWords = (name) => {
      name = name.trim();
      name = name.toLowerCase();
      name = name.replace(/\_/g, ' ');
      name = name.replace(/\-/g, ' ');
      name = name.replace(/\./g, ' ');
      // step 1
      // check word by word if contained
      var words = name.split(' ');

      for (var i = 0; i < words.length; i++) {
        var name = words[i];
        if (badWords.indexOf(name) !== -1) {
          return true;
        }
      }

      return false;
    };

    submitBtn.onclick = () => {
      // validate nameInput , to have at least 3 characters
      if (nameInput.value.length < 3) {
        // push a custm validation message to the nameInput
        nameInput.setCustomValidity('Name should be at least 3 characters');
        nameInput.reportValidity();
        return;
      }

      if (hasBadWords(nameInput.value)) {
        nameInput.setCustomValidity("That is inaporpriate you can't use that");
        nameInput.reportValidity();
        return;
      }

      const userData = {
        name: nameInput.value,
      };

      // save the data to the localstore
      if (window.localStorage) {
        localStorage.setItem('user', JSON.stringify(userData));
      }

      form.style.display = 'none';

      this.onFormSubmit(userData);
    };
  }

  getUserData() {
    if (window.localStorage) {
      const userData = localStorage.getItem('user');

      if (userData) {
        try {
          return JSON.parse(userData);
        } catch (e) {
          console.log('Error parsing user data');
        }
      }
    }

    return null;
  }

  createFooterText(text) {
    const footerText = new Label(text, {
      fontFamily: 'Early GameBoy',
      fontSize: 20,
      fill: 0xeae7bc,
    });
    footerText.anchor.set(0.5, 0.5);

    this.addChild(footerText);

    return footerText;
  }

  onLinkClick(link) {
    // link for the key being collected
    // https://www.tigranhamasyan.com/merch/p/key-shirt-game-bonus-40-discount

    if (link.id === 'skip-intro') {
      this.onSkipIntroClicked(link);
    } else if (link.id === 'restart-game') {
      window.location.reload();
    } else if (link.id === 'music-album') {
      window.open('https://www.tigranhamasyan.com/music', '_blank');
    } else if (link.id === 'live-show') {
      window.open(
        'https://www.hollandfestival.nl/en/the-bird-of-a-thousand-voices',
        '_blank'
      );
    } else if (link.id === 'the-story') {
      window.open('https://www.tigranhamasyan.com/story', '_blank');
    } else if (link.id === 'tigran-page') {
      window.open('https://www.tigranhamasyan.com/home', '_blank');
    } else if (link.id === 'main-menu') {
      this.showEndMenu();
    } else if (link.id === 'continue') {
      this.onContinueClicked();
    } else if (link.id === 'leaderboard') {
      this.showLeaderboard('menu');
    }
  }
}
