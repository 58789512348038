import * as PIXI from 'pixi.js';
import DashedMenu from './DashedMenu';
import TextLink from './TextLink';
import { Viewport } from 'pixi-viewport';
import Label from '../ui/label';
import DashedBox from './DashedBox';
import API from '../utils/api';
import ShareHelper from '../utils/ShareHelper';
import BigStar from './BigStar';

export default class LeaderboardMenu extends PIXI.Container {
  constructor(delegate, screenSize, scoreText, mode) {
    super();

    this.delegate = delegate;
    this.screenSize = screenSize;
    this.mode = mode;
    this.score = scoreText;

    // add background blank screen with black tint

    const frame = new PIXI.Sprite(
      PIXI.Assets.get(
        screenSize.width < screenSize.height
          ? 'ui_mobile_frame'
          : 'ui_desktop_frame'
      )
    );
    frame.anchor.set(0.5, 0.5);
    frame.y = this.screenSize.height / 2;

    // add trasparent background the size of the frame
    const background = new PIXI.Sprite(PIXI.Texture.WHITE);
    background.width = frame.width - 20;
    background.height = frame.height - 20;
    background.tint = 0x333333;
    background.alpha = 0.8;
    background.anchor.set(0.5, 0.5);
    background.y = frame.y;

    this.addChild(background);
    this.addChild(frame);

    background.x = screenSize.width / 2;
    frame.x = screenSize.width / 2;

    // create a new big star with ui-star texture

    // // add star  ui-star
    const star = new BigStar();
    star.startScale = 0.8;
    star.factor = 600;
    star.randomSpeed = 0.0002;
    this.stars = [star];
    star.texture = PIXI.Assets.get('ui-star');
    star.anchor.set(0.5, 0.5);
    star.x = screenSize.width / 2;
    star.y = screenSize.width < screenSize.height ? 135 : 160;
    this.addChild(star);

    const shareScore = new TextLink('Your Points', this);
    shareScore.id = 'share-score';
    shareScore.underline.visible = false;
    shareScore.canHover = false;
    shareScore.position.set(screenSize.width / 2, 230);
    this.addChild(shareScore);

    // add score text
    const scoreLabel = new Label(scoreText, {
      fontFamily: 'Early GameBoy',
      fontSize: 30,
      fill: 0xeae7bc,
    });
    scoreLabel.anchor.set(0.5, 0.5);
    scoreLabel.position.set(screenSize.width / 2, 260);
    this.addChild(scoreLabel);

    this.bigStar = star;

    // add menu with links

    const width = 400;
    const height = 450;
    const padding = 10;

    const viewY = 350;

    const scrollContentWidth = width - padding * 2;
    const scrollContentHeight = 1550;
    const viewWidth = width - padding * 2;
    const viewHeight = height - padding * 2;

    const dimensions = {
      scrollContentWidth,
      scrollContentHeight,
      viewWidth,
      viewHeight,
      viewY,
      padding,
    };

    ////////// Dashed menu

    const dashedBox = new DashedBox(width, height);
    dashedBox.position.set(screenSize.width / 2, viewY);
    this.stars = this.stars.concat(dashedBox.stars);
    this.addChild(dashedBox);

    //////////// Leaderboard section

    const scoreboardTitleLabel = new Label('Scoreboard:', {
      fontFamily: 'Pixels',
      fontSize: 40,
      fill: 0xeae7bc, // yellow
      wordWrap: true,
      wordWrapWidth: scrollContentWidth,
    });
    scoreboardTitleLabel.anchor.set(0.5, 0.5);
    scoreboardTitleLabel.position.set(screenSize.width / 2, 320);
    this.addChild(scoreboardTitleLabel);

    // add a loading label
    const loadingLabel = new Label('Loading...', {
      fontFamily: 'Pixels',
      fontSize: 40,
      fill: 0xeae7bc, // yellow
      wordWrap: true,
      wordWrapWidth: scrollContentWidth,
    });
    loadingLabel.anchor.set(0.5, 0.5);
    loadingLabel.position.set(screenSize.width / 2, viewY + height / 2);
    this.addChild(loadingLabel);

    this.loadingLabel = loadingLabel;

    this.loadLeaderboard(dimensions);

    /////////////////////////////// bottom links

    // add a textlink below the leaderboard

    this.setMode(mode);
  }

  createActionBtn(text, id) {
    const menuLink = new TextLink(text, this);
    menuLink.id = id;
    menuLink.underline.visible = true;
    menuLink.canHover = false;
    menuLink.position.set(
      this.screenSize.width / 2,
      this.screenSize.height - 180
    );
    this.addChild(menuLink);

    return menuLink;
  }

  setMode(mode) {
    this.mode = mode;

    if (this.actionBtn) {
      this.actionBtn.removeFromParent();
      this.actionBtn.destroy();
    }

    if (this.mode === 'continue') {
      this.actionBtn = this.createActionBtn('Continue', 'continue');
    } else {
      this.actionBtn = this.createActionBtn('Menu', 'main-menu');
    }
  }

  loadLeaderboard(dimensions) {
    this.api = new API();
    this.api.getLeaderboard((response) => {
      this.buildLeaderboard(dimensions, response);
      this.loadingLabel.visible = false;
    });
  }

  buildLeaderboard(dimensions, data) {
    // deconstruct the dimensions object
    const { scrollContentWidth, viewWidth, viewHeight, viewY, padding } =
      dimensions;

    const scrollContent = new PIXI.Container();
    // create a label out of each line
    const labelsStyle = {
      fontFamily: 'Pixels',
      fontSize: 40,
      fill: 0xeae7bc, // yellow
      wordWrap: true,
      wordWrapWidth: scrollContentWidth,
    };
    data.forEach((rowData, index) => {
      const rankLine = `${index + 1}.`;
      const rankLabel = new Label(rankLine, labelsStyle);
      rankLabel.anchor.set(0, 0);
      rankLabel.position.set(0, 10 + 30 * index);

      const line = `${rowData.name}`;
      const label = new Label(line, labelsStyle);
      label.anchor.set(0, 0);
      label.position.set(rankLabel.width + padding, 10 + 30 * index);

      // set score on the right side
      const line2 = `${rowData.score}`;
      const label2 = new Label(line2, labelsStyle);
      label2.anchor.set(1, 0);
      label2.position.set(scrollContentWidth, 10 + 30 * index);

      scrollContent.addChild(rankLabel);
      scrollContent.addChild(label);
      scrollContent.addChild(label2);
    });

    const scrollContentHeight = scrollContent.getBounds().height + padding * 2;

    // create a red box
    const box = new PIXI.Graphics();
    box.beginFill(0xeae7bc, 0.05);
    box.drawRect(0, 0, scrollContentWidth, scrollContentHeight);
    box.endFill();

    const scrollView = new PIXI.Container();
    scrollView.position.set(
      this.screenSize.width / 2 - scrollContentWidth / 2,
      viewY + padding
    );
    this.addChild(scrollView);

    // mask the scrollView
    const mask = new PIXI.Graphics();
    mask.beginFill(0x000000);
    mask.drawRect(0, 0, viewWidth, viewHeight);
    mask.endFill();

    scrollView.addChild(mask);
    scrollView.mask = mask;

    const viewport = new Viewport({
      screenWidth: viewWidth, // screen width used by viewport (eg, size of canvas)
      screenHeight: viewHeight,
      worldWidth: scrollContentWidth,
      worldHeight: scrollContentHeight,
      forceHitArea: new PIXI.Rectangle(
        0,
        0,
        scrollContentWidth,
        scrollContentHeight
      ),
      passiveWheel: false,
      events: app.game.app.renderer,
    });

    viewport
      .drag({
        direction: 'y',
      })
      .decelerate();

    viewport.bounce({
      sides: 'vertical', // all, horizontal, vertical, or combination of top, bottom, right, left(e.g., 'top-bottom-right')
      friction: 0.5, // friction to apply to decelerate if active
      time: 150, // time in ms to finish bounce
      bounceBox: {
        x: 0,
        y: 0,
        width: scrollContentWidth,
        height: scrollContentHeight,
      }, // use this bounceBox instead of { x: 0, y: 0, width: viewport.worldWidth, height: viewport.worldHeight }
      ease: 'easeInOutSine', // ease function or name (see http://easings.net/ for supported names)
      underflow: 'center', // (top/bottom/center and left/right/center, or center) where to place world if too small for screen
    });

    scrollView.addChild(viewport);

    viewport.addChild(scrollContent);
  }

  onUpdate(delta, ticker) {
    this.stars.forEach((star) => {
      star.onUpdate(delta, ticker);
    });

    this.bigStar.rotation += delta * 0.0005;
  }

  onLinkClick(link) {
    if (link.id === 'share-score') {
      // ShareHelper.share(this.score);
      return;
    }

    this.delegate.onLinkClick(link);
    this.visible = false;
  }
}
