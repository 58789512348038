import * as PIXI from 'pixi.js';
import TweenGeneric from '../tweening/tween_generic';
import { Util } from '../util';

export default class Star extends PIXI.Sprite {
  constructor(duration, delegate, starsArray) {
    super();
    this.delegate = delegate;
    this.starsArray = starsArray;
    const rand = Util.Math.randomInt(1, 3);
    this.texture = PIXI.Assets.get('star' + rand);
    this.anchor.set(0.5);
    this.starsArray.push(this);
    this.lifeSpan = duration;

    this.isAnimating = false;

    this.alpha = 0;
    new TweenGeneric(this, { alpha: 1 }, null, 2000, () => {
      this.isAnimating = true;
    }).run();

    setTimeout(() => {
      this.isAnimating = false;
      const duration = 800;

      new TweenGeneric(this, { alpha: 0 }, null, duration, () => {
        this.removeFromParent();
        this.starsArray.splice(this.starsArray.indexOf(this), 1);
      }).run();

      // also scale to 0
      new TweenGeneric(this.scale, { x: 0, y: 0 }, null, duration).run();
    }, this.lifeSpan);
  }

  onUpdate(delta, ticker) {
    this.rotation += 0.001 * delta;

    if (!this.isAnimating) return;

    const factor = 200;
    const scaleAplitude = 0.4;

    // using sine wave scale up the star , then scale down , and then fade out
    this.scale.set(1 + Math.sin(ticker.lastTime / factor) * scaleAplitude);
    this.alpha = 1 - Math.sin(ticker.lastTime / factor) * 0.5;
  }
}
