import * as PIXI from 'pixi.js';
import './utils/extraPIXI.js';
import { Assets } from 'pixi.js';
import LandingScene from './scenes/LandingScene.js';
import Bundles from './Bundles.js';
import GameScene from './scenes/GameScene.js';
import { sound } from '@pixi/sound';
import Visibility from 'visibilityjs';
import TweenGeneric from './tweening/tween_generic.js';
import * as SpineRuntime from 'pixi-spine';

export default class Game {
  constructor(app) {
    this.isPaused = false;
    this.windowSize = this.getWindowSize();
    this.targetSize = { width: 1920, height: 1080 };
    this.screenSize = { width: 0, height: 0 };

    this.calculateGameSize();

    Visibility.change(function (e, state) {
      if (Visibility.hidden()) {
        sound.pauseAll();
      } else {
        sound.resumeAll();
      }
    });

    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    //

    this.app = app;
    this.stage = app.stage;
    this.isLandingPageLoaded = false;
    this.isIntroLoaded = false;
    this.isSwampLoaded = false;
    this.isTreesLoaded = false;
    this.isRuinsLoaded = false;
    this.isLakeLoaded = false;
    this.isEyeCutSceneLoaded = false;
    this.isCosmosLoaded = false;
    this.isFinalSceneLoaded = false;

    this.waitingAssetsEvents = [];

    // add a background color before loading the game assets

    // this just defines the bundles
    const bundles = new Bundles();

    this.preloadLandingPage().then(() => {
      this.isLandingPageLoaded = true;

      this.init();

      this.onAssetsLoaded('landing');

      this.preloadIntro().then(() => {
        this.isIntroLoaded = true;
        this.onAssetsLoaded('intro');

        this.preloadSwamp().then(() => {
          this.isSwampLoaded = true;
          this.onAssetsLoaded('swamp');

          this.preloadTrees().then(() => {
            this.isTreesLoaded = true;
            this.onAssetsLoaded('trees');

            this.preloadRuins().then(() => {
              this.isRuinsLoaded = true;
              this.onAssetsLoaded('ruins');

              this.preloadLake().then(() => {
                this.isLakeLoaded = true;
                this.onAssetsLoaded('lake');

                this.preloadEyeCutScene().then(() => {
                  this.isEyeCutSceneLoaded = true;
                  this.onAssetsLoaded('eye-cut-scene');

                  this.preloadCosmos().then(() => {
                    this.isCosmosLoaded = true;
                    this.onAssetsLoaded('cosmos');

                    this.preloadFinalScene().then(() => {
                      this.isFinalSceneLoaded = true;
                      this.onAssetsLoaded('final-scene');
                    });
                  });
                });
              });
            });
          });
        });
      });
    });

    this.resizeObserver = new ResizeObserver(this.onResize.bind(this));
    this.resizeObserver.observe(window.document.body);
  }

  async preloadFinalScene() {
    return await Assets.loadBundle('final-scene');
  }

  async preloadCosmos() {
    return await Assets.loadBundle('cosmos');
  }

  async preloadEyeCutScene() {
    return await Assets.loadBundle(['eye-cut-scene']);
  }

  async preloadLake() {
    return await Assets.loadBundle('lake');
  }

  async preloadRuins() {
    return await Assets.loadBundle('ruins@0.5x');
  }

  async preloadTrees() {
    return await Assets.loadBundle('trees');
  }

  async preloadSwamp() {
    return await Assets.loadBundle([
      'swamp',
      'collectables',
      'sky',
      'clouds',
      'sound_fx',
    ]);
  }

  async preloadIntro() {
    return await Assets.loadBundle(['intro-page', 'initial-generic']);
  }

  async preloadLandingPage() {
    return await Assets.loadBundle('load-screen');
  }

  init() {
    const scene = new LandingScene(this);
    this.setScene(scene);

    // set alpha filter to fade in
    const alphaFilter = new PIXI.AlphaFilter();
    alphaFilter.alpha = 0;
    scene.filters = [alphaFilter];

    new TweenGeneric(alphaFilter, { alpha: 1 }, null, 600, () => {
      scene.filters = [];
    }).run();

    // remove loading element
    const loadingElement = document.getElementById('loading');
    loadingElement.style.display = 'none';

    // Prevent iOS from displaying a blue selection overlay when pressing on the canvas

    function absorbEvent(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const canvas = this.app.view;

    canvas.style.webkitTapHighlightColor = 'rgba(0, 0, 0, 0)';
    canvas.style.userSelect = 'noselect';
    canvas.style.webkitUserSelect = 'none';

    canvas.addEventListener('touchstart', absorbEvent);
    canvas.addEventListener('touchend', absorbEvent);
    canvas.addEventListener('touchmove', absorbEvent);
    canvas.addEventListener('touchcancel', () => {
      event.stopPropagation();
      if (event.cancelable) {
        event.preventDefault();
      }
    });
  }

  getWindowSize() {
    var e = window,
      a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
  }

  update(delta, ticker) {
    if (!this.isLandingPageLoaded) {
      return;
    }

    // clamp delta to 33ms
    delta = Math.min(delta, 33);

    if (this.currentScene) {
      this.currentScene.update(delta, ticker);
    }
  }

  updatePaused(delta, ticker) {
    if (!this.isLandingPageLoaded) {
      return;
    }

    if (this.currentScene && this.currentScene.updatePaused) {
      this.currentScene.updatePaused(delta, ticker);
    }
  }

  onResize() {
    this.calculateGameSize();

    // SET THE RESOLUTION
    this.app.view.width = this.screenSize.width;
    this.app.view.height = this.screenSize.height;

    this.app.renderer.resize(this.screenSize.width, this.screenSize.height);

    if (this.currentScene) {
      this.currentScene.onResize(this.screenSize);
    }
  }

  calculateGameSize() {
    this.windowSize = this.getWindowSize();

    var width = this.targetSize.width;
    var height = this.targetSize.height;
    var size = this.windowSize;

    this.windowWidth = size.width;
    this.windowHeight = size.height;

    this.canvasWidth = Math.ceil(size.width);
    this.canvasHeight = Math.ceil(size.height);

    var ratio = size.width / size.height;

    // FLEXIBLE WIDTH
    this.screenSize.height = height;
    this.screenSize.width = height * ratio;
  }

  onHomeScreen() {
    const scene = new GameScene(this);
    this.setScene(scene);
  }

  setScene(scene) {
    const pastScene = this.currentScene;
    if (pastScene) {
      pastScene.onHide();
    }

    this.currentScene = scene;
    this.stage.addChild(this.currentScene);
    this.currentScene.onShow();

    if (pastScene) {
      pastScene.removeFromParent();
    }
  }

  onAssetsLoaded(eventName) {
    console.log('Assets loaded:', eventName);
    for (let i = 0; i < this.waitingAssetsEvents.length; i++) {
      const waitingEvent = this.waitingAssetsEvents[i];
      if (waitingEvent.name === eventName) {
        this.waitingAssetsEvents.splice(i, 1);
        i--;
        waitingEvent.callback();
      }
    }
  }

  addWaitingAssetsEvent(name, callback) {
    this.waitingAssetsEvents.push({ name, callback });
  }

  pause() {
    this.isPaused = true;
    SpineRuntime.settings.GLOBAL_AUTO_UPDATE = false;
    sound.pauseAll();
  }

  resume() {
    this.isPaused = false;
    SpineRuntime.settings.GLOBAL_AUTO_UPDATE = true;
    sound.resumeAll();
  }
}
