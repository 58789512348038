import * as PIXI from 'pixi.js';
import TweenGeneric from '../tweening/tween_generic';
import { Util } from '../util';

export default class DustAnimation extends PIXI.Container {
  constructor() {
    super();
  }

  setAnimation1() {
    const textures = [];
    for (let i = 1; i <= 4; i++) {
      textures.push(PIXI.Assets.get('jump_dust_2_' + i + '.png'));
    }

    this.animation = new PIXI.AnimatedSprite(textures);
    this.animation.animationSpeed = 0.25;
    this.animation.scale.set(1);
    this.animation.play();
    this.animation.anchor.set(0.5, 1);
    this.animation.autoUpdate = true;
    this.animation.onLoop = () => {
      this.removeFromParent();
    };

    this.addChild(this.animation);
  }

  setAnimation2() {
    const textures = [];
    for (let i = 1; i <= 10; i++) {
      textures.push(PIXI.Assets.get('jump_smoke_' + i + '.png'));
    }
    // console.log({ textures }, PIXI.Assets.get('jump_smoke'));

    this.animation = new PIXI.AnimatedSprite(textures);
    this.animation.animationSpeed = 0.4;
    this.animation.scale.set(1);
    this.animation.play();
    this.animation.anchor.set(0.5, 1);
    this.animation.autoUpdate = true;
    this.animation.onLoop = () => {
      this.removeFromParent();
    };

    this.addChild(this.animation);
  }
}
