import * as PIXI from 'pixi.js';

export default class Bundles {
  constructor() {
    PIXI.Assets.addBundle('load-screen', [
      {
        name: 'landing-page_screen',
        srcs: 'assets/level-jsons/landing-page_screen.json',
      },
      { name: 'Pixels', srcs: 'assets/fonts/Pixels.ttf' },
      { name: 'Early GameBoy', srcs: 'assets/fonts/Early GameBoy.ttf' },

      { name: 'clouds', srcs: 'assets/landing-page/clouds@2x.png' },
      {
        name: 'landingPageAtlas',
        srcs: 'assets/atlases-landing/landingPageAtlas.json',
      },
      { name: 'rounded-10', srcs: 'assets/blank/rounded-10.png' },
      { name: 'menu-txt', srcs: 'assets/landing-page/menu-txt@2x.png' },
      { name: 'sky-line1', srcs: 'assets/landing-page/sky-line1@2x.png' },
      { name: 'sky-line2', srcs: 'assets/landing-page/sky-line2@2x.png' },
      { name: 'sky-line3', srcs: 'assets/landing-page/sky-line3@2x.png' },
      { name: 'bg-nightsky', srcs: 'assets/landing-page/bg-nightsky@2x.jpg' },
      { name: 'Areg_landing', srcs: 'assets/spine/Areg_landing.json' },
      { name: 'menu-light', srcs: 'assets/ui/menu-light.png' },
      { name: 'menu-gray', srcs: 'assets/ui/menu-gray.png' },
      { name: 'ui-end-star-frame', srcs: 'assets/ui/ui-end-star-frame.png' },
      {
        name: 'landing-footer',
        srcs: 'assets/landing-page/landing-footer.png',
      },
      { name: 'ui-star', srcs: 'assets/ui/ui-star.png' },
    ]);

    PIXI.Assets.addBundle('intro-page', [
      { name: 'intro_gate', srcs: 'assets/intro-page/intro_gate.png' },
      {
        name: 'gate-hidding-top',
        srcs: 'assets/intro-page/gate-hidding-top.png',
      },
      {
        name: '01_areg_portrait',
        srcs: 'assets/spine/01_areg_portrait.json',
      },
      { name: 'bgMusic', srcs: 'assets/sounds/bgMusic.mp3' },
      {
        name: 'portrait-merged',
        srcs: 'assets/spine/portrait merged.json',
      },
      { name: '01_king', srcs: 'assets/spine/01_king.json' },
      { name: 'intro_1', srcs: 'assets/intro-page/intro_1@2x.png' },
      { name: 'intro_2', srcs: 'assets/intro-page/intro_2.png' },
      { name: 'intro_3', srcs: 'assets/intro-page/intro_3@2x.png' },

      {
        name: 'intro_gate_empty',
        srcs: 'assets/intro-page/intro_gate_empty.png',
      },

      {
        name: '01_grandmother-b',
        srcs: 'assets/spine/01_grandmother-b.json',
      },
      {
        name: 'intro_gate_mask',
        srcs: 'assets/intro-page/intro_gate_mask.png',
      },
      { name: 'ui-game-logo', srcs: 'assets/ui/ui-game-logo.png' },
      { name: 'Take_FX', srcs: 'assets/spine/Take_FX.json' },
      { name: 'gameObjects', srcs: 'assets/level-jsons/objects.json' },
    ]);

    PIXI.Assets.addBundle('swamp', [
      { name: 'foreground', srcs: 'assets/swamp/foreground.png' },
      { name: 'reflection', srcs: 'assets/swamp/reflection.png' },
      { name: 'rocks-stand1', srcs: 'assets/swamp/rocks-stand1.png' },
      { name: 'rocks-stand2', srcs: 'assets/swamp/rocks-stand2.png' },
      { name: 'rocks1', srcs: 'assets/swamp/rocks1.png' },
      { name: 'rocks2', srcs: 'assets/swamp/rocks2.png' },
      { name: 'straws-main', srcs: 'assets/swamp/straws-main.png' },
      { name: 'straws', srcs: 'assets/swamp/straws.png' },
      { name: 'jump_smoke', src: 'assets/fx/jump_smoke.json' },
      { name: 'jump_dust_2', src: 'assets/fx/jump_dust_2.json' },
      { name: 'ui-egg-points', src: 'assets/ui/ui-egg-points.png' },
    ]);

    PIXI.Assets.addBundle('sound_fx', [
      {
        name: '01_SFX_Areg-Fall',
        srcs: 'assets/sounds/fx/01_SFX_Areg-Fall.mp3',
      },
      { name: '02_AMB_Forest', srcs: 'assets/sounds/fx/02_AMB_Forest.mp3' },
      { name: '03_AMB_Ruins-2', srcs: 'assets/sounds/fx/03_AMB_Ruins-2.mp3' },
      { name: '03_AMB_Ruins', srcs: 'assets/sounds/fx/03_AMB_Ruins.mp3' },
      { name: '04_AMB_River', srcs: 'assets/sounds/fx/04_AMB_River.mp3' },
      {
        name: '04_SFX_Boat-Floating',
        srcs: 'assets/sounds/fx/04_SFX_Boat-Floating.mp3',
      },
      {
        name: '05_AMB_Underwater',
        srcs: 'assets/sounds/fx/05_AMB_Underwater.mp3',
      },
      {
        name: '05_SFX_Areg-Catapults',
        srcs: 'assets/sounds/fx/05_SFX_Areg-Catapults.mp3',
      },
      {
        name: '05_SFX_Areg-Sword-Grab',
        srcs: 'assets/sounds/fx/05_SFX_Areg-Sword-Grab.mp3',
      },
      { name: '05_SFX_Boat-Hit', srcs: 'assets/sounds/fx/05_SFX_Boat-Hit.mp3' },
      {
        name: '05_SFX_Boat-on-Fire',
        srcs: 'assets/sounds/fx/05_SFX_Boat-on-Fire.mp3',
      },
      { name: '05_SFX_Lightray', srcs: 'assets/sounds/fx/05_SFX_Lightray.mp3' },
      { name: '06_AMB-Storm', srcs: 'assets/sounds/fx/06_AMB-Storm.mp3' },
      {
        name: '06_SFX_Areg-Dodge-1',
        srcs: 'assets/sounds/fx/06_SFX_Areg-Dodge-1.{ogg,mp3}',
      },
      {
        name: '06_SFX_Areg-Dodge-2',
        srcs: 'assets/sounds/fx/06_SFX_Areg-Dodge-2.{ogg,mp3}',
      },
      {
        name: '06_SFX_Areg-to-Air',
        srcs: 'assets/sounds/fx/06_SFX_Areg-to-Air.mp3',
      },
      {
        name: '07_SFX_Horse-Appears',
        srcs: 'assets/sounds/fx/07_SFX_Horse-Appears.mp3',
      },
      {
        name: 'SFX_Horse-Flaming',
        srcs: 'assets/sounds/fx/SFX_Horse-Flaming.mp3',
      },
      {
        name: '08_AMB-DemonTentacles',
        srcs: 'assets/sounds/fx/08_AMB-DemonTentacles.mp3',
      },
      {
        name: '08_SFX_Areg-Slash',
        srcs: 'assets/sounds/fx/08_SFX_Areg-Slash.mp3',
      },
      {
        name: '09_SFX_Demon-Roar',
        srcs: 'assets/sounds/fx/09_SFX_Demon-Roar.mp3',
      },
      {
        name: '09_SFX_Magic-Egg-Hatch',
        srcs: 'assets/sounds/fx/09_SFX_Magic-Egg-Hatch.mp3',
      },
      {
        name: '10_WHOOSH-MAGIC-LIGHT',
        srcs: 'assets/sounds/fx/10_WHOOSH-MAGIC-LIGHT.mp3',
      },
      { name: 'SFX_Areg-Jump-1', srcs: 'assets/sounds/fx/SFX_Areg-Jump-1.mp3' },
      { name: 'SFX_Areg-Jump-2', srcs: 'assets/sounds/fx/SFX_Areg-Jump-2.mp3' },
      { name: 'SFX_Areg-Jump-3', srcs: 'assets/sounds/fx/SFX_Areg-Jump-3.mp3' },
      { name: 'SFX_Areg-Jump-4', srcs: 'assets/sounds/fx/SFX_Areg-Jump-4.mp3' },
      {
        name: 'SFX_Footsteps_Left-1',
        srcs: 'assets/sounds/fx/SFX_Footsteps_Left-1.mp3',
      },
      {
        name: 'SFX_Footsteps_Left-2',
        srcs: 'assets/sounds/fx/SFX_Footsteps_Left-2.mp3',
      },

      {
        name: 'SFX_Footsteps_Right-1',
        srcs: 'assets/sounds/fx/SFX_Footsteps_Right-1.mp3',
      },
      {
        name: 'SFX_Footsteps_Right-2',
        srcs: 'assets/sounds/fx/SFX_Footsteps_Right-2.mp3',
      },
      {
        name: 'SFX_Glowing-Tree',
        srcs: 'assets/sounds/fx/SFX_Glowing-Tree.mp3',
      },
      { name: 'SFX_Magic-Egg', srcs: 'assets/sounds/fx/SFX_Magic-Egg.mp3' },
      {
        name: 'SFX_Magic-Whoosh',
        srcs: 'assets/sounds/fx/SFX_Magic-Whoosh.mp3',
      },
      {
        name: 'SFX_Starlight-Collect-1',
        srcs: 'assets/sounds/fx/SFX_Starlight-Collect-1.mp3',
      },
      {
        name: 'SFX_Starlight-Collect-2',
        srcs: 'assets/sounds/fx/SFX_Starlight-Collect-2.mp3',
      },
      {
        name: 'SFX_Starlight-Collect-3',
        srcs: 'assets/sounds/fx/SFX_Starlight-Collect-3.mp3',
      },
      {
        name: 'SFX_Starlight-Collect-4',
        srcs: 'assets/sounds/fx/SFX_Starlight-Collect-4.mp3',
      },
      {
        name: 'SFX_Starlight-Collect-5',
        srcs: 'assets/sounds/fx/SFX_Starlight-Collect-5.mp3',
      },
      {
        name: 'SFX_Head-Apart',
        srcs: 'assets/sounds/fx/SFX_Head-Apart.mp3',
      },
    ]);

    PIXI.Assets.addBundle('initial-generic', [
      { name: 'swamp-level', srcs: 'assets/level-jsons/swamp.json' },
      { name: 'Human_Profile', srcs: 'assets/areg/Human_Profile.json' },
      { name: 'floor_tile', srcs: 'assets/generic-assets/floor_tile.jpg' },
    ]);

    PIXI.Assets.addBundle('collectables', [
      {
        name: 'atlases-generic',
        srcs: 'assets/atlases-generic/generic-assets.json',
      },
    ]);

    PIXI.Assets.addBundle('trees', [
      { name: 'trees_atlas0', srcs: 'assets/atlases-trees/trees-0.json' },
      { name: 'trees_atlas1', srcs: 'assets/atlases-trees/trees-1.json' },
      { name: 'forest_glow_back', srcs: 'assets/trees/forest_glow_back.jpg' },
      { name: 'forest_smoke1', srcs: 'assets/trees/forest_smoke1.png' },
      { name: 'forest_smoke2', srcs: 'assets/trees/forest_smoke2.png' },
    ]);

    PIXI.Assets.addBundle('sky', [
      {
        name: 'BGcloud-blend-mirror_4',
        srcs: 'assets/sky/BGcloud-blend-mirror_4.jpg',
      },
      { name: 'BGcloud-blend_1', srcs: 'assets/sky/BGcloud-blend_1.jpg' },
      { name: 'BGcloud-blend_2', srcs: 'assets/sky/BGcloud-blend_2.jpg' },
      { name: 'BGcloud-blend_3', srcs: 'assets/sky/BGcloud-blend_3.jpg' },
      { name: 'BGskyline-blend-1', srcs: 'assets/sky/BGskyline-blend-1.jpg' },
      { name: 'BGskyline-blend-2', srcs: 'assets/sky/BGskyline-blend-2.jpg' },
      { name: 'BGskyline-blend-3', srcs: 'assets/sky/BGskyline-blend-3.jpg' },
      { name: 'cosmos_line1-blend', srcs: 'assets/sky/cosmos_line1-blend.jpg' },
      { name: 'cosmos_line2-blend', srcs: 'assets/sky/cosmos_line2-blend.jpg' },
    ]);

    PIXI.Assets.addBundle('clouds', [
      { name: 'sky_1', srcs: 'assets/clouds/sky_1.jpg' },
      { name: 'sky_2', srcs: 'assets/clouds/sky_2.jpg' },
      { name: 'sky_3', srcs: 'assets/clouds/sky_3.jpg' },
      { name: 'sky_4', srcs: 'assets/clouds/sky_4.jpg' },
      { name: 'sky_5', srcs: 'assets/clouds/sky_5.jpg' },
      { name: 'sky_6', srcs: 'assets/clouds/sky_6.jpg' },
    ]);

    // load @0.5 version of the ruins using the same urls just add @0.5x at the end of the url before the extension
    PIXI.Assets.addBundle('ruins@0.5x', [
      {
        name: 'Fire Bucket',
        srcs: 'assets/spine/Fire Bucket.json',
      },
      {
        name: 'ruins_atlas',
        srcs: 'assets/atlases-ruins/ruins-atlas@0.5x.json',
      },
    ]);

    PIXI.Assets.addBundle('lake', [
      { name: 'tree-magic', srcs: 'assets/spine/tree-magic.json' },
      { name: 'underwater_glow', srcs: 'assets/lake/underwater_glow.jpg' },
      {
        name: 'lake-atlas',
        srcs: 'assets/atlases-lake/lake-atlas@0.5x.json',
      },
      {
        name: 'river-monster-tenticles-1',
        srcs: 'assets/lake/river-monster-tenticles-1.png',
      },
      {
        name: 'river-monster-tenticles-2',
        srcs: 'assets/lake/river-monster-tenticles-2.png',
      },
      {
        name: 'transparent-fadeout',
        srcs: 'assets/lake/transparent-fadeout.png',
      },
      { name: 'underwater-lines', srcs: 'assets/level-jsons/underwater.json' },
    ]);

    PIXI.Assets.addBundle('eye-cut-scene', [
      { name: 'B_Eye', srcs: 'assets/spine/B_Eye.json' },
      { name: 'Laser_Eye', srcs: 'assets/spine/Laser_Eye.json' },
      { name: 'under-water', srcs: 'assets/spine/under-water.json' },
      { name: 'Human_Front', srcs: 'assets/areg/Human_Front.json' },
    ]);

    PIXI.Assets.addBundle('cosmos', [
      { name: 'bad_words', srcs: 'assets/bad_words.json' },
      { name: 'Horse', srcs: 'assets/areg/Horse.json' },
      { name: 'Human_Horse', srcs: 'assets/areg/Human_Horse.json' },
      { name: 'Shadow-self', srcs: 'assets/spine/Shadow-self.json' },
      { name: 'M_eye', srcs: 'assets/spine/M_eye.json' },

      { name: 'planet_1_1', srcs: 'assets/cosmos/planet_1_1.png' },
      { name: 'planet_2_1', srcs: 'assets/cosmos/planet_2_1.png' },
      { name: 'planet_3_1', srcs: 'assets/cosmos/planet_3_1.png' },
      { name: 'planet_4_1', srcs: 'assets/cosmos/planet_4_1.png' },
      { name: 'planet_5_1', srcs: 'assets/cosmos/planet_5_1.png' },
      { name: 'planet_6_1', srcs: 'assets/cosmos/planet_6_1.png' },
      { name: 'planet_7_1', srcs: 'assets/cosmos/planet_7_1.png' },
      { name: 'tentacle1', srcs: 'assets/cosmos/tentacle1.png' },
      { name: 'tentacle2', srcs: 'assets/cosmos/tentacle2.png' },
      { name: 'tentacle4', srcs: 'assets/cosmos/tentacle4.png' },
      { name: 'tentacle7', srcs: 'assets/cosmos/tentacle7.png' },
      { name: 'tentacle9', srcs: 'assets/cosmos/tentacle9.png' },
      { name: 'tentacle6', srcs: 'assets/cosmos/tentacle6.png' },
      { name: 'tentacle10', srcs: 'assets/cosmos/tentacle10.png' },
      { name: 'tentacle6b', srcs: 'assets/cosmos/tentacle6b.png' },
      {
        name: 'circle-tentacles1',
        srcs: 'assets/cosmos/circle-tentacles1@0.5x.png',
      },
      {
        name: 'tentacles-long-tiles_lowres',
        srcs: 'assets/cosmos/tentacles-long-tiles_lowres.png',
      },
      {
        name: 'cosmos-tenticles-bg-1',
        srcs: 'assets/cosmos/cosmos-tenticles-bg-1.jpg',
      },
      {
        name: 'cosmos-tenticles-bg-2',
        srcs: 'assets/cosmos/cosmos-tenticles-bg-2.jpg',
      },
      {
        name: 'cosmos-final-transition',
        srcs: 'assets/cosmos/cosmos-final-transition.jpg',
      },
      {
        name: 'displacement_map_repeat',
        srcs: 'assets/displacement_map_repeat.jpg',
      },
      {
        name: 'gradient-fade-out',
        srcs: 'assets/gradient-fade-out@0.5x.png',
      },
      {
        name: 'tentacle-fix_1',
        srcs: 'assets/cosmos/tentacle-fix_1.png',
      },
    ]);

    PIXI.Assets.addBundle('final-scene', [
      { name: 'Final frame', srcs: 'assets/spine/Final frame.json' },
      { name: 'ui_desktop_frame', srcs: 'assets/ui/ui_desktop_frame.png' },
      { name: 'ui_mobile_frame', srcs: 'assets/ui/ui_mobile_frame.png' },
      {
        name: 'AMB-end-song',
        srcs: 'assets/sounds/fx/AMB-end-song.mp3',
      },
    ]);
  }
}
