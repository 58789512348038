import * as PIXI from 'pixi.js';

export default class WaterWaves extends PIXI.ParticleContainer {
  constructor(texture, width, height) {
    super();
    this.tilePosition = new PIXI.Point(0, 0);

    let widthToFill = width;

    this.tileWidth = 0;

    this.tiles = [];
    // instantiate the srpites

    let i = 0;
    while (widthToFill > 0) {
      const sprite = new PIXI.Sprite(texture);
      sprite.cullable = true;
      const w = sprite.width;
      sprite.x = this.tilePosition.x + i * w;
      sprite.y = this.tilePosition.y;
      this.addChild(sprite);
      widthToFill -= w;

      this.tileWidth = w;
      i++;
    }
  }

  onUpdate(delta, ticker) {
    this.updateTilesPosition();
  }

  updateTilesPosition() {
    // calculate the position of the first tile based on tilePosition and tileWidth , to find the shift
    const shiftX = this.tilePosition.x % this.tileWidth;

    // update all tiles now
    for (let i = 0; i < this.children.length; i++) {
      const sprite = this.children[i];
      sprite.x = shiftX + i * this.tileWidth;
    }
  }
}
