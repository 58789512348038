import * as PIXI from 'pixi.js';
import LineHelper from '../utils/LineHelper';
import BigStar from './BigStar';
import { Vector } from '../utils/helpers';

export default class DashedBox extends PIXI.Container {
  constructor(width, height) {
    super();

    this.stars = [];

    const graphics = new PIXI.Graphics();
    // set yellow color
    graphics.lineStyle(2, 0xeae7bc, 1);

    const points = [
      new Vector(-width / 2, 0),
      new Vector(width / 2, 0),
      new Vector(width / 2, height),
      new Vector(-width / 2, height),
    ];

    // draw the dashed rectangle
    for (let i = 0; i < points.length; i++) {
      const pointA = points[i];
      const pointB = points[(i + 1) % points.length];
      LineHelper.drawDashedLine(graphics, pointA, pointB, 5, 3);
    }

    this.addChild(graphics);

    // add rotating stars to the edges of the menu

    for (let i = 0; i < points.length; i++) {
      const star = new BigStar();
      star.texture = PIXI.Assets.get('star1');
      star.scaleAmplitude = 0;
      star.scale.set(2);
      star.startScale = 2;
      star.randomSpeed = 0.001;
      star.anchor.set(0.5);
      star.position.x = points[i].x;
      star.position.y = points[i].y;
      graphics.addChild(star);
      this.stars.push(star);
    }
  }
}
