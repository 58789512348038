import Tween from './tween';

export default class TweenIdle extends Tween {
  constructor(duration, callback) {
    super(null, null, null, duration, callback);
    this.applyValues();
  }

  applyValues() {}

  step(dt) {
    this.timePassed += dt;

    let s = this.timePassed / this.duration;

    s = s >= 1 ? 1.0 : s;

    if (s === 1) {
      this.invokeCallback();
      this.stop();
    }
  }
}
