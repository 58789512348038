import * as PIXI from 'pixi.js';
import Label from '../ui/label';

export default class TextLink extends PIXI.Container {
  constructor(text, delegate, style = {}) {
    super();
    this.delegate = delegate;
    this.isUnderline = true;
    this.canHover = true;
    this.eventMode = 'static';
    this.cursor = 'pointer';

    const color = 0xeae7bc;

    this.text = new Label(text, style);
    this.text.anchor.set(0.5, 1);
    this.addChild(this.text);

    // add underline
    this.underline = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.underline.tint = color;
    this.underline.anchor.set(0.5);
    this.underline.height = 3;
    this.underline.width = this.text.width;
    this.underline.y = 7;
    this.underline.visible = false;
    this.addChild(this.underline);

    // add event listeners

    // on hover
    this.addEventListener('pointerover', this.hoverIn.bind(this));
    this.addEventListener('pointerout', this.hoverOut.bind(this));
    this.addEventListener('pointerup', this.onClick.bind(this));
  }

  setColor(color) {
    this.text.style.fill = color;
    this.underline.tint = color;
  }

  hoverIn() {
    if (!this.canHover) return;
    if (this.isUnderline) {
      this.underline.visible = true;
    }
  }

  hoverOut() {
    if (!this.canHover) return;
    this.underline.visible = false;
  }

  onClick() {
    if (this.delegate && this.delegate.onLinkClick) {
      this.delegate.onLinkClick(this);
    }
  }
}
