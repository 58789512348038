import { Util } from '../util';
import * as PIXI from 'pixi.js';
import { Vector } from './helpers';

export default class LineHelper {
  static drawDashedLine(
    graphics,
    pointA,
    pointB,
    solidLength = 10,
    gapLength = 8,
    forceClose = true,
    forceEdges = true
  ) {
    const angle = Util.Math.getAngle(pointA, pointB);
    const lineLength = Util.Math.getDistance(pointA, pointB);

    const lineV = new Vector(solidLength).setAngle(angle);
    const gapV = new Vector(gapLength).setAngle(angle);

    const startPoint = pointA.clone();
    const nextPoint = startPoint.clone();

    // The algorithm will keep drawing until it reaches the end point
    let i = 0;
    while (true) {
      if (i++ % 2 === 0) {
        // draw fragment line
        nextPoint.add(lineV);
        // If the framgent line that is going to be drawn exceeds the line
        if (Util.Math.getDistance(pointA, nextPoint) > lineLength) {
          // If the line does not end with a gap
          if (Util.Math.getDistance(pointA, startPoint) < lineLength) {
            if (
              forceEdges &&
              Util.Math.getDistance(startPoint, pointB) < solidLength / 3
            ) {
              // If the frament is too short , better join it with its previous fragment
              // So we will draw a bit longer fragment
              startPoint.sub(gapV);
              LineHelper.drawLine(graphics, startPoint, pointB);
            } else {
              LineHelper.drawLine(graphics, startPoint, pointB);
            }
          } else if (forceClose) {
            // if the line ends with a gap , but we want to close the gap anyway
            startPoint.sub(gapV);
            LineHelper.drawLine(graphics, startPoint, pointB);
          }
          break;
        }

        LineHelper.drawLine(graphics, startPoint, nextPoint);
      } else {
        // make gap
        nextPoint.add(gapV);
        startPoint.copy(nextPoint);
      }
    }
  }

  static drawLine(graphics, pointA, pointB) {
    graphics.moveTo(pointA.x, pointA.y);
    graphics.lineTo(pointB.x, pointB.y);
  }
}
