import * as PIXI from 'pixi.js';
import Obstacle from './Obstacle';
import { SAT } from '../utils/sat';

export default class Tenticle extends PIXI.Sprite {
  constructor() {
    super();
    this.randomPhase = Math.random() * 500;
    this.collisonRectangle = null;
  }

  draw() {
    if (this.collisonRectangle) {
      this.graphics = new PIXI.Graphics();
      this.graphics.scale.set(1 / this.scale.x, 1 / this.scale.y);
      this.addChild(this.graphics);

      this.graphics.clear();
      this.graphics.lineStyle(2, 0xffffff, 1);

      this.graphics.moveTo(
        this.collisonRectangle.points[0].x,
        this.collisonRectangle.points[0].y
      );

      for (let i = 1; i < this.collisonRectangle.points.length; i++) {
        this.graphics.lineTo(
          this.collisonRectangle.points[i].x,
          this.collisonRectangle.points[i].y
        );
      }

      this.graphics.lineTo(
        this.collisonRectangle.points[0].x,
        this.collisonRectangle.points[0].y
      );
    }
  }

  setData(data, extractMethod) {
    this.texture = PIXI.Assets.get(data.imageName);
  }

  followPoint(point) {
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if (child.followPoint) {
        child.followPoint(point);
      }
    }
  }

  onUpdate(delta, ticker) {
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if (child.onUpdate) {
        child.onUpdate(delta, ticker);
      }
    }

    // animate skew with sine wave where the the amplitude is 0.1
    // and the period is 1 second
    const skew = Math.sin(this.randomPhase + ticker.lastTime / 1000) * 0.1;
    this.skew.x = skew;
  }

  onImport() {
    this.initializeCollisionRect();
  }

  initializeCollisionRect() {
    const scaleFactor = this.scale.x;
    const obstacles = this.findByType(Obstacle);
    if (!obstacles || obstacles.length === 0) {
      return;
    }
    const obs = obstacles[0];
    this.obstacle = obs;

    const data = obs.data;

    // Polygons must be scaled , rotated and translated to match the sprite
    const points = [];

    for (let i = 0; i < data.points.length; i++) {
      points.push(
        new SAT.Vector(
          data.points[i].x * scaleFactor,
          data.points[i].y * scaleFactor
        )
      );
    }

    this.collisonRectangle = new SAT.Polygon(
      new SAT.Vector(
        data.position.x * scaleFactor,
        data.position.y * scaleFactor
      ),
      points
    );

    const pos = this.collisonRectangle.pos;
    this.collisonRectangle.translate(pos.x, pos.y);
    this.collisonRectangle.setAngle(this.rotation);

    obs.removeFromParent();
  }

  getCollisonRect() {
    const globalPosition = this.getGlobalPosition();
    this.collisonRectangle.pos.x = globalPosition.x;
    this.collisonRectangle.pos.y = globalPosition.y;

    return this.collisonRectangle;
  }
}
