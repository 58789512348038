import * as PIXI from 'pixi.js';

export default class SkyLine extends PIXI.Sprite {
  constructor() {
    super();
    this.randomPhase = Math.random() * Math.PI * 2;
  }

  setData(data, extractMethod) {
    this.texture = PIXI.Assets.get(data.imageName);
  }

  onUpdate(delta, ticker) {
    // use sine wave to animate the alpha channel of the sprite , and use its x position to shift phase
    this.alpha = 0.6 + Math.sin(this.randomPhase + ticker.lastTime / 900) * 0.5;

    // animate skew with sine wave
    this.skew.x =
      Math.sin(this.randomPhase + 300 + ticker.lastTime / 2600) * 0.1;
    // skey by y
    this.skew.y = Math.sin(this.randomPhase + ticker.lastTime / 2600) * 0.1;
  }
}
