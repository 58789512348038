import * as PIXI from 'pixi.js';
import Button from '../ui/button';
import Label from '../ui/label';
import { Default } from '../ui/default';
import Styles from '../ui/styles';
import NineSlice from '../ui/nineSlice';
import CollisionBox from '../gameObjects/collisionBox';
import Collectable from '../gameObjects/Collectable';
import LakeBoat from '../gameObjects/LakeBoat';
import SkyLine from '../gameObjects/SkyLine';
import BlinkingEye from '../gameObjects/BlinkingEye';
import Tenticle from '../gameObjects/Tenticle';
import Tree from '../gameObjects/Tree';
import BigStar from '../gameObjects/BigStar';
import LakeFog from '../gameObjects/LakeFog';
import MagicTree from '../gameObjects/MagicTree';
import FireBucket from '../gameObjects/FireBucket';
import BackgroundEye from '../gameObjects/BackgroundEye';
import MidTenticles from '../gameObjects/MidTenticles';
import Obstacle from '../gameObjects/Obstacle';
import DemonTenticle from '../gameObjects/DemonTenticle';

window['Collectable'] = Collectable;
window['LakeBoat'] = LakeBoat;
window['SkyLine'] = SkyLine;
window['BlinkingEye'] = BlinkingEye;
window['Tenticle'] = Tenticle;
window['Tree'] = Tree;
window['BigStar'] = BigStar;
window['LakeFog'] = LakeFog;
window['MagicTree'] = MagicTree;
window['FireBucket'] = FireBucket;
window['BackgroundEye'] = BackgroundEye;
window['MidTenticles'] = MidTenticles;
window['Obstacle'] = Obstacle;
window['DemonTenticle'] = DemonTenticle;

export default class Importer {
  constructor() {
    this.initialize();
    this.isLazyImport = false;
    this.isImportingFinishded = true;
    this.isMustImport = false;
  }

  initialize() {
    this.inputs = [];
    this.content = null;
    this.resolveConstraints = true;
    this.objects = null;
    this.counter = 0;
    this.lazyCommands = [];
    this.importedObjects = [];
  }

  dataToObject(data) {
    const object = this.unwrapObject(data, null);
    object.id = PIXI.utils.uid();

    if (data.children && data.children.length) {
      this.importChildren(object, data.children);
    }

    return object;
  }

  findDataById(id, children) {
    children = children || this.objects;

    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (c.id === id) {
        return c;
      }

      if (c.children) {
        const object = this.findDataById(id, c.children);
        if (object) {
          return object;
        }
      }
    }

    return null;
  }

  findDataByType(type, children, result) {
    children = children || this.objects;
    result = result || [];
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (c.type === type) {
        result.push(c);
      }
      if (c.children) {
        this.findDataByType(type, c.children, result);
      }
    }

    return result.length ? result : null;
  }

  findDataByClassName(className, children, result) {
    children = children || this.objects;
    result = result || [];
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (c.className === className) {
        result.push(c);
      }

      if (c.children) {
        this.findDataByClassName(className, c.children, result);
      }
    }

    return result.length ? result : null;
  }

  findDataByTag(tag, children, result) {
    children = children || this.objects;
    result = result || [];
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (c.tag === tag) {
        result.push(c);
      }

      if (c.children) {
        this.findDataByTag(tag, c.children, result);
      }
    }

    return result.length ? result : null;
  }

  findDataByMethod(compareMethod, children, result) {
    children = children || this.objects;
    result = result || [];
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (compareMethod(c)) {
        result.push(c);
      }

      if (c.children) {
        this.findDataByMethod(compareMethod, c.children, result);
      }
    }

    return result.length ? result : null;
  }

  ////////////////////////////// IMPORT //////////////////////////////

  importObjects(objects, content, callback) {
    this.importedObjects = [];

    if (this.isLazyImport) {
      this.lazyImportObjects(objects, content, callback);
      return;
    }

    this.counter = 0;
    this.content = content;
    this.objects = objects;
    this.importCallback = callback;

    for (let i = 0; i < objects.length; i++) {
      const data = objects[i];
      const object = this.unwrapObject(data, content);

      if (object) {
        this.importedObjects.push(object);
        content.addChild(object);
      }

      if (data.children && data.children.length) {
        this.importChildren(object, data.children);
      }
    }

    this.propagateImport(this.importedObjects);

    if (this.importCallback) {
      this.importCallback();
    }
  }

  importChildren(parent, children) {
    for (let i = 0; i < children.length; i++) {
      const data = children[i];
      const object = this.unwrapObject(data, parent);

      if (object) {
        this.importedObjects.push(object);
        parent.addChild(object);
        if (data.children && data.children.length) {
          this.importChildren(object, data.children);
        }
      }
    }
  }

  // import the objects , but make a dealy between each object

  propagateImport(children) {
    for (let i = children.length - 1; i >= 0; i--) {
      const c = children[i];
      if (c.onImport) {
        c.onImport();
      }

      if (c.onImportFinished) {
        c.onImportFinished();
      }
      this.propagateImport(c.children);
    }
  }

  unwrapObject(data, parent) {
    let object = null;
    this.counter++;
    if (data.className) {
      if (window[data.className]) {
        object = new window[data.className](data);
        object.mode = 'app';
      } else {
        console.warn('Class: "' + data.className + '" is not defined!');
        return new PIXI.Sprite();
      }

      if (object.setData) {
        object.setData(data, this.extract, this);
      }
    } else if (data.type === 'ImageObject' && !this._customImport) {
      try {
        const texture = PIXI.utils.TextureCache[data.imageName];
        object = PIXI.Sprite.from(texture);
      } catch (e) {
        console.error('Error creating ImageObject ' + data.imageName);
      }
    } else if (data.type === 'LabelObject') {
      const style = this.createStyle(data);
      object = new Label('', style);
      object.text = data.txt;
    } else if (data.type === 'ButtonObject') {
      const props = this.createProperties(data);
      const style = this.createStyle(data);

      const type = props.isNineSlice
        ? Button.TYPE_NINE_SLICE
        : Button.TYPE_NORMAL;
      object = new Button(data.txt, {
        properties: props,
        style: style,
        type: type,
      });
    } else if (data.type === 'ContainerObject') {
      object = new PIXI.Container();
    } else if (data.type === 'NineSliceObject') {
      const props = this.createProperties(data);

      object = new NineSlice(
        props.backgroundName,
        props.padding.toString(),
        props.width,
        props.height
      );
      object.tint = props.tintColor;
    } else if (data.type === 'TilingSpriteObject') {
      const props = this.createProperties(data);

      var texture = PIXI.Assets.get(props.backgroundName);

      object = new PIXI.TilingSprite(texture);
      object.width = props.width;
      object.height = props.height;

      object.tilePosition.set(props.tilePositionX, props.tilePositionY);
      object.tileScale.set(props.tileScaleX, props.tileScaleY);
    } else if (data.type === 'Layer') {
      object = new PIXI.Container();
      object.factor = data.factor;
      object.name = data.name;
      // part to integer the last part of the name after -
      const nameParts = data.name.split('-');
      object.zIndex = parseInt(nameParts[nameParts.length - 1]);
    } else if (data.type === 'PolygonObject') {
      object = new CollisionBox(data);
    }

    if (!object) {
      object = new PIXI.Sprite();
    }

    if (data.position) {
      object.position.set(data.position.x, data.position.y);
    }

    if (object.anchor) {
      if (data.anchor) {
        object.anchor.set(data.anchor.x, data.anchor.y);
      } else {
        object.anchor.set(0.5, 0.5);
      }
    }

    if (data.scale) {
      object.scale.set(data.scale.x, data.scale.y);
    }

    if (data.tag) {
      object.tag = data.tag;
    }

    if (data.rotation) {
      object.rotation = data.rotation;
    }

    if (data.alpha !== undefined) {
      object.alpha = data.alpha;
    }

    object.visible = data.visible === undefined ? true : data.visible;
    object.cullable = true; // all the object are cullable by default

    if (data.id !== undefined) {
      object.id = data.id;
      object.id = object.id.toLowerCase();
    } else {
      object.id = PIXI.utils.uid();
    }

    if (data.type !== 'NineSliceObject') {
      object.tint = data.tint || 0xffffff;
    }

    if (data.properties && data.properties._custom) {
      object._properties = data.properties._custom;
    }

    return object;
  }

  extract(key, data) {
    if (data.properties && data.properties._custom) {
      for (let i = 0; i < data.properties._custom.length; i++) {
        const d = data.properties._custom[i];
        if (d.key === key) {
          return d.value;
        }
      }
    }

    return null;
  }

  applyValues(object, values) {
    for (const prop in values) {
      if (Object.prototype.hasOwnProperty.call(values, prop)) {
        const defaultValue = values[prop];
        object[prop] = defaultValue;
      }
    }
  }

  createStyle(data) {
    const style = {};
    this.applyValues(style, Default.stylesClean[data.type]);

    if (data.styleName) {
      const styledProperties = Styles.types[data.type][data.styleName];
      if (styledProperties) {
        this.applyValues(style, styledProperties.style);
      } else {
        console.warn('Style: ' + data.styleName + ' not found');
      }
    }

    this.applyValues(style, data.style || {});
    return style;
  }

  createProperties(data) {
    const properties = {};
    this.applyValues(properties, Default.properties[data.type]);

    if (data.styleName) {
      const styledProperties = Styles.types[data.type][data.styleName];
      if (styledProperties) {
        this.applyValues(properties, styledProperties.properties);
      } else {
        console.warn('Style: ' + data.styleName + ' not found');
      }
    }

    this.applyValues(properties, data.properties);
    return properties;
  }

  // lazy import the objects

  lazyImportObjects(objects, content, callback) {
    this.counter = 0;
    this.content = content;
    this.objects = objects;
    this.importCallback = callback;
    this.isImportingFinishded = false;

    this.lazyCommands = [];
    this.lazyStack = [content];

    for (let i = 0; i < objects.length; i++) {
      const data = objects[i];
      this.lazyCommands.push(data);

      if (data.children && data.children.length) {
        this.lazyImportChildren(data.children);
      }
    }
  }

  lazyImportChildren(children) {
    this.lazyCommands.push(0);

    for (let i = 0; i < children.length; i++) {
      const data = children[i];
      this.lazyCommands.push(data);
      if (data.children && data.children.length) {
        this.lazyImportChildren(data.children);
      }
    }

    this.lazyCommands.push(1);
  }

  onUpdate() {
    if (this.lazyCommands.length === 0) {
      if (!this.isImportingFinishded) {
        this.isImportingFinishded = true;

        this.propagateImport(this.importedObjects);

        if (this.importCallback) {
          this.importCallback();
        }
      }

      return;
    }

    this.lazyCounter = this.isMustImport ? this.lazyCommands.length : 10; // 10 objects per frame
    // console.log(
    //   `commandsLeft: ${this.lazyCommands.length} executeAtTime: ${window.app.game.app.ticker.lastTime}`
    // );

    for (let i = 0; i < this.lazyCounter; i++) {
      const command = this.lazyCommands.shift();

      if (command !== undefined) {
        if (command === 0) {
          // unwrap and push
          this.lazyStack.push(this.lastObject);
        } else if (command === 1) {
          this.lazyStack.pop();
        } else {
          // its the data
          const lastParent = this.lazyStack[this.lazyStack.length - 1];
          const object = this.unwrapObject(command, lastParent);
          if (object) {
            this.importedObjects.push(object);
            lastParent.addChild(object);
          }
          this.lastObject = object;
        }
      }
    }

    this.isMustImport = false;
  }
}
