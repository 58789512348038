import * as PIXI from 'pixi.js';
import DashedMenu from './DashedMenu';
import TextLink from './TextLink';
import { Viewport } from 'pixi-viewport';
import TweenIdle from '../tweening/tween_idle';
import Label from '../ui/label';

export default class PauseMenu extends PIXI.Container {
  constructor(delegate, screenSize) {
    super();

    this.delegate = delegate;
    this.screenSize = screenSize;

    // add background blank screen with black tint

    const background = new PIXI.Sprite(PIXI.Texture.WHITE);
    background.tint = 0x333333;
    background.width = screenSize.width;
    background.height = screenSize.height;
    background.alpha = 0.96;
    this.addChild(background);

    background.eventMode = 'static';
    background.on('pointerup', () => {
      this.delegate.onResume();
    });

    // create a new big star with ui-star texture
    const star = new PIXI.Sprite();
    star.texture = PIXI.Assets.get('ui-star');
    star.scale.set(0.8);
    star.anchor.set(0.5);
    star.position.set(screenSize.width / 2, 100);
    this.addChild(star);

    this.bigStar = star;

    // add menu with links

    const links = [
      {
        id: 'resume',
        text: 'Resume',
      },
      {
        id: 'restart-game',
        text: 'Restart Game',
      },
      {
        id: 'the-artwork',
        text: 'About The Project',
      },
      {
        id: 'the-music',
        text: 'The Music',
      },
      {
        id: 'the-story',
        text: 'The Story',
      },
      {
        id: 'live-show',
        text: 'Live Staged Show',
      },
      {
        id: 'films',
        text: 'Films',
      },
      {
        id: 'merchandise',
        text: 'Merchandise',
      },
      {
        id: 'tigrans-website',
        text: `Tigran's Website`,
      },
    ];

    const width = 340;
    const height = 470;

    const dashedMenu = new DashedMenu({
      width,
      height,
      links,
      style: null,
      delegate: this,
      fontSize: 55,
      startY: 50,
      padding: 19,
    });
    dashedMenu.position.set(screenSize.width / 2, 200);
    this.addChild(dashedMenu);

    this.dashedMenu = dashedMenu;

    // add credits section

    const scrollContentWidth = 400;
    const scrollContentHeight = 1550;

    const viewWidth = 400;
    const viewHeight = 300;

    const viewY = 720;

    // create a red box
    const box = new PIXI.Graphics();
    box.beginFill(0xeae7bc, 0.05);
    box.drawRect(0, 0, scrollContentWidth, scrollContentHeight);
    box.endFill();

    const scrollView = new PIXI.Container();
    scrollView.position.set(
      screenSize.width / 2 - scrollContentWidth / 2,
      viewY
    );
    this.addChild(scrollView);

    // mask the scrollView
    const mask = new PIXI.Graphics();
    mask.beginFill(0x000000);
    mask.drawRect(0, 0, viewWidth, viewHeight);
    mask.endFill();

    scrollView.addChild(mask);
    scrollView.mask = mask;

    const viewport = new Viewport({
      screenWidth: viewWidth, // screen width used by viewport (eg, size of canvas)
      screenHeight: viewHeight,
      worldWidth: scrollContentWidth,
      worldHeight: scrollContentHeight,
      forceHitArea: new PIXI.Rectangle(
        0,
        0,
        scrollContentWidth,
        scrollContentHeight
      ),
      passiveWheel: false,
      events: app.game.app.renderer,
    });

    viewport
      .drag({
        direction: 'y',
      })
      .decelerate();

    viewport.bounce({
      sides: 'vertical', // all, horizontal, vertical, or combination of top, bottom, right, left(e.g., 'top-bottom-right')
      friction: 0.5, // friction to apply to decelerate if active
      time: 150, // time in ms to finish bounce
      bounceBox: {
        x: 0,
        y: 0,
        width: scrollContentWidth,
        height: scrollContentHeight,
      }, // use this bounceBox instead of { x: 0, y: 0, width: viewport.worldWidth, height: viewport.worldHeight }
      ease: 'easeInOutSine', // ease function or name (see http://easings.net/ for supported names)
      underflow: 'center', // (top/bottom/center and left/right/center, or center) where to place world if too small for screen
    });

    scrollView.addChild(viewport);
    // viewport.addChild(box);

    const credits = new TextLink('Credits', this, {
      fontFamily: 'Pixels',
      fontSize: 40,
      fill: 0xeae7bc,
    });
    credits.id = 'credits';
    credits.position.set(scrollContentWidth / 2, 30);
    credits.underline.visible = true;
    credits.canHover = false;
    viewport.addChild(credits);

    const creditsText = this.getCreditsText();
    // break the text into lines
    const lines = creditsText.split('\n');

    // create a label out of each line
    lines.forEach((line, index) => {
      const label = new Label(line, {
        fontFamily: 'Pixels',
        fontSize: 40,
        wordWrap: true,
        wordWrapWidth: scrollContentWidth,
      });
      label.anchor.set(0.5, 0);
      label.position.set(scrollContentWidth / 2, 60 + 30 * index);
      viewport.addChild(label);
    });

    setTimeout(() => {
      viewport.animate({
        time: 15000,
        removeOnInterrupt: true,
        position: {
          x: scrollContentWidth / 2,
          y: scrollContentHeight - height / 2,
        },
      });
    }, 1000);

    this.viewport = viewport;
  }

  update(delta, ticker) {
    this.dashedMenu.stars.forEach((star) => {
      star.onUpdate(delta, ticker);
    });

    this.bigStar.rotation += delta * 0.0005;
  }

  onLinkClick(link) {
    if (link.id === 'resume') {
      this.delegate.onResume();
    } else if (link.id === 'restart-game') {
      window.location.reload();
    } else if (link.id === 'credits') {
      window.open('https://bird1000.com/credits', '_blank');
    }
  }

  getCreditsText() {
    return `music:
Tigran Hamasyan

artwork:
Khoren Matevosyan

creative director * script:
Ruben Van Leer

voice over:
Areni Agbabian

lead developer:
Ilija Trbogazov

animation:
Roman Fedorov
Vialex Motion Lab
Astghik Harutyunyan
Ruben Van Leer

sound design:
Alexander Nezhinskiy - Evolsound

Audio Engineer:
Ignacio Flores

Social media creative lead:
Marie Lavis

Supported by:
Creative Industries Fund NL

thanks to:
Johann Audiffren
Juan Agustin Tibaldo - Ohzi
Tom Korkidis - New Village 

inspired by folktale 'hazaran blbul'  
version by Armenian poet serine

executive producer:
Ruben van Leer

produced by:
Truth.io
Believe-naive
yergatun`;
  }
}
