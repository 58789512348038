const b = '-c12-',
  d = 'er-xx-',
  a = 'data',
  c = 'dr-';

const word = a + b + c + d;

export default class API {
  constructor() {
    this.baseUrl = 'https://bird1000.com/admin/api/'; // https://bird1000.com/admin/api/get-leaderboard/E6A95A59-919B-42F8-B1B6-407A1579E9AD/0
    this.gameUuid = 'E6A95A59-919B-42F8-B1B6-407A1579E9AD';

    // this.baseUrl = 'http://localhost/bird1000/admin/api/';
    // this.gameUuid = 'E6A95A59-919B-42F8-B1B6-407A1579E9AD';
  }

  getLeaderboard(callback) {
    const period = 0; // 0 = all time, 1 = today, 7 = last 7 days
    return this.ajaxPost(
      this.baseUrl + 'get-leaderboard/' + this.gameUuid + '/' + period + '/1',
      { random: Math.random() },
      (response) => {
        callback(response);
      }
    );
  }

  sendScore(score, name, email, isNewsletter, isKeyCollected, callback) {
    // // post: score , game_uuid ,name , email
    return this.postData(
      'set-score',
      {
        score: this.comp(score),
        name,
        email,
        details: JSON.stringify({ isNewsletter, isKeyCollected }),
        game_uuid: this.gameUuid,
      },
      (response) => {
        if (callback) callback(response);
      }
    );
  }

  postData(path, data, callback) {
    this.ajaxPost(this.baseUrl + path, data, (response) => {
      if (callback) callback(response);
    });
  }

  comp(data) {
    var x1 = 53;
    var x2 = 120;
    var x = x1 + x2;

    data = word + data;

    var btdo = '';

    for (var i = 0; i < data.length; i++) {
      var a = data.charCodeAt(i);
      var b = a ^ x;
      btdo = btdo + String.fromCharCode(b);
    }

    return btdo;
  }

  decomp(encoded) {
    var x1 = 53;
    var x2 = 120;
    var x = x1 + x2;

    function ord(str) {
      return str.charCodeAt(0);
    }

    function chr(codePt) {
      if (codePt > 0xffff) {
        codePt -= 0x10000;
        return String.fromCharCode(
          0xd800 + (codePt >> 10),
          0xdc00 + (codePt & 0x3ff)
        );
      }
      return String.fromCharCode(codePt);
    }

    var bdoded = '';
    for (var i = 0; i < encoded.length; i++) {
      var b = ord(encoded[i]);

      var a = b ^ x;
      bdoded += chr(a);
    }

    return Number(bdoded.replace(word, ''));
  }

  serialize(obj, prefix) {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          typeof v == 'object'
            ? this.serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  }

  createAjax() {
    var xhttp;
    if (window.XMLHttpRequest) {
      xhttp = new XMLHttpRequest();
    } else {
      xhttp = new ActiveXObject('Microsoft.XMLHTTP');
    }
    return xhttp;
  }

  ajaxGet(url, callback, headers) {
    var xhttp = this.createAjax();

    if (headers) {
      for (var prop in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, prop)) {
          xhttp.setRequestHeader(prop, headers[prop]);
        }
      }
    }

    xhttp.onreadystatechange = function () {
      if (xhttp.readyState === 4) {
        if (xhttp.status === 200) {
          var ct = xhttp.getResponseHeader('content-type') || '';
          if (ct.indexOf('json') > -1) {
            callback(JSON.parse(xhttp.responseText), xhttp);
          } else {
            callback(xhttp.responseText, xhttp);
          }
        } else {
          if (callback) {
            callback(null, xhttp);
          }
        }
      }
    };

    xhttp.open('GET', url, true);
    xhttp.send();
  }

  ajaxPost(url, data, callback, headers) {
    var xhttp = this.createAjax();
    xhttp.onreadystatechange = function () {
      if (xhttp.readyState === 4) {
        if (xhttp.status === 200) {
          if (callback) {
            var ct = xhttp.getResponseHeader('content-type') || '';
            if (ct.indexOf('json') > -1) {
              var response = xhttp.responseText;
              try {
                response = JSON.parse(xhttp.responseText);
              } catch (e) {
                response = xhttp.responseText;
              }
              callback(response, xhttp);
            } else {
              callback(xhttp.responseText, xhttp);
            }
          }
        } else {
          if (callback) {
            callback(null, xhttp);
          }
        }
      }
    };

    xhttp.open('POST', url, true);

    if (headers) {
      for (var prop in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, prop)) {
          xhttp.setRequestHeader(prop, headers[prop]);
        }
      }
    } else {
      xhttp.setRequestHeader(
        'Content-type',
        'application/x-www-form-urlencoded'
      );
    }

    if (data instanceof FormData) {
      xhttp.send(data);
    } else {
      var data_string = this.serialize(data);
      xhttp.send(data_string);
    }
  }
}

// get-leaderboard/uuid/period[0,1,2]/desc[0,1

// post: score , game_uuid ,name , email
