import * as PIXI from 'pixi.js';

export default class Tree extends PIXI.Sprite {
  constructor() {
    super();
    this.randomPhase = Math.random() * Math.PI * 2;
  }

  setData(data, extractMethod) {
    this.texture = PIXI.Assets.get(data.imageName);
  }

  onUpdate(delta, ticker) {
    // use sine wave to animate the skew of the sprite , and use its x position to shift phase
    this.skew.x = Math.sin(this.randomPhase + ticker.lastTime / 2000) * 0.05;
  }
}
