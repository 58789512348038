import * as PIXI from 'pixi.js';
import TweenGeneric from '../tweening/tween_generic';
import { Util } from '../util';

export default class BigStar extends PIXI.Sprite {
  constructor() {
    super();
    this.randomSpeed = Math.random() * 0.002;

    this.startScale = Util.Math.randomFloat(0.1, 0.2);
    this.randomPhase = Math.random() * Math.PI * 2;
    this.factor = 300;
    this.scaleAmplitude = 0.05;
  }

  setData(data, extractMethod) {
    this.texture = PIXI.Assets.get(data.imageName);
  }

  onUpdate(delta, ticker) {
    this.rotation += delta * this.randomSpeed;

    // using sine wave scale up the star , then scale down , and then fade out
    this.scale.set(
      this.startScale +
        Math.sin(this.x + ticker.lastTime / this.factor) * this.scaleAmplitude
    );
  }
}
