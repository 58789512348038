export default class ShareHelper {
  static share = (score) => {
    if (navigator.share) {
      navigator
        .share({
          title: 'The bird of a thousand voices',
          text: `I collected ${score} light points as Prince Areg to create a beacon of hope and bring harmony in the online game The Bird of a Thousand Voices - The Kingdom. Play it now or listen to Tigran Hamasyan's new album at www.bird1000.com`,
          url: 'https://www.bird1000.com/',
        })
        .then(() => {
          console.log('Thanks for sharing!');
        });
    } else {
      alert('Web Share API not supported');
    }
  };
}
