import Easing from './easing';
import Tween from './tween';

export default class TweenPop extends Tween {
  constructor(object, to, bezier, duration, callback) {
    super(object, to, bezier, duration, callback);
    this.bezier2 = new Easing(Easing.EASE_OUT_ELASTIC);

    this.nonBouncePercent = 0.1;

    this.applyValues();
  }

  applyValues() {
    this.startScale = this.object.scale.x;
    this.difference = this.startScale - this.to;
  }

  nonBounce(nonBounce) {
    this.nonBouncePercent = nonBounce;
    return this;
  }

  elsticity(elasticity) {
    this.bezier2.elsticity = elasticity;

    return this;
  }

  step(dt) {
    this.timePassed = this.timePassed + dt;
    this.ticks++;

    let s = this.timePassed / this.duration;

    s = s >= 1 ? 1.0 : s;

    if (s <= this.nonBouncePercent) {
      var ss = s / this.nonBouncePercent;
      if (this.bezier) {
        ss = this.bezier.get(s / this.nonBouncePercent);
      }
      this.object.scale.set(this.startScale + (this.to - this.startScale) * ss);
    } else {
      var ss = (s - this.nonBouncePercent) / (1 - this.nonBouncePercent);
      const bstep = this.bezier2.get(ss);
      this.object.scale.set(this.to + bstep * this.difference);
    }

    if (s === 1.0 && this.ticks > 1) {
      this.invokeCallback();
      this.stop();
    }
  }
}
