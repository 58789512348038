import * as PIXI from 'pixi.js';
import { Vector } from '../utils/helpers';
import { SAT } from '../utils/sat';
import { Spine, Utils } from 'pixi-spine';
import TweenGeneric from '../tweening/tween_generic';
import { Util } from '../util';
import TweenIdle from '../tweening/tween_idle';

export default class BlinkingEye extends PIXI.Container {
  constructor() {
    super();
    this.animation = new Spine(PIXI.Assets.get('M_eye').spineData);
    this.animation.autoUpdate = true;
    this.animation.scale.set(0.2);

    // get root bone
    this.iris = this.animation.skeleton.findBone('containerr-root');
    this.blinkTimer = Util.Math.randomInt(1000, 5000);
    this.timer = this.blinkTimer;

    // eye-follow

    this.animation.state.setAnimation(0, 'eye-follow', false);
    this.addChild(this.animation);
  }

  followPoint(point) {
    const gp = this.getGlobalPosition();
    const angle = Util.Math.getAngle(gp, point);
    const p = new Vector(0, 30);
    p.setAngle(this.rotation - angle);

    this.iris.x = 0;
    this.iris.y = 0;

    this.iris.x = p.x;
    this.iris.y = p.y;
  }

  setData(data, extractMethod) {}

  onUpdate(delta, ticker) {
    this.timer -= delta;
    if (this.timer < 0) {
      this.animation.state.setAnimation(0, 'blink', false);
      this.timer = this.blinkTimer;
    }
  }
}
