import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

export default class BackgroundEye extends PIXI.Container {
  constructor() {
    super();

    this.player = null;
    this.screenSize = null;

    const data = PIXI.Assets.get('B_Eye');

    this.animation = new Spine(data.spineData);
    this.animation.autoUpdate = true;
    this.animation.scale.set(0.5);
    this.addChild(this.animation);
    this.animation.state.setAnimation(0, 'lurking', true);

    this.isRespositioned = false;
  }

  onUpdate(delta, ticker) {
    // const playerGp = this.player.getGlobalPosition();
    // const gp = this.getGlobalPosition();
    // const distanceX = gp.x - playerGp.x;
    // const distanceY = gp.y - playerGp.y;
    // if (distanceX < this.screenSize.width + 200 && !this.isRespositioned) {
    //   this.isRespositioned = true;
    //   this.y -= distanceY + 300;
    // }
  }
}
