import * as PIXI from 'pixi.js';
import { Vector } from '../utils/helpers';
import { SAT } from '../utils/sat';

export default class Obstacle extends PIXI.Container {
  constructor(data) {
    super();

    this.data = data;

    const points = [];

    for (let i = 0; i < data.points.length; i++) {
      points.push(new SAT.Vector(data.points[i].x, data.points[i].y));
    }

    this.collisonRectangle = new SAT.Polygon(
      new SAT.Vector(data.position.x, data.position.y),
      points
    );

    this.debug = false;

    if (this.debug) {
      this.graphics = new PIXI.Graphics();
      this.addChild(this.graphics);

      this.draw();
    }
  }

  draw() {
    if (this.debug) {
      this.graphics.clear();
      this.graphics.lineStyle(2, 0xffffff, 1);

      this.graphics.moveTo(
        this.collisonRectangle.points[0].x,
        this.collisonRectangle.points[0].y
      );

      for (let i = 1; i < this.collisonRectangle.points.length; i++) {
        this.graphics.lineTo(
          this.collisonRectangle.points[i].x,
          this.collisonRectangle.points[i].y
        );
      }

      this.graphics.lineTo(
        this.collisonRectangle.points[0].x,
        this.collisonRectangle.points[0].y
      );
    }
  }

  getCollisonRect() {
    const globalPosition = this.getGlobalPosition();
    this.collisonRectangle.pos.x = globalPosition.x;
    this.collisonRectangle.pos.y = globalPosition.y;

    return this.collisonRectangle;
  }
}
