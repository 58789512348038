import * as PIXI from 'pixi.js';
import 'pixi-spine';
import Game from './js/game.js';
import Actions from './js/tweening/actions.js';
import { Spine } from 'pixi-spine';
import * as SpineRuntime from 'pixi-spine';

export default class App {
  constructor() {
    window.app = this;
    window.PIXI = PIXI;
    window.Spine = Spine;
    window.SpineRuntime = SpineRuntime;

    const app = new PIXI.Application({
      width: 1920,
      height: 1080,
      backgroundColor: 0xebe4ae,
      resolution: 1,
      powerPreference: 'high-performance',
    });

    // to avoid flickering on load
    setTimeout(() => {
      document.body.appendChild(app.view);
    }, 0);

    const game = new Game(app);
    this.game = game;
    window.Actions = Actions;

    app.ticker.add((delta) => {
      game.updatePaused(app.ticker.deltaMS, app.ticker);
      if (game.isPaused) return;
      Actions.update(app.ticker.deltaMS, app.ticker);
      game.update(app.ticker.deltaMS, app.ticker);
    });

    window.addEventListener('resize', (event) => {
      game.onResize();
    });
  }
}
