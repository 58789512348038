import Tween from './tween';

export default class TweenTick extends Tween {
  constructor(duration, tickMethod, bezier = null, callback = null) {
    super(null, null, bezier, duration, callback);
    this.tickMethod = tickMethod;
    this.applyValues();
  }

  applyValues() {}

  step(dt) {
    this.timePassed += dt;
    let relativeStep = this.timePassed / this.duration;
    relativeStep = relativeStep >= 1 ? 1.0 : relativeStep;
    const bstep = this.bezier ? this.bezier.get(relativeStep) : relativeStep;

    this.tickMethod.call(this.context, bstep, dt);

    if (relativeStep === 1) {
      this.stop();
      this.invokeCallback();
    }
  }
}
