import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Vector } from '../utils/helpers';
import { SAT } from '../utils/sat';
import { Util } from '../util';
import TweenGeneric from '../tweening/tween_generic';
import Bezier from '../tweening/bezier';
import TweenIdle from '../tweening/tween_idle';
import DustAnimation from './DustAnimation';
import { sound } from '@pixi/sound';
import TweenBlink from '../tweening/tween_blink';
import { VOLUME_FX } from '../scenes/GameScene';

const LONG_JUMP_TIMER = (1000 / 60) * 12; // number of frames
const LEFT_RIGHT_DURATION = 300; // ms

// 6_Jump_0
// 7_Jump_1
// 8_Jump_2
const JUMP_ANIMATION = '6_Jump_0';

export default class Player extends PIXI.Container {
  constructor(delegate) {
    super();

    this.delegate = delegate;

    this.graphics = new PIXI.Graphics();
    this.addChild(this.graphics);

    this.collisionRectangle = new SAT.Polygon(new SAT.Vector(0, 0), [
      new SAT.Vector(-50, -200),
      new SAT.Vector(50, -200),
      new SAT.Vector(50, 0),
      new SAT.Vector(-50, 0),
    ]);

    this.isDamage = false;

    ////////////////// Avalilable animations //////////////////

    ////////////// init animation ////////////////////////////

    const data = PIXI.Assets.get('Human_Profile');
    this.animation = new Spine(data.spineData);
    this.animation.autoUpdate = true;
    this.animation.scale.set(0.07); // initial scale
    this.addChild(this.animation);

    // add events to listen to the animation
    setTimeout(() => {
      this.animation.state.addListener({
        event: (trackEntry, event) => {
          if (event.data.name === 'left-foot') {
            // play sound
            const sf = Util.Math.randomInt(1, 2);
            sound.play('SFX_Footsteps_Left-' + sf, { volume: 1 * VOLUME_FX });
          } else if (event.data.name === 'right-foot') {
            // play sound
            const sf = Util.Math.randomInt(1, 2);
            sound.play('SFX_Footsteps_Right-' + sf, { volume: 1 * VOLUME_FX });
          }
        },
        complete: (event) => {
          if (event.animation.name === '12_Swim-Hit') {
            this.animation.state.setAnimation(0, '13_Drown_Loop', true);
          }
        },
      });
    }, 100);

    ///////////////////// set flags //////////////////////////

    this.platforms = []; // all the platforms
    this.collectables = [];
    this.triggers = [];
    this.boat = null;

    this.runSpeed = 600 / 1000; //px/s
    this.jumpSpeed = 1.8; // //px/s
    this.glideSpeed = 0.17;
    this.velocity = new Vector(this.runSpeed, 0);

    this.gravity = new Vector(0, 0.006);
    this.fallGravity = new Vector(0, 0.007);

    this.isLocked = false;
    this.isRunnerMode = true;
    this.isUpMechanicsMode = false;

    this.isLongJump = false;
    this.timerLongJump = LONG_JUMP_TIMER;
    this.isOnPlatform = false;
    this.isGliding = false;
    this.isFalling = false;
    this.isOnGround = false;
    this.isOnBoat = false;

    this.isUp = false;

    this.isLeft = false; // its either left or right

    this.platforms = [];
    this.objects = [];
    this.sensors = [];
    this.obstacles = [];

    this.leftLocationX = 0;
    this.rightLocationX = 0;

    this.upLocationY = 0;
    this.downLocationY = 0;

    this.set_animations_mix();

    this.leftRightBezier = new Bezier(0.27, 0.83, 0.61, 0.99);

    this.movementTween = null;
  }

  moveUp() {
    this.isUp = true;
    this.animation.state.setAnimation(0, '5_Up', false);

    if (this.movementTween) this.movementTween.stop();

    this.movementTween = new TweenGeneric(
      this,
      { y: this.upLocationY },
      this.leftRightBezier,
      LEFT_RIGHT_DURATION
    ).run();

    if (this.moveIdleTween) this.moveIdleTween.stop();

    this.moveIdleTween = new TweenIdle(LEFT_RIGHT_DURATION - 100, () => {
      if (this.animation.state.getCurrent(0).animation.name === 'Damage')
        return;
      this.animation.state.setAnimation(0, '2_Right', true);
    }).run();

    sound.play('06_SFX_Areg-Dodge-1', { volume: 1 * VOLUME_FX * 0.4 });
  }

  moveDown() {
    this.isUp = false;
    this.animation.state.setAnimation(0, '3_Down', false);

    if (this.movementTween) this.movementTween.stop();

    this.movementTween = new TweenGeneric(
      this,
      { y: this.downLocationY },
      this.leftRightBezier,
      LEFT_RIGHT_DURATION
    ).run();

    if (this.moveIdleTween) this.moveIdleTween.stop();

    this.moveIdleTween = new TweenIdle(LEFT_RIGHT_DURATION - 100, () => {
      if (this.animation.state.getCurrent(0).animation.name === 'Damage')
        return;
      this.animation.state.setAnimation(0, '2_Right', true);
      this.moveIdleTween = null;
    }).run();

    sound.play('06_SFX_Areg-Dodge-2', { volume: 1 * VOLUME_FX * 0.4 });
  }

  moveLeft() {
    this.isLeft = true;
    this.animation.state.setAnimation(0, 'Left', false);

    if (this.movementTween) this.movementTween.stop();
    this.movementTween = new TweenGeneric(
      this,
      { x: this.leftLocationX },
      this.leftRightBezier,
      LEFT_RIGHT_DURATION
    ).run();

    sound.play('06_SFX_Areg-Dodge-1', { volume: 1 * VOLUME_FX * 0.4 });
  }

  moveRight() {
    this.isLeft = false;
    this.animation.state.setAnimation(0, 'Right', false);

    if (this.movementTween) this.movementTween.stop();
    this.movementTween = new TweenGeneric(
      this,
      { x: this.rightLocationX },
      this.leftRightBezier,
      LEFT_RIGHT_DURATION
    ).run();

    sound.play('06_SFX_Areg-Dodge-2', { volume: 1 * VOLUME_FX * 0.4 });
  }

  activateUpMechanics() {
    this.isUpMechanicsMode = true;
    this.isRunnerMode = false;

    //set a mix between the animations Left , Right and Up
    this.animation.stateData.setMix('Left', 'Up', 0.2);
    this.animation.stateData.setMix('Right', 'Up', 0.2);
    this.animation.stateData.setMix('Up', 'Left', 0.2);
    this.animation.stateData.setMix('Up', 'Right', 0.2);

    // mix all animations to Damage animation
    this.animation.stateData.setMix('Left', 'Damage', 0.2);
    this.animation.stateData.setMix('Right', 'Damage', 0.2);
    this.animation.stateData.setMix('Up', 'Damage', 0.2);

    // mix all animation to Damage animation
    this.animation.stateData.setMix('Damage', 'Left', 0.2);
    this.animation.stateData.setMix('Damage', 'Right', 0.2);
    this.animation.stateData.setMix('Damage', 'Up', 0.2);

    // add spine events
    setTimeout(() => {
      if (this.isUpMechanicsMode) {
        this.animation.state.addListener({
          complete: (event) => {
            if (
              event.animation.name === 'Left' ||
              event.animation.name === 'Right'
            ) {
              this.animation.state.setAnimation(0, 'Up', true);
            }

            if (event.animation.name === 'Damage') {
              this.animation.state.setAnimation(0, 'Up', true);
            }
          },
        });
      }
    }, 100);

    // burst out of the water
    new TweenGeneric(this, { y: this.y - 1500 }, null, 600, () => {
      this.velocity.y = -1;
      this.isLocked = false;
    }).run();
  }

  activateHorseMode() {
    this.isHorseMode = true;
    this.isRunnerMode = false;
    this.isUpMechanicsMode = false;

    this.animation.removeFromParent();
    this.animation.destroy();

    const data = PIXI.Assets.get('Human_Horse');
    this.animation = new Spine(data.spineData);
    this.animation.autoUpdate = true;
    this.animation.scale.set(0.2); // initial scale
    this.animation.state.setAnimation(0, '1_Start', false);

    this.addChild(this.animation);

    // Rotate horse on mounting
    new TweenGeneric(
      this,
      { rotation: -0.3 },
      new Bezier(0.24, 0.83, 0.67, 1.21),
      600
    )
      .delay(400)
      .run();

    // delgate on horse mounted
    new TweenIdle(900, () => {
      this.isLocked = false;
      this.delegate.onHorseMounted();
    }).run();

    // add spine events
    setTimeout(() => {
      this.animation.state.addListener({
        complete: (event) => {
          if (event.animation.name === '1_Start') {
            this.animation.state.setAnimation(0, '2_Right', true);
            this.animation.timeScale = 2;
          }

          if (event.animation.name === '6_End') {
            this.animation.state.setAnimation(0, '7_End_Loop', true);
            this.animation.timeScale = 2;
          }

          if (event.animation.name === 'Damage') {
            this.animation.state.setAnimation(0, '2_Right', true);
          }
        },
      });
    }, 100);

    this.animation.stateData.setMix('1_Start', '2_Right', 0.2);
    this.animation.stateData.setMix('2_Right', '3_Down', 0.2);
    this.animation.stateData.setMix('2_Right', '5_Up', 0.2);

    this.animation.stateData.setMix('5_Up', '2_Right', 0.2);
    this.animation.stateData.setMix('3_Down', '2_Right', 0.2);

    // this.animation.stateData.setMix('3_Down', '6_End', 0.2);
    // this.animation.stateData.setMix('2_Right', '6_End', 0.1);
    // this.animation.stateData.setMix('5_Up', '6_End', 0.2);

    // this.animation.stateData.setMix('6_End', '7_End_Loop', 0.1);
  }

  set_animations_mix() {
    this.animation.stateData.setMix('2_Intro', '5_Run', 0.2);
    this.animation.stateData.setMix('5_Run', JUMP_ANIMATION, 0.2);
    this.animation.stateData.setMix(JUMP_ANIMATION, '5_Run', 0.1);

    //
    this.animation.stateData.setMix('2_Intro', '3_Idle', 0.2);
    this.animation.stateData.setMix('3_Idle', '5_Run', 0.2);

    // 9_Jump_3 is fall animation
    this.animation.stateData.setMix(JUMP_ANIMATION, '8_Jump_falling', 0.2);
    this.animation.stateData.setMix('8_Jump_falling', '5_Run', 0.1);
    this.animation.stateData.setMix('5_Run', '8_Jump_falling', 0.2);

    // Gliding
    this.animation.stateData.setMix(JUMP_ANIMATION, '8_Jump_gliding', 0.2);
    this.animation.stateData.setMix('8_Jump_gliding', '8_Jump_falling', 0.2);
    this.animation.stateData.setMix('8_Jump_gliding', '5_Run', 0.2);
    this.animation.stateData.setMix('8_Jump_gliding', '11_Swim', 0.2);

    this.animation.stateData.setMix('5_Run', '11_Swim', 0.2); // run to swim
    this.animation.stateData.setMix('8_Jump_falling', '11_Swim', 0.2); // fall to swim
    this.animation.stateData.setMix('11_Swim', JUMP_ANIMATION, 0.2); // swin to jump

    this.animation.stateData.setMix('11_Swim', '12_Swim-Hit', 0.1); // swin to jump
    this.animation.stateData.setMix('12_Swim-Hit', '13_Drown_Loop', 0.1); // swin to jump
    this.animation.stateData.setMix('13_Drown_Loop', '14_Drown', 0.2); // swin to jump
    this.animation.stateData.setMix('13_Drown_Loop', '13_Drown_Loop', 0.1); // swin to jump
  }

  onUpdate(delta, ticker) {
    if (this.isHorseMode) {
      this.onUpdateHorseMode(delta, ticker);
    } else if (this.isRunnerMode) {
      this.runnerUpdate(delta, ticker);
    } else if (this.isUpMechanicsMode) {
      // check other modes
      this.onUpdateUpMechanics(delta, ticker);
    }
  }

  onUpdateUpMechanics(delta, ticker) {
    ////////////////// apply movement //////////////////////

    this.x += this.velocity.x * delta;
    this.y += this.velocity.y * delta;

    ////////////////////// resolve collissions //////////////////////

    const response = new SAT.Response();
    const playerRect = this.getCollisonRect();

    ///////////////////////////// check collectables ///////////////

    for (var i = 0; i < this.collectables.length; i++) {
      const collectable = this.collectables[i];
      const b = collectable.getCollisonRect();

      if (SAT.testPolygonPolygon(playerRect, b)) {
        this.delegate.onPlayerCollectable(collectable);
        this.collectables.splice(i, 1);
      }
    }

    ///////////////////////////// check obstacles ///////////////
    if (!this.isDamage) {
      for (let i = 0; i < this.obstacles.length; i++) {
        const obstacle = this.obstacles[i];
        const b = obstacle.getCollisonRect();

        if (b && SAT.testPolygonPolygon(playerRect, b)) {
          this.delegate.onPlayerObstacle(obstacle);
        }
      }
    }
  }

  onUpdateHorseMode(delta, ticker) {
    const response = new SAT.Response();
    const playerRect = this.getCollisonRect();
    ///////////////////////////// check collectables ///////////////

    for (var i = 0; i < this.collectables.length; i++) {
      const collectable = this.collectables[i];
      const b = collectable.getCollisonRect();
      if (SAT.testPolygonPolygon(playerRect, b)) {
        this.delegate.onPlayerCollectable(collectable);
        this.collectables.splice(i, 1);
      }
    }

    ///////////////////////////// check obstacles ///////////////
    if (!this.isDamage) {
      for (let i = 0; i < this.obstacles.length; i++) {
        const obstacle = this.obstacles[i];
        const b = obstacle.getCollisonRect();

        if (b && SAT.testPolygonPolygon(playerRect, b)) {
          this.delegate.onPlayerObstacle(obstacle);
        }
      }
    }
  }

  runnerUpdate(delta, ticker) {
    /////////// read controlls //////////////////////////

    ////////////////////// set velocity //////////////////////

    // jumping and long jumping
    if (this.isLongJump && !this.isOnGround) {
      this.timerLongJump -= delta;
      // keep applying jump speed
      this.velocity.y = -this.jumpSpeed;
      if (this.timerLongJump <= 0) {
        this.isLongJump = false;
        this.timerLongJump = LONG_JUMP_TIMER;
      }
    }

    /////////////// apply gravity //////////////////////////

    if (this.velocity.y >= 0 && this.isPointerDown && !this.isFalling) {
      this.velocity.y = this.glideSpeed;
      this.onGlide();
    } else if (this.velocity.y > 0) {
      this.onFall();
      var v = this.fallGravity.clone().scale(delta);
      this.velocity.add(v);
    } else {
      // standard gravity pushing down
      var v = this.gravity.clone().scale(delta);
      this.velocity.add(v);
    }

    ////////////////// apply movement //////////////////////

    this.x += this.velocity.x * delta;
    this.y += this.velocity.y * delta;

    ////////////////////// resolve restrictions //////////////////////

    // clamp y speed
    this.velocity.y = Util.Math.clamp(this.velocity.y, -2, 2.5);

    // Can't go bellow the ground
    if (this.y >= 0) {
      this.y = 0;
      this.velocity.y = 0;
      this.onGround();
    }

    ///////////////////////// resolve collisions //////////////////////

    let hasPlatformCollision = false;
    const response = new SAT.Response();
    const playerRect = this.getCollisonRect();

    for (var i = 0; i < this.platforms.length; i++) {
      const platform = this.platforms[i];

      // check the collision
      const b = platform.getCollisonRect();
      response.clear();

      if (SAT.testPolygonPolygon(playerRect, b, response)) {
        if (this.velocity.y >= 0) {
          // if it gets near the platform
          if (
            (response.overlap < 25 && response.overlapN.y === 1) ||
            // for height speeds give it more room
            (this.velocity.y >= 1 &&
              response.overlap < 50 &&
              response.overlapN.y === 1)
          ) {
            // do not resolve x axis with platforms
            this.y -= response.overlapV.y;
            this.velocity.y = 0;
            this.onPlatform();
            hasPlatformCollision = true;
            break;
          }
        }
      }
    }

    if (!hasPlatformCollision && this.isOnPlatform) {
      this.onFall();
    }

    /////////////////////////// check boat /////////////////////////

    if (this.boat && this.velocity.y >= 0) {
      const boatRect = this.boat.getCollisonRect();
      response.clear();

      if (SAT.testPolygonPolygon(playerRect, boatRect, response)) {
        // if it gets near the platform
        if (response.overlap) {
          this.onBoat();
        }
      }
    }

    ///////////////////////////// check collectables ///////////////

    for (let i = 0; i < this.collectables.length; i++) {
      const collectable = this.collectables[i];
      const b = collectable.getCollisonRect();

      if (SAT.testPolygonPolygon(playerRect, b)) {
        this.delegate.onPlayerCollectable(collectable);
        this.collectables.splice(i, 1);
      }
    }

    ///////////////////////////// check triggers ///////////////

    for (let i = 0; i < this.triggers.length; i++) {
      const trigger = this.triggers[i];
      const b = trigger.getCollisonRect();

      if (SAT.testPolygonPolygon(playerRect, b)) {
        trigger.onTrigger();
        this.delegate.onPlayerTrigger(trigger);
        this.triggers.splice(i, 1);
      }
    }
  }

  onPointerDown() {
    this.isPointerDown = true;

    if (this.isLocked) return;

    if (this.isHorseMode) {
      // toggle between those two
      if (!this.isUp) {
        this.moveUp();
      } else {
        this.moveDown();
      }
    } else if (this.isRunnerMode) {
      this.onJump();
    } else if (this.isUpMechanicsMode) {
      // toggle between those two
      if (!this.isLeft) {
        this.moveLeft();
      } else {
        this.moveRight();
      }
    }
  }

  onJump() {
    if (this.isOnGround || this.isOnPlatform || this.isOnBoat) {
      this.isLongJump = true;
      this.isOnPlatform = false;
      this.isOnGround = false;
      this.isFalling = false;
      this.isOnBoat = false;

      this.timerLongJump = LONG_JUMP_TIMER;
      this.velocity.y = -this.jumpSpeed;

      this.animation.state.setAnimation(0, JUMP_ANIMATION, true);
      this.delegate.onPlayerJump(this);

      const dust = new DustAnimation();
      dust.setAnimation2();
      dust.position.set(this.x, this.y);
      this.parent.addChild(dust);
      sound.play('SFX_Areg-Jump-' + Util.Math.randomInt(1, 4), {
        volume: 1 * VOLUME_FX,
      });
    }
  }

  onPointerRelesed() {
    this.isLongJump = false;
    this.isPointerDown = false;
  }

  onGround() {
    if (!this.isOnGround) {
      // console.log('on ground');

      this.velocity.y = 0;
      this.isOnGround = true;
      this.isLongJump = false;
      this.isPointerDown = false;

      this.animation.state.setAnimation(0, '5_Run', true);

      this.delegate.onPlayerGround(this);

      const dust = new DustAnimation();
      dust.setAnimation1();
      dust.position.set(this.x, this.y);
      this.parent.addChild(dust);

      sound.play('01_SFX_Areg-Fall', { volume: 1 * VOLUME_FX });
    }
  }

  onPlatform() {
    if (!this.isOnPlatform) {
      // console.log('on platform');

      this.velocity.y = 0;
      this.isOnPlatform = true;
      this.isLongJump = false;
      this.isOnGround = false;
      this.isPointerDown = false;
      this.isFalling = false;

      this.animation.state.setAnimation(0, '5_Run', true);

      this.delegate.onPlayerPlatform(this);

      const dust = new DustAnimation();
      dust.setAnimation1();
      dust.position.set(this.x, this.y);
      this.parent.addChild(dust);
    }
  }

  onFall() {
    if (this.isFalling) return;

    // console.log('falling');

    this.isFalling = true;
    this.isGliding = false;
    this.isOnPlatform = false;
    this.isOnGround = false;
    this.isOnBoat = false;

    this.animation.state.setAnimation(0, '8_Jump_falling', true);

    this.delegate.onPlayerFall(this);
  }

  onBoat() {
    if (!this.isOnBoat) {
      // console.log('on boat');
      this.isOnBoat = true;
      this.isOnGround = true;
      this.isOnPlatform = false;
      this.isFalling = false;
      this.isGliding = false;

      this.animation.state.setAnimation(0, '11_Swim', true);

      if (this.delegate.onPlayerBoat) {
        this.delegate.onPlayerBoat(this);
      }
    }
  }

  onGlide() {
    if (this.isGliding) return;
    this.isGliding = true;
    this.isFalling = false;

    // console.log("I'm gliding");
    // TODO set gliding animation
    this.animation.state.setAnimation(0, '8_Jump_gliding', true);
  }

  takeDamage() {
    if (this.isDamage) return;

    this.isDamage = true;

    this.animation.state.setAnimation(0, 'Damage', false);

    new TweenBlink(this, 0, null, 200).repeat(8).run();

    setTimeout(() => {
      this.isDamage = false;
      this.alpha = 1;
    }, 8 * 200);

    if (this.delegate.onTakeDamage) {
      this.delegate.onTakeDamage(this);
    }

    // play 08_SFX_Areg-Slash
    sound.play('08_SFX_Areg-Slash', { volume: 1 * VOLUME_FX });

    // play SFX_Areg-Jump-4
    sound.play('SFX_Areg-Jump-4', { volume: 1 * VOLUME_FX });
  }

  getCollisonRect() {
    const globalPosition = this.getGlobalPosition();
    this.collisionRectangle.pos.x = globalPosition.x;
    this.collisionRectangle.pos.y = globalPosition.y;

    return this.collisionRectangle;
  }
}
