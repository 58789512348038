import * as PIXI from 'pixi.js';
import { Vector } from '../utils/helpers';
import { SAT } from '../utils/sat';

export default class Collectable extends PIXI.Container {
  constructor() {
    super();
    this.startY = 0;
    this.points = 0;
    this.shouldRotate = true;
    this.shouldBounce = true;
    this.sprite = null;
    this.centerSprite = null;
    this.sounds = ['SFX_Starlight-Collect-1'];
    this.isKey = false;
  }

  initializeByType(data) {
    this.sprite = new PIXI.Sprite(PIXI.Assets.get(data.imageName));
    this.sprite.anchor.set(0.5);
    this.addChild(this.sprite);

    // points_star_2
    const pointsByKey = {
      points_star_1: 10,
      points_star_2: 20,
      points_star_3: 30,
      points_star_4: 40,
      points_star_5: 50,
      points_star_6_body: 100,
      points_star_7_body: 150,
      points_star_8_body: 200,
      points_star_9_body: 300,
      collectable_key: 3000,
    };

    if (data.imageName === 'points_star_6_body') {
      this.makeComplexStar(6);
      this.sounds = ['SFX_Starlight-Collect-2'];
    } else if (data.imageName === 'points_star_7_body') {
      this.makeComplexStar(7);
      this.sounds = ['SFX_Starlight-Collect-2'];
    } else if (data.imageName === 'points_star_8_body') {
      this.makeComplexStar(8);
      this.sounds = ['SFX_Starlight-Collect-2'];
    } else if (data.imageName === 'points_star_9_body') {
      this.makeComplexStar(9);
      this.sounds = ['SFX_Starlight-Collect-2'];
    }

    if (data.imageName === 'collectable_key') {
      this.sounds = ['SFX_Starlight-Collect-4', 'SFX_Magic-Egg'];
      this.isKey = true;
    }

    this.scale.set(this.scale.x * 0.7);
    this.points = pointsByKey[data.imageName];

    const width = 50;
    const height = 50;

    const points = [
      new Vector(-width / 2, -height / 2),
      new Vector(width / 2, -height / 2),
      new Vector(width / 2, height / 2),
      new Vector(-width / 2, height / 2),
    ];

    this.collisonRectangle = new SAT.Polygon(
      new SAT.Vector(data.position.x, data.position.y),
      points
    );

    this.startY = data.position.y;
  }

  makeComplexStar(n) {
    this.centerSprite = new PIXI.Sprite(
      PIXI.Assets.get('points_star_' + n + '_center')
    );
    this.centerSprite.anchor.set(0.5);
    this.addChild(this.centerSprite);
    this.shouldRotate = false;
    this.scale.set(0.8);
    this.sprite.y = -7;
  }

  setData(data, extractMethod) {
    this.initializeByType(data);
  }

  getCollisonRect() {
    const globalPosition = this.getGlobalPosition();
    this.collisonRectangle.pos.x = globalPosition.x;
    this.collisonRectangle.pos.y = globalPosition.y;

    return this.collisonRectangle;
  }

  onUpdate(delta, ticker) {
    if (this.shouldRotate) {
      this.rotation += 0.001 * delta;
    }

    if (this.centerSprite) {
      this.centerSprite.rotation -= 0.001 * delta;
    }

    if (this.shouldBounce) {
      // sine wave
      const amplitude = 5;
      const frequency = 0.001;
      const offset = this.x;
      this.y =
        this.startY +
        Math.sin(offset + ticker.lastTime * frequency) * amplitude;
    }
  }
}
