import * as PIXI from 'pixi.js';
import Color from './color.js';

export function convertColor(hex) {
  if (hex === 'transparent' || hex === '#ffffff' || hex === '#FFFFFF') {
    return 0xffffff;
  }
  var color = hex || '';
  color = color.replace('#', '0x');
  color = PIXI.utils.hex2rgb(color);
  return Color.getColor32(1, color[0] * 255, color[1] * 255, color[2] * 255);
}

export const Vector = function (x, y) {
  this.x = x || 0;
  this.y = y || 0;
};

Vector.prototype.setLength = function (length) {
  var angle = this.getAngle();
  this.x = Math.cos(angle) * length;
  this.y = Math.sin(angle) * length;
  return this;
};

Vector.prototype.getLength = function () {
  return Math.sqrt(this.len2());
};

Vector.prototype.setAngle = function (angle) {
  var length = this.len();
  this.x = Math.cos(angle) * length;
  this.y = Math.sin(angle) * length;
  return this;
};

Vector.prototype.getAngle = function () {
  return Math.atan2(this.y, this.x);
};

// Get the dot product of this vector and another.
/**
 * @param {Vector}  other The vector to dot this one against.
 * @return {number} The dot product.
 */
Vector.prototype.dot = function (other) {
  return this.x * other.x + this.y * other.y;
};

// Get the squared length of this vector.
/**
 * @return {number} The length^2 of this vector.
 */
Vector.prototype.len2 = function () {
  return this.dot(this);
};

// Get the length of this vector.
/**
 * @return {number} The length of this vector.
 */
Vector.prototype.len = function () {
  return Math.sqrt(this.len2());
};

Vector.prototype.sub = function (other) {
  this.x -= other.x;
  this.y -= other.y;
  return this;
};

Vector.prototype.clone = function () {
  return new Vector(this.x, this.y);
};

Vector.prototype.scale = function (x) {
  this.x *= x;
  this.y *= x;
  return this;
};

Vector.prototype.add = function (other) {
  this.x += other.x;
  this.y += other.y;
  return this;
};

Vector.prototype.copy = function (other) {
  this.x = other.x;
  this.y = other.y;
  return this;
};

Vector.addition = function (v1, v2) {
  return new Vector(v1.x + v2.x, v1.y + v2.y);
};
