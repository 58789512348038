import * as PIXI from 'pixi.js';
import TweenGeneric from '../tweening/tween_generic';
import { Util } from '../util';

export default class MidTenticles extends PIXI.Sprite {
  constructor() {
    super();

    this.eventMode = 'static';
    this.player = null;

    // this.on('pointermove', this.onMove.bind(this));
  }

  setData(data, extractMethod) {
    this.texture = PIXI.Assets.get(data.imageName);

    const displacementSprite = PIXI.Sprite.from(
      PIXI.Assets.get('displacement_map_repeat')
    );

    displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
    const displacementFilter = new PIXI.DisplacementFilter(displacementSprite);

    displacementFilter.padding = 10;

    this.addChild(displacementSprite);

    this.filters = [displacementFilter];

    this.displacementSprite = displacementSprite;
    this.displacementSprite.scale.set(5);
  }

  // onMove(event) {
  //   const point = event.data.global;
  //   this.followPoint(point);
  // }

  followPoint(point) {
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if (child.followPoint) {
        child.followPoint(point);
      }
    }
  }

  onUpdate(delta, ticker) {
    const displacementSprite = this.displacementSprite;

    const scaleDetail = 500;
    const posDetail = 1000;

    // use sine wave to animate displacementSprite scale
    const scale = 200 + Math.sin(ticker.lastTime / scaleDetail) * 100;
    this.filters[0].scale.x = scale;
    this.filters[0].scale.y = scale;

    displacementSprite.x++;
    // Reset x to 0 when it's over width to keep values from going to very huge numbers.
    if (displacementSprite.x > displacementSprite.width) {
      displacementSprite.x = 0;
    }

    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if (child.onUpdate) {
        child.onUpdate(delta, ticker);
      }
    }

    if (this.player) {
      this.followPoint(this.player.getGlobalPosition());
    }
  }
}
