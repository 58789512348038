import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

export default class TakeFX extends PIXI.Container {
  constructor() {
    super();

    const data = PIXI.Assets.get('Take_FX');

    this.animation = new Spine(data.spineData);
    this.animation.autoUpdate = true;
    this.animation.scale.set(0.5);
    this.animation.state.timeScale = 0.4;
    this.addChild(this.animation);

    this.animation.state.setAnimation(0, 'STAR', false);

    // add event
    setTimeout(() => {
      this.animation.state.addListener({
        complete: (entry) => {
          setTimeout(() => {
            this.removeFromParent();
            this.animation.destroy();
          }, 0);
        },
      });
    }, 100);
  }
}
