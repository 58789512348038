import Tween from './tween';

export default class TweenGeneric extends Tween {
  constructor(object, to, bezier, duration, callback) {
    super(object, to, bezier, duration, callback);
    this.timePassed = 0;
    this.onChange = null;
    this.applyValues();
  }

  applyValues() {
    this._properties = {};
    this._list = [];

    for (let property in this.to) {
      if (this.to.hasOwnProperty(property)) {
        const k = property;
        this._properties['start' + k] = this.object[k];
        this._properties['difference' + k] = this.to[k] - this.object[k];
        this._list.push(k);
      }
    }
  }

  step(dt) {
    this.timePassed += dt;
    let relativeStep = this.timePassed / this.duration;
    relativeStep = relativeStep >= 1 ? 1.0 : relativeStep;
    const bstep = this.bezier ? this.bezier.get(relativeStep) : relativeStep;

    for (let i = 0; i < this._list.length; i++) {
      const pk = this._list[i];
      this.object[pk] =
        this._properties['start' + pk] +
        bstep * this._properties['difference' + pk];
      if (this.onChange) {
        this.onChange(this, pk, this.object[pk]);
      }
    }

    if (relativeStep >= 1) {
      this.invokeCallback();
      this.stop();
    }
  }

  to(to, duration, bezier) {
    // Define the 'to' method if needed
  }

  call(to, duration, bezier) {
    // Define the 'call' method if needed
  }
}
